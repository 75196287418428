import BaseStore from '../../../../../../store/domain/base.store';
import {makeObservable} from 'mobx';
import moment from 'moment';

class UserLoginHistoryUIStore extends BaseStore {
    dateRange;

    constructor() {
        super();
        this.initialiseDateRange();

        makeObservable(this, {});

        this.createInitialState();
    }

    reset() {
        this.initialiseDateRange();
    }

    initialiseDateRange() {
        this.dateRange = {
            endDate: moment().utc(false),
            startDate: moment().utc(false).subtract(1, 'month')
        };
    };

    setDateRange = dateRange => {
        this.dateRange = dateRange;
    }
}

const userLoginHistoryUIStore = new UserLoginHistoryUIStore()
export default userLoginHistoryUIStore;