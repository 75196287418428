import React, { useEffect, useRef } from 'react';
import columnConfig from '../active-accounts/accounts.column-config';
import PageableTableSelect from '../../../components/table-select/pageable/PageableTableSelect';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const ArchivedAccounts = inject('headerUIStore', 'accountStore', 'accountsUIStore')(observer((props) => {
    const { accountsUIStore, accountStore } = props;
    const navigate = useNavigate();
    const tableSelectRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            accountsUIStore.reset();
            accountStore.reset();
        };
    }, [accountsUIStore, accountStore]);

    const handleOpenButton = () => {
        navigateToAccountOverview(accountsUIStore.singleSelectedAccount);
    };

    const handleRowsSelection = selectedAccounts => {
        accountsUIStore.setSelectedAccounts(selectedAccounts);
    };

    const handleRowDoubleClick = selectedAccount => {
        navigateToAccountOverview(selectedAccount);
    };

    const navigateToAccountOverview = selectedAccount => {
        navigate(`/accounts/${selectedAccount.id}`);
    };

    const getPageableAccounts = (currentPage, pageSize, sortingFields, filter, tableSelectCallback) => {
        accountStore.getPageableAccounts(
            currentPage,
            pageSize,
            sortingFields,
            filter,
            tableSelectCallback,
            true
        );
    };

    const renderTableButtons = () => {
        return !accountsUIStore.singleSelectedAccount ? null : (
            <div>
                <Button onClick={handleOpenButton}>{t('common:common.open')}</Button>
            </div>
        );
    };

    return (
        <div>
            <PageableTableSelect
                multiSelect={true}
                columnConfig={columnConfig}
                fetchPageableData={getPageableAccounts}
                handleRowsSelection={handleRowsSelection}
                rowId="id"
                tableButtons={renderTableButtons()}
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('account:account.data.name')}
                handleRowDoubleClick={handleRowDoubleClick}
                defaultColumnSorted="lastModifiedDate"
                tableSelectRef={tableSelectRef}
            />
        </div>
    );
}));

export default ArchivedAccounts;
