import {observable, action, makeObservable, computed} from 'mobx';

import BaseStore from '../../store/domain/base.store';

class ProductBundleUIStore extends BaseStore {

    selectedProductBundles;
    isEditProductBundleModalOpen = false;
    isAddNewProductBundleModalOpen = false;
    isArchiveConfirmOpen = false;

    constructor() {
        super();
        this.setSelectedProductBundles([]);
        makeObservable(this, {
            selectedProductBundles: observable,
            isEditProductBundleModalOpen: observable,
            isArchiveConfirmOpen: observable,
            isAddNewProductBundleModalOpen: observable,
            setSelectedProductBundles: action,
            closeEditProductBundleModal: action,
            openEditProductBundleModal: action,
            closeAddNewProductBundleModal: action,
            openAddNewProductBundleModal: action,
            closeArchiveConfirmModal: action,
            openArchiveConfirmModal: action,
            singleSelectedProductBundle: computed,
            selectedSalesforceIds: computed
        });
        this.createInitialState();
    }

    setSelectedProductBundles(selectedProductBundles) {
        this.selectedProductBundles = selectedProductBundles;
    }

    closeEditProductBundleModal = () => {
        this.isEditProductBundleModalOpen = false;
    };

    openEditProductBundleModal = () => {
        this.isEditProductBundleModalOpen = true;
    };

    closeAddNewProductBundleModal = () => {
        this.isAddNewProductBundleModalOpen = false;
    };

    openAddNewProductBundleModal = () => {
        this.isAddNewProductBundleModalOpen = true;
    };

    openArchiveConfirmModal = () => {
        this.isArchiveConfirmOpen = true;
    };

    closeArchiveConfirmModal = () => {
        this.isArchiveConfirmOpen = false;
    };

    get singleSelectedProductBundle() {
        return this.selectedProductBundles
            && this.selectedProductBundles.length === 1
            && this.selectedProductBundles[0];
    }

    get selectedSalesforceIds() {
        if (!(this.selectedProductBundles instanceof Array)) {
            return [];
        }

        return this.selectedProductBundles.map(productBundle => productBundle.salesforceId);
    }
}

const productBundleUIStore = new ProductBundleUIStore()
export default productBundleUIStore;