import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../../../../../../store/domain/base.store';

class UserOverviewUIStore extends BaseStore {
    isEditUserModalOpen = false;
    isTransferUserModalOpen = false;

    constructor() {
        super();
        this.closeEditUserModal();
        this.closeTransferUserModal();
        makeObservable(this, {
            isEditUserModalOpen: observable,
            openEditUserModal: action,
            closeEditUserModal: action,
            isTransferUserModalOpen: observable,
            openTransferUserModal: action,
            closeTransferUserModal: action
        });
        this.createInitialState();
    }

    openEditUserModal = () => {
        this.isEditUserModalOpen = true;
    };

    closeEditUserModal = () => {
        this.isEditUserModalOpen = false;
    };
    openTransferUserModal = () => {
        this.isTransferUserModalOpen = true;
    };

    closeTransferUserModal = () => {
        this.isTransferUserModalOpen = false;
    };
}

const userOverviewUIStore = new UserOverviewUIStore()
export default userOverviewUIStore;