import {action, computed, makeObservable, observable} from 'mobx';
import contactService from './contact.service';
import BaseStore from '../base.store';
import _ from 'lodash';
import {fileUtil, paginationUtil} from '../../../utils';
import moment from 'moment';

class UserStore extends BaseStore {
    isLoading = false;
    isExportingCsv;
    user;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setIsExportingCsv(false);
        this.setUser({});

        makeObservable(this, {
            isLoading: observable,
            isExportingCsv: observable,
            user: observable,
            setIsLoading: action,
            setIsExportingCsv: action,
            setUser: action,
            userCard: computed,
            editUserFields: computed
        });

        this.createInitialState();
    }

    setIsLoading = (loading) => {
        this.isLoading = loading;
    };

    setIsExportingCsv = (loading) => {
        this.isExportingCsv = loading;
    };

    setUser = (user) => {
        this.user = user;
    };

    createUser = (accountId) => (user) => {
        if (user && accountId) {
            user.accountId = accountId;
        }

        return contactService.createContact(user);
    };

    updateUser = (accountId, userId) => (user) => {
        if (user && accountId) {
            user.accountId = accountId;
        }

        return contactService.updateContact(userId, user);
    };

    retrieveUser = (contactId) => {
        this.setIsLoading(true);

        return contactService.retrieveContact(contactId)
            .then(({data}) => {
                this.setUser(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    transferUser = (contactId) => (contactTransferRequest) => {
        if (contactTransferRequest) {
            contactTransferRequest.contactId = contactId;
        }

        return contactService.transferContact(contactTransferRequest);
    };

    getPageableUsers = (accountId, archived = false) => (currentPage, pageSize, sortingFields, filter, tableSelectCallback) => {
        const params = {
            accountId: accountId,
            archived: archived,
            page: currentPage,
            size: pageSize,
            filter: filter,
            sort: paginationUtil.createSingleSortingParameter(sortingFields)
        };

        return contactService.getPageableContacts(params)
            .then(({data}) => {
                tableSelectCallback(data);
            });
    };

    archiveUser = (selectedUsers, archiveReason) => {
        return contactService.archiveContacts(selectedUsers, archiveReason);
    };

    unarchiveUser = (selectedUsers) => {
        return contactService.unarchiveContacts(selectedUsers);
    };

    activateUsers = (selectedUsers) => {
        return contactService.activateContacts(selectedUsers);
    };

    deactivateUsers = (selectedUsers) => {
        return contactService.deactivateContacts(selectedUsers);
    };

    exportToCsv = (filter) => () => {
        const params = {
            filter: filter,
            active: true
        };

        const fileName = `Encompass users ${moment().utc(false).toISOString()}`;

        this.setIsExportingCsv(true);
        return contactService.getContactCSV(params)
            .then(({data}) => {
                fileUtil.download(data, fileName, '.csv');
            })
            .finally(() => this.setIsExportingCsv(false));
    };

    isExternallyManged = () => {
        return this.user && this.user.externallyManaged;
    }

    get userCard() {
        if (_.isEmpty(this.user)) {
            return {};
        }

        return {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            partnerUserId: this.user.partnerUserId
        };
    }

    get editUserFields() {
        if (!this.user) {
            return {};
        }

        return {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            partnerUserId: this.user.partnerUserId,
            connection: this.user.connection,
            active: this.user.active,
            accountAdmin: this.user.accountAdmin,
            uiAccessAllowed: this.user.uiAccessAllowed,
            apiAccessAllowed: this.user.apiAccessAllowed,
            factsetAccessAllowed: this.user.factsetAccessAllowed
        };
    }
}

const userStore = new UserStore()
export default userStore;
