import React from 'react';
import {withTranslation} from 'react-i18next';
import RowDetails from '../../../../../components/row-details/RowDetails';
import rowDetailsConfig from './account-billing-run-details.row-details-config';
import columnConfig from './account-billing-run-details.column-config';
import PropTypes from 'prop-types';
import TableSelect from '../../../../../components/table-select/TableSelect';
import './AccountBillingRunDetails.scss';
import {inject, observer} from 'mobx-react';
import {Button} from 'semantic-ui-react';
import AccountBillingRunEntryDetails, {
    profileColumnConfig,
    transactionColumnConfig
} from './account-billing-run-entry-details';
import poser, {PoseGroup} from 'react-pose';

const BillingDetailsWrapper = poser.div({
    enter: {
        x: 0,
        transition: {
            duration: 300,
            ease: 'easeInOut'
        }
    },
    exit: {
        x: '-100%',
        transition: {
            duration: 300,
            ease: 'easeInOut'

        }
    }
});

const BillingEntryWrapper =  poser.div({
    enter: {
        x: 0,
        transition: {
            duration: 300,
            ease: 'easeInOut'
        }
    },
    exit: {
        x: '100%',
        transition: {
            duration: 300,
            ease: 'easeInOut'
        }
    }
});

class AccountBillingRunDetails extends React.Component {

    componentDidMount() {
        this.props.accountBillingRunEntryStore.loadAllBillingRunEntries(this.props.billingRun.id);
    }

    componentWillUnmount() {
        this.props.accountBillingRunEntryStore.reset();
        this.props.accountBillingRunDetailsUIStore.reset();
    }

    handleRowsSelection = (selectedBillingRunEntries) => {
        this.props.accountBillingRunDetailsUIStore.setSelectedBillingRunEntries(selectedBillingRunEntries);
    };

    handleRowDoubleClick = (selectedBillingRunEntries) => {
        this.handleRowsSelection([selectedBillingRunEntries]);
        this.props.accountBillingRunDetailsUIStore.openBillingRunEntryDetails();
    };

    render() {
        const {t, accountName, billingRun} = this.props;

        return (
            <PoseGroup>
                {this.props.accountBillingRunDetailsUIStore.isBillingRunEntryDetailsOpen &&
                   <BillingEntryWrapper
                       key="accountBillingRunEntry"
                       pose={this.props.accountBillingRunDetailsUIStore.isBillingRunEntryDetailsOpen ? 'enter':'exit'}>
                       {this.renderEntryDetails()}
                   </BillingEntryWrapper>
                }

                {!this.props.accountBillingRunDetailsUIStore.isBillingRunEntryDetailsOpen &&
                <BillingDetailsWrapper
                    key="accountBillingRunDetails"
                    pose={this.props.accountBillingRunDetailsUIStore.isBillingRunEntryDetailsOpen ? 'exit':'enter'}>
                    <div className='single__column'>
                        <h1 className="text-center">{t('account.billing.run.details')}</h1>

                        <div className="context">
                            <div className="entity company">
                                <div className="entity-icon"/>
                                <div className="entity-info">
                                    <label>{accountName}</label>
                                </div>
                            </div>
                        </div>

                        <RowDetails dataObject={billingRun}
                                    columnConfig={rowDetailsConfig}/>
                    </div>

                    <br/>
                    <br/>
                    <TableSelect
                        disableFilter={true}
                        columnConfig={columnConfig}
                        rowData={billingRun.billingRunEntries}
                        loading={false}
                        handleRowsSelection={this.handleRowsSelection}
                        handleRowDoubleClick={this.handleRowDoubleClick}
                        dataName={t('account.billing.run.entries')}
                        rowId="id"
                        tableButtons={this.renderTableButtons()}
                        isSticky={true}
                        stickyTopBarOffset={0}
                        stickyTopOffset={55}
                    />
                </BillingDetailsWrapper>}

            </PoseGroup>
        );
    }

    renderTableButtons() {
        const {t} = this.props;

        return (
            <div>
                {
                    this.props.accountBillingRunDetailsUIStore.selectedBillingRunEntries.length === 1 &&
                    <Button onClick={this.props.accountBillingRunDetailsUIStore.openBillingRunEntryDetails}>
                        {t('common:common.details')}
                    </Button>
                }
            </div>
        );
    }

    renderEntryDetails() {
        const {t} = this.props;
        const singleSelectedBillingRunEntry = this.props.accountBillingRunDetailsUIStore.singleSelectedBillingRunEntry;

        return (
            <div>
                <AccountBillingRunEntryDetails
                    backFunc={this.props.accountBillingRunDetailsUIStore.closeBillingRunEntryDetails}
                    header={singleSelectedBillingRunEntry.description}
                    columnConfig={singleSelectedBillingRunEntry.type === 'TRANSACTION' ? transactionColumnConfig : profileColumnConfig}
                    data={this.props.accountBillingRunEntryStore.getByEntryType(singleSelectedBillingRunEntry.type)}
                    dataName={singleSelectedBillingRunEntry.type === 'TRANSACTION' ? t('account.transactions') : t('common:common.profiles')}
                    loading={this.props.accountBillingRunEntryStore.isLoading}/>
            </div>
        );
    }
}

AccountBillingRunDetails.propTypes = {
    accountName: PropTypes.string.isRequired,
    billingRun: PropTypes.object.isRequired
};

export default inject(
    'accountBillingRunEntryStore',
    'accountBillingRunDetailsUIStore'
)(withTranslation([
    'account'
])(observer(AccountBillingRunDetails)));