import 'sync-scroll';
import 'react-table/react-table.css';
import {withTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

import {stringUtil} from '../../../utils';
import BaseTableSelect from '../BaseTableSelect';


const FIRST_PAGE = 0;

class PageableTableSelect extends BaseTableSelect {

    static propTypes = {
        ...BaseTableSelect.propTypes,
        handleTextFilterChange: PropTypes.func,
        tableSelectRef: PropTypes.func,
        fetchPageableData: PropTypes.func,
        propertyToDisableRow: PropTypes.string
    };

    componentDidMount() {
        if (typeof this.props.tableSelectRef === 'function') {
            this.props.tableSelectRef({
                refreshTable: this.fetchServerDataManually
            });
        }

        const pageableState = {
            serverData: [],
            totalPages: -1,
            totalElements: 0,
            isServerDataLoading: true
        };

        this.setState(pageableState);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.onComponentUpdate(prevProps, prevState, this.state.isServerDataLoading);
        this.updateSelectedRow(prevState, this.state.serverData);
        this.removeDeletedRow(prevState, prevState.serverData, this.state.serverData);
    }

    showPaginationBar = () => {
        return !this.state.isServerDataLoading
            && this.state.totalElements > super.getPageSize();
    };

    onTextFilterChange = event => {

        const callback = () => {
            this.checkboxSelectTableReference.getWrappedInstance().state.page = FIRST_PAGE;

            if (stringUtil.isNotBlank(this.state.textFilter)) {
                if (this.state.textFilter.length >= 3) {
                    this.props.handleTextFilterChange && this.props.handleTextFilterChange(this.state.textFilter);
                    this.fetchServerDataManually();
                }
            } else {
                this.props.handleTextFilterChange && this.props.handleTextFilterChange(this.state.textFilter);
                this.fetchServerDataManually();
            }
        };

        super.onTextFilterChange(event, callback);
    };

    onFetchServerData = ({page, pageSize, sorted}) => {

        const callback = ({elements, totalPages, totalElements}) => {
            this.setState({
                serverData: elements,
                totalPages,
                totalElements,
                isServerDataLoading: false
            });
        };

        const {
            fetchPageableData
        } = this.props;

        this.setState({isServerDataLoading: true},
            () => fetchPageableData && fetchPageableData(page, pageSize, sorted, this.state.textFilter, callback)
        );
    };

    fetchServerDataManually = () => {
        this.checkboxSelectTableReference
        && this.checkboxSelectTableReference.getWrappedInstance().fireFetchData();
    };

    getReactTableProperties = () => {

        const {
            totalPages,
            isServerDataLoading,
            serverData
        } = this.state;

        const reactTableProperties = super.getReactTableProperties(
            serverData,
            isServerDataLoading,
            this.showPaginationBar()
        );

        Object.assign(reactTableProperties, {
            pages: totalPages,
            manual: true,
            onFetchData: this.onFetchServerData
        });

        return reactTableProperties;
    };

    render() {
        return super.renderCustomisedComponent(
            this.getReactTableProperties(),
            this.state.totalElements,
            this.onTextFilterChange
        );
    }
}

export default withTranslation()(observer(PageableTableSelect));