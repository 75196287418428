export default class LoggingUtil {

    errorMessageCSS = 'background-color: rgba(192,57,43,.2);' +
        'font-weight: normal;' +
        'color: #C0392B;' +
        'padding: 3px 7px;' +
        'border-radius:0px 3px 3px 0px;';

    generalMessageCSS = 'background-color: #ECEEF1;' +
        'font-weight: normal;' +
        'color: #444;' +
        'padding: 3px 7px;' +
        'border-radius:0px 3px 3px 0px;';

    errorCSS = 'background-color: #C0392B;' +
        'font-weight: bold;' +
        'color:white;' +
        'padding: 3px 7px;' +
        'border-radius:3px 0px 0px 3px;';

    infoCSS = 'background-color: #27AE60;' +
        'font-weight: bold;' +
        'color:white;' +
        'padding: 3px 7px;' +
        'border-radius:3px 0px 0px 3px;';

    warnCSS = 'background-color: rgb(245, 188, 66);' +
        'font-weight: bold;' +
        'color:white;' +
        'padding: 3px 7px;' +
        'border-radius:3px 0px 0px 3px;';

    verboseCSS = 'background-color: rgb(77, 76, 76);' +
        'font-weight: bold;' +
        'color:white;' +
        'padding: 3px 7px;' +
        'border-radius:3px 0px 0px 3px;';


    logMessage = (messageType, message, typeColor, messageColor, logLevel) => {
        console[logLevel]('%c' + messageType + '%c' + message, typeColor, messageColor);
    };

    error = (message, messageType = 'Error') => {
        this.logMessage(messageType, message, this.errorCSS, this.errorMessageCSS, 'error');
    };

    info = (message, messageType = 'Info') => {
        this.logMessage(messageType, message, this.infoCSS, this.generalMessageCSS, 'info');
    };

    verbose = (message, messageType = 'Debug') => {
        this.logMessage(messageType, message, this.verboseCSS, this.generalMessageCSS, 'debug');
    };

    warn = (message, messageType = 'Warn') => {
        this.logMessage(messageType, message, this.warnCSS, this.generalMessageCSS, 'warn');
    };
}