import React, { useEffect, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/loader/index';
import { MobXProviderContext } from 'mobx-react';

const Dashboard = observer(() => {
    const { productBundleArrayStore, headerUIStore, authenticationStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        headerUIStore.setTitleKey('dashboard:dashboard.title');
        productBundleArrayStore.loadProductBundles();

        return () => {
            productBundleArrayStore.reset();
        };
    }, [headerUIStore, productBundleArrayStore]);

    const navigateToProductBundleOverview = (id) => {
        navigate(`/product-bundles/${id}`);
    };

    const { isLoading, productBundles } = productBundleArrayStore;

    return authenticationStore.isSessionValidAndAdminUIUser && (
        <div className="content mt-4">
            <div className="section__full--width--padding">
                <div className="section__columns">
                    <section className="section__column grid-16">
                        <div className="card">
                            <div className="card__header">
                                <h2 className="text-center">{t('dashboard:dashboard.product.bundles')}</h2>
                            </div>
                            <div className="card__body">
                                <div className="table">
                                    <div className="thead">
                                        <div className="tr">
                                            <div className="th grid-16">{t('dashboard:dashboard.product.name')}</div>
                                            <div className="th grid-8">{t('dashboard:dashboard.product.amount')}</div>
                                        </div>
                                    </div>
                                    {
                                        !isLoading && (
                                            <div className="tbody">
                                                {productBundles.slice(0, 10).map((row) => (
                                                    <div
                                                        className="tr"
                                                        key={row.id}
                                                        onDoubleClick={() => navigateToProductBundleOverview(row.id)}
                                                    >
                                                        <div className="td grid-16">{row.name}</div>
                                                        <div className="td grid-8">{row.numberOfDataProducts}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    }
                                </div>
                                {isLoading && <Loader />}
                            </div>
                            <div className="card__footer">
                                <Link to="/product-bundles">
                                    <Button>{t('dashboard:dashboard.product.view.more')}</Button>
                                </Link>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
});

export default Dashboard;
