import React, {useEffect, useRef, useContext, useState} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import EncDateRangePicker from '../../../../components/date-range-picker/index';
import { Button } from 'semantic-ui-react';
import NoResultsMessage from '../../../../components/table-select/NoResultsMessage';
import columnConfig from './account-profiles.column-config';
import PageableTableSelect from '../../../../components/table-select/pageable/PageableTableSelect';
import { MobXProviderContext } from 'mobx-react';
import { useParams } from 'react-router-dom';

const AccountProfiles = observer(() => {
    const { t } = useTranslation();
    const { accountId } = useParams();
    const { profileStore, accountProfilesUIStore, accountStore, headerUIStore } = useContext(MobXProviderContext);
    const tableSelectRef = useRef(null);
    const [tableSelectFilter, setTableSelectFilter] = useState('');

    const setTableSelectRef = (node) => {
        tableSelectRef.current = node;
    };

    useEffect(() => {
        accountStore.retrieveAccount(accountId)
            .then(() => headerUIStore.setTitleKey(accountStore.account.name));

        return () => {
            accountProfilesUIStore.reset();
        };
    }, [accountId, accountStore, accountProfilesUIStore, headerUIStore]);

    const exportProfilesToCSV = () => {
        profileStore.exportToCsv(
            accountProfilesUIStore.dateRange,
            tableSelectFilter,
            { accountId: accountId, accountName: accountStore.account.name }
        );
    };

    const handleTextFilterChange = filter => {
        if (tableSelectRef.current) {
            setTableSelectFilter(filter);
        }
    };

    const getPageableProfiles = (...params) => {
        profileStore.getPageableProfiles(accountProfilesUIStore.dateRange, accountId)(...params);
    };

    const renderAdditionalFilteringComponents = () => (
        <EncDateRangePicker
            startDate={accountProfilesUIStore.dateRange.startDate}
            endDate={accountProfilesUIStore.dateRange.endDate}
            handleRange={dateRange => {
                accountProfilesUIStore.setDateRange(dateRange);
                if (tableSelectRef.current) {
                    tableSelectRef.current.refreshTable();
                }
            }}
        />
    );

    const renderNoResultsMessage = () => (
        <NoResultsMessage
            tableData={profileStore.profiles}
            startDate={accountProfilesUIStore.dateRange.startDate}
            endDate={accountProfilesUIStore.dateRange.endDate}
        />
    );

    return (
        <div className="content sticky-scroll">
            <div className="section__full--width--padding mt-4">
                <Button className="button__primary" onClick={exportProfilesToCSV}>
                    {t('common:common.export.to.csv')}
                </Button>
            </div>

            <div className="sticky-scroll">
                <PageableTableSelect
                    multiSelect={false}
                    columnConfig={columnConfig}
                    fetchPageableData={getPageableProfiles}
                    rowId="id"
                    isSticky={true}
                    stickyTopOffset={55}
                    stickyTopBarOffset={0}
                    dataName={t('common:common.profiles')}
                    additionalFilteringComponents={renderAdditionalFilteringComponents()}
                    selectable={false}
                    noDataText={renderNoResultsMessage()}
                    defaultColumnSorted="profileCreationDate"
                    tableSelectRef={setTableSelectRef}
                    handleTextFilterChange={handleTextFilterChange}
                />
            </div>
        </div>
    );
});

export default AccountProfiles;
