import React, {useContext, useEffect, useRef} from 'react';
import {Button} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import AddAccount from './add-account/index';
import EditAccount from './edit-account/EditAccount';
import EncModal from '../../../components/enc-modal/index';
import EncConfirm from '../../../components/enc-confirm/EncConfirm';
import ConfirmMessage from '../../../components/enc-confirm/ConfirmMessage';
import PageableTableSelect from '../../../components/table-select/pageable/index';
import columnConfig from './accounts.column-config';
import Loader from '../../../components/loader/Loader';

import {MobXProviderContext} from 'mobx-react';

const DEFAULT_AUTH0_TENANT = 'Default Encompass Auth0 Tenant';
const DEFAULT_SUBSCRIPTION_TYPE = 'UKCONFIRM';
const DEFAULT_BILLING_COUNTRY_CODE = 'GB';
const DEFAULT_TAXABLE = 'true';
const DEFAULT_ACCOUNT_ACTIVE = 'ACTIVE';

const ActiveAccounts = observer(() => {
    const { accountStore, auth0TenantStore, subscriptionTypeStore, accountsUIStore } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tableSelectRef = useRef(null);

    useEffect(() => {
        auth0TenantStore.load();
        subscriptionTypeStore.loadSubscriptionTypes();

        return () => {
            accountsUIStore.reset();
            accountStore.reset();
            auth0TenantStore.reset();
        };
    }, [accountStore, auth0TenantStore, subscriptionTypeStore, accountsUIStore]);

    const onSuccessSaveAccount = () => {
        tableSelectRef.current.refreshTable();
        accountsUIStore.closeNewAccountModal();
    };

    const onSuccessEditAccount = () => {
        tableSelectRef.current.refreshTable();
        accountsUIStore.closeEditAccountModal();
    };

    const archiveAccount = () => {
        accountStore.archiveAccount(accountsUIStore.singleSelectedAccount.id, accountsUIStore.archiveAccountReason)
            .then(() => tableSelectRef.current.refreshTable());
        accountsUIStore.closeConfirmArchiveModal();
    };

    const handleOpenButton = () => {
        navigate(`/accounts/${accountsUIStore.singleSelectedAccount.id}`);
    };

    const handleRowsSelection = selectedAccounts => {
        accountsUIStore.setSelectedAccounts(selectedAccounts);
    };

    const handleRowDoubleClick = selectedAccount => {
        navigate(`/accounts/${selectedAccount.id}`);
    };

    const getPageableAccounts = (currentPage, pageSize, sortingFields, filter, tableSelectCallback) => {
        accountStore.getPageableAccounts(
            currentPage,
            pageSize,
            sortingFields,
            filter,
            tableSelectCallback,
            false
        );
    };

    const renderTableButtons = () => {
        return !accountsUIStore.singleSelectedAccount ? null : (
            <div>
                <Button onClick={handleOpenButton}>{t('common:common.open')}</Button>

                {accountsUIStore.singleSelectedAccount.validORPC &&
                    <Button onClick={accountsUIStore.openEditAccountModal}>
                        {t('common:common.edit')}
                    </Button>}

                <Button
                    onClick={accountsUIStore.openConfirmArchiveModal}>{t('common:common.archive')}</Button>
            </div>
        );
    };

    const renderModals = () => {
        const defaultAuth0Tenant = auth0TenantStore.auth0Tenants.find(auth0Tenant => auth0Tenant.name === DEFAULT_AUTH0_TENANT);

        return (
            <div>
                <EncModal
                    isOpen={accountsUIStore.isNewAccountModalOpen}
                    closeModal={accountsUIStore.closeNewAccountModal}>
                    <AddAccount
                        closeFunc={accountsUIStore.closeNewAccountModal}
                        submitFunc={accountStore.createAccount}
                        onSuccess={onSuccessSaveAccount}
                        auth0TenantOptions={auth0TenantStore.auth0TenantsOptions}
                        subscriptionTypeOptions={subscriptionTypeStore.subscriptionTypeOptions}
                        initialValues={{
                            auth0Id: defaultAuth0Tenant ? defaultAuth0Tenant.id : undefined,
                            billingCountryCode: DEFAULT_BILLING_COUNTRY_CODE,
                            subscriptionType: DEFAULT_SUBSCRIPTION_TYPE,
                            taxable: DEFAULT_TAXABLE,
                            status: DEFAULT_ACCOUNT_ACTIVE
                        }}
                    />
                </EncModal>
                <EncModal
                    isOpen={accountsUIStore.isEditAccountModalOpen}
                    closeModal={accountsUIStore.closeEditAccountModal}>
                    <EditAccount
                        closeFunc={accountsUIStore.closeEditAccountModal}
                        submitFunc={accountStore.updateAccount(accountsUIStore.singleSelectedAccount.id)}
                        onSuccess={onSuccessEditAccount}
                        auth0TenantOptions={auth0TenantStore.auth0TenantsOptions}
                        subscriptionTypeOptions={subscriptionTypeStore.subscriptionTypeOptions}
                        initialValues={{
                            name: accountsUIStore.singleSelectedAccount.name,
                            auth0Id: accountsUIStore.singleSelectedAccount.auth0Id,
                            billingCity: accountsUIStore.singleSelectedAccount.billingCity,
                            billingCountryCode: accountsUIStore.singleSelectedAccount.billingCountryCode,
                            subscriptionType: accountsUIStore.singleSelectedAccount.subscriptionType,
                            taxable: accountsUIStore.singleSelectedAccount.taxable,
                            status: accountsUIStore.singleSelectedAccount.status
                        }}
                    />
                </EncModal>
            </div>
        );
    };

    const renderConfirmArchive = () => {
        const additionalFields = (
            <span>
                <label htmlFor="reason">{t('account:account.reason.for.archival')}</label>
                <input type="text" onChange={(event) => {
                    accountsUIStore.setArchiveAccountReason(event.target.value);
                }}/>
            </span>
        );

        const confirmArchiveMessage = (
            <ConfirmMessage
                itemsArray={accountsUIStore.selectedAccounts}
                itemProperty={'name'}
                itemKey={t('user:user.this.account')}
                itemsKey={t('user:user.these.accounts')}
                confirmMessageTranslationKey={'account:account.archive.message'}
            />
        );

        return (
            <EncConfirm
                openConfirm={accountsUIStore.isConfirmArchiveOpen}
                closeConfirm={accountsUIStore.closeConfirmArchiveModal}
                confirmFunc={archiveAccount}
                confirmButtonLabel={t('account:account.archive.account')}
                confirmTitle={t('account:account.archive.confirm')}
                confirmMessage={confirmArchiveMessage}
                additionalFields={additionalFields}
            />
        );
    };

    if (subscriptionTypeStore.isLoading || auth0TenantStore.isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <div className="section__full--width--padding mt-4">
                <Button className="button__primary"
                        onClick={accountsUIStore.openNewAccountModal}>
                    {t('common:common.new')}
                </Button>

                {renderConfirmArchive()}
                {renderModals()}
            </div>

            <PageableTableSelect
                multiSelect={true}
                columnConfig={columnConfig}
                fetchPageableData={getPageableAccounts}
                handleRowsSelection={handleRowsSelection}
                rowId="id"
                tableButtons={renderTableButtons()}
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('account:account.data.name')}
                handleRowDoubleClick={handleRowDoubleClick}
                defaultColumnSorted="lastModifiedDate"
                tableSelectRef={ref => tableSelectRef.current = ref}
            />
        </div>
    );
});

export default ActiveAccounts;
