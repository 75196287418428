import BaseStore from '../../../../store/domain/base.store';
import {action, computed, makeObservable, observable} from 'mobx';

class ProductBundlesDataProductsUIStore extends BaseStore {
    selectedDataProducts;
    isAddDataProductModalOpen = false;
    isEditDataProductModalOpen = false;
    isDataProductDetailsModalOpen = false;
    isConfirmArchiveModalOpen = false;

    constructor() {
        super();
        this.setSelectedDataProducts([]);

        makeObservable(this, {
            selectedDataProducts: observable,
            isAddDataProductModalOpen: observable,
            isEditDataProductModalOpen: observable,
            isDataProductDetailsModalOpen: observable,
            isConfirmArchiveModalOpen: observable,
            setSelectedDataProducts: action,
            openAddDataProductModal: action,
            closeAddDataProductModal: action,
            openEditDataProductModal: action,
            closeEditDataProductModal: action,
            openDataProductDetailsModal: action,
            closeDataProductDetailsModal: action,
            openConfirmArchiveModal: action,
            closeConfirmArchiveModal: action,
            singleSelectedDataProduct: computed
        });

        this.createInitialState();
    }

    setSelectedDataProducts = (selectedDataProducts) => {
        this.selectedDataProducts = selectedDataProducts;
    };

    openAddDataProductModal = () => {
        this.isAddDataProductModalOpen = true;
    };

    closeAddDataProductModal = () => {
        this.isAddDataProductModalOpen = false;
    };

    openEditDataProductModal = () => {
        this.isEditDataProductModalOpen = true;
    };

    closeEditDataProductModal = () => {
        this.isEditDataProductModalOpen = false;
    };

    openDataProductDetailsModal = () => {
        this.isDataProductDetailsModalOpen = true;
    };

    closeDataProductDetailsModal = () => {
        this.isDataProductDetailsModalOpen = false;
    };

    openConfirmArchiveModal = () => {
        this.isConfirmArchiveModalOpen = true;
    };

    closeConfirmArchiveModal = () => {
        this.isConfirmArchiveModalOpen = false;
    };

    get singleSelectedDataProduct() {
        return this.selectedDataProducts
            && this.selectedDataProducts.length > 0
            && this.selectedDataProducts[0];
    }
}

const productBundlesDataProductsUIStore = new ProductBundlesDataProductsUIStore()
export default productBundlesDataProductsUIStore;