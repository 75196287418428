import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import columnConfig from './archived-users.column-config';
import PageableTableSelect from '../../../components/table-select/pageable';

let ArchivedUsers = (props) => {
    const {
        t, archivedUsersUIStore, userStore
    } = props;

    useEffect(() => {
        return () => archivedUsersUIStore.reset();
    }, [archivedUsersUIStore]);

    return (
        <div>
            <PageableTableSelect
                multiSelect={false}
                selectable={false}
                columnConfig={columnConfig}
                fetchPageableData={userStore.getPageableUsers(undefined, true)}
                rowId="id"
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('user.archived.users')}
                defaultColumnSorted="lastModifiedDate"
                propertyToDisableRow="transferred"
            />
        </div>
    );
};

export default inject(
    'accountStore',
    'archivedUsersUIStore',
    'userStore'
)(withTranslation(
    'user'
)(observer(ArchivedUsers)));