import React from 'react';

const PersonIcon = () => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="256" height="256"
             viewBox="0 0 256 256">
            <g>
                <circle className="cls-1" fill="#3e3e3e" cx="130" cy="130" r="126"/>
                <circle className="cls-2" fill="#7c7c7c" cx="126" cy="126" r="126"/>
            </g>
            <g>
                <ellipse className="cls-1" fill="#3e3e3e" cx="127" cy="91" rx="27.5" ry="31"/>
                <path className="cls-1" fill="#3e3e3e"
                      d="M187,190H69l7-36.84A26,26,0,0,1,101.53,132h52.94A26,26,0,0,1,180,153.16Z"/>
            </g>
            <path className="cls-3" fill="#0000004f"
                  d="M131.86,251.86a126.06,126.06,0,0,0,113.27-84.73L144,66l-41,41,38.34,38.34L65,185Z"/>
            <g>
                <ellipse className="cls-4" fill="#fff" cx="123" cy="86.25" rx="27.5" ry="31"/>
                <path className="cls-4" fill="#fff"
                      d="M183,185H65l7-36.84A26,26,0,0,1,97.53,127h52.94A26,26,0,0,1,176,148.16Z"/>
            </g>
        </svg>

    )
};

export default PersonIcon;