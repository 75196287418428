import React from 'react';
import {withTranslation} from 'react-i18next';
import {countryCodeUtil} from '../../../../utils';
import {
    EncForm,
    EncInputField,
    EncRadioField,
    EncSelectField,
    withEncFormHandler
} from '../../../../components/hocs/form-validation';
import PropTypes from 'prop-types';

let AddAccount = (props) => {
    const {formProperties, fieldProperties} = props;
    const {t} = props;

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="id"
                label="account:account.salesforce.id"
                validator={{
                    type: 'string',
                    rules: ['required', 'alphanumeric']
                }}
            />
            <EncInputField
                id="name"
                label="account:account.name"
                validator={{
                    type: 'string',
                    rules: ['required', 'alphanumericWithSpaceParenthesesAndAmpersand']
                }}
            />
            <EncSelectField
                id="auth0Id"
                label="account:account.auth0.tenant"
                options={props.auth0TenantOptions}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="billingCity"
                label="account:account.city"
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncSelectField
                id="billingCountryCode"
                label="account:account.country"
                options={countryCodeUtil.getBillingCountryCodeOptions()}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncSelectField
                id="subscriptionType"
                label="account:account.subscription.type"
                options={props.subscriptionTypeOptions}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="auth0ExternalConnectionNames"
                label="account:account.auth.external.connection.names.field"
                validator={{
                    type: 'string',
                    rules: []
                }}
            />
            <div className="radio__group">
                <label>{t('account:account.taxable')}</label>
                <EncRadioField
                    id="taxable"
                    label="common:common.yes"
                    value={'true'}
                />
                <EncRadioField
                    id="taxable"
                    label="common:common.no"
                    value={'false'}
                />
            </div>
            <div className="radio__group">
                <label>{t('account:account.status')}</label>
                <EncRadioField
                    id="status"
                    label="account:account.active"
                    value={'ACTIVE'}
                />
                <EncRadioField
                    id="status"
                    label="account:account.inactive"
                    value={'INACTIVE'}
                />
            </div>
        </EncForm>
    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'account:account.add.account',
    successMessage: 'Account successfully created',
    validateOnChange: false,
    validateOnBlur: true,
    confirmAlertMsg: (initialValues, submitValues) => {
        if (submitValues.status === 'INACTIVE') {
            return {
                header: 'account:account.confirm.add.account',
                messages: 'account:account.add.account.inactive.confirm.message'
            }
        }

        return undefined;
    }
};

AddAccount.propTypes = {
    auth0TenantOptions: PropTypes.array.isRequired,
    subscriptionTypeOptions: PropTypes.array.isRequired
};

export default withEncFormHandler(
    FORM_VALIDATION_OPTIONS
)(withTranslation()(AddAccount));


