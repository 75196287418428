import {action, makeObservable, toJS} from 'mobx';
import {loggingUtil} from '../../utils';

class BaseStore {
    initialState = {};

    constructor() {
        makeObservable(this, {
            reset: action
        });
        this.createInitialState();
    }

    createInitialState() {
        this.initialState = toJS(this);
    }

    reset() {
        if (!this.initialState) {
            loggingUtil.warn('.reset() does not work unless you call this.createInitialState() at the beginning of the store constructor');
            return;
        }
        Object
            .keys(this.initialState)
            .forEach(key => {
                if (key !== 'initialState') {
                    this[key] = this.initialState[key]
                }
            });
    }
}

export default BaseStore;
