import axiosInterface from '../../axios/axios-instance';
import {toastError, toastSuccess} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const ADMIN_USER_ENDPOINT = '/api/v1/admin-ui-user';

class AdminUserService {

    postAdminUser(adminUser) {
        return axiosInterface.post(ADMIN_USER_ENDPOINT, adminUser)
            .catch(error => {
                loggingUtil.error(`An error has occurred when creating Admin User: ${error}`);
                throw error;
            });
    }

    deleteAdminUsers(users) {
        return axiosInterface.delete(ADMIN_USER_ENDPOINT, {data: users})
            .then(() => {
                toastSuccess({
                    statusMessage: users.length > 1
                        ? 'admin:admin.users.successfully.deleted'
                        : 'admin:admin.user.successfully.deleted'
                });
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error.message});
                loggingUtil.error(`An error has occurred when deleting Admin Users: ${error}`);
                throw error;
            });
    }

    doesUserHaveAdminUIAccess(sourceUserId) {
        const params = {
            sourceUserId: sourceUserId
        };

        return axiosInterface.get(`${ADMIN_USER_ENDPOINT}/access`, {params})
            .then(response => {
                return response;
            })
            .catch(error => {
                if (error && error.response && error.response.status !== 403) {
                    toastError({statusMessage: 'common:common.an.error.occurred', label: error.message});
                    loggingUtil.error(`An error has occurred when retrieving Admin UI User: Id: [${sourceUserId}]: ${error}`);
                }
                throw error;
            });
    }

    getAdminUsers() {
        return axiosInterface.get(ADMIN_USER_ENDPOINT)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading Admin Users: ${error}`);
                throw error;
            });
    }
}

const adminUserService = new AdminUserService()
export default adminUserService;