import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const CONNECTION_INFORMATION_ENDPOINT = '/api/v1/connection-information';

class ConnectionInformationService {

    retrieveConnectionInformation(params) {
        return axiosInterface.get(CONNECTION_INFORMATION_ENDPOINT, {params})
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading connection information. ${error}`);
                throw error;
            });
    }
}

const connectionInformationService = new ConnectionInformationService()
export default connectionInformationService;