import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';

import './Header.scss';
import Logo from './Logo';
import UserCard from './user-card';

const Header = function Header({headerUIStore, authenticationStore, t}) {
    return authenticationStore.isSessionValidAndAdminUIUser && (
        <div className="header">
            <div className="header__item">
                <Logo/>
            </div>
            <div className="header__item header__item--title">
                <h2>{t(headerUIStore.titleKey)}</h2>
            </div>
            <UserCard/>
        </div>
    );
};

export default inject(
    'headerUIStore',
    'authenticationStore'
)(withTranslation()(observer(Header)));