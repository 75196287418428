import React from 'react';
import Modal from 'react-modal';
import {Button} from 'semantic-ui-react';
import './Alert.scss';

Modal.setAppElement(document.getElementById('root'));

class AlertModal extends React.Component {

    render() {
        return (
            <Modal
            isOpen={this.props.openAlert}
            closeTimeoutMS={300}
            onRequestClose={this.props.closeAlert}
            className="modal__alert"
            contentLabel="Alert Error"
            overlayClassName="modal__overlay--alert"
            >
                <h1>{this.props.alertTitle}</h1>
                <div>
                    {this.props.alertContent}
                </div>

                 <div className="button-box mt-2">
                     <Button onClick={this.props.closeAlert}>Dismiss</Button>
                 </div>


            </Modal>
        );
    }
}

export default AlertModal;