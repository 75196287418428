import {dateTimeUtil} from '../../../../../../utils';

const config = [
    {
        headerKey: 'common:common.profile.name',
        prop: 'profileName',
        width: 300,
        id: 'profileName'
    },
    {
        headerKey: 'common:common.policy.name',
        prop: 'policySearchName',
        width: 300,
        id: 'policySearchName'
    },
    {
        headerKey: 'common:common.policy.criteria',
        prop: 'policySearchCriteria',
        width: 300,
        id: 'policySearchCriteria'
    },
    {
        headerKey: 'common:common.policy.date',
        prop: 'policyDate',
        type: 'datetime',
        transform: policy => policy.policyDate ? dateTimeUtil.toDisplayDateTime(policy.policyDate) : null
    },
    {
        headerKey: 'common:common.policy.status',
        prop: 'policyStatus',
        id: 'policyStatus'
    },
    {
        headerKey: 'common:common.profile.owner',
        prop: 'sourceUserId',
        id: 'sourceUserId'
    }
];
export default config;