import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router';
import columnConfig from './active-account-users.column-config';
import {Button} from 'semantic-ui-react';
import {ActiveAccountUsersConfirmMessages} from './sub-components/confirm-messages';
import PageableTableSelect from '../../../../../../components/table-select/pageable';
import {customHandleRowSelection} from '../../../../../../utils/helpers';
import {ActiveAccountUsersTableButtons} from './sub-components/table-buttons';
import {useRefreshTable} from '../../../../../../utils/hooks';
import {ActiveAccountUsersModals} from './sub-components/modals';

let ActiveAccountUsers = (props) => {
    const {
        t, accountStore, activeAccountUsersUIStore, connectionInformationStore, userStore
    } = props;

    const params = useParams();
    const navigate =  useNavigate();

    const [refreshTable, setRefreshTable] = useRefreshTable();
    const handleRowSelection = customHandleRowSelection(activeAccountUsersUIStore.setSelectedUsers, navigateToUserOverview);

    useEffect(() => {
        return () => activeAccountUsersUIStore.reset();
    }, [activeAccountUsersUIStore]);

    function navigateToUserOverview() {
        let userOverviewPath = `/accounts/${params.accountId}/users/${activeAccountUsersUIStore.singleSelectedUser.salesforceId}`;
        navigate(userOverviewPath);
    }

    return (
        <div>
            <ActiveAccountUsersModals refreshTable={refreshTable}/>
            <ActiveAccountUsersConfirmMessages refreshTable={refreshTable}/>

            {
                accountStore.account.archived !== true && accountStore.account.validORPC &&
                <div className="section__full--width--padding mt-4">
                    <Button className="button__primary"
                            disabled={connectionInformationStore.isLoading}
                            onClick={activeAccountUsersUIStore.openAddNewUserModal}>
                        {t('common:common.new')}
                    </Button>
                </div>
            }

            <PageableTableSelect
                multiSelect={true}
                columnConfig={columnConfig}
                fetchPageableData={userStore.getPageableUsers(params.accountId)}
                handleRowsSelection={handleRowSelection}
                handleRowDoubleClick={handleRowSelection}
                rowId="id"
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('user.users')}
                tableButtons={<ActiveAccountUsersTableButtons/>}
                defaultColumnSorted='lastModifiedDate'
                tableSelectRef={setRefreshTable}
            />
        </div>
    );
};

export default inject(
    'accountStore',
    'activeAccountUsersUIStore',
    'connectionInformationStore',
    'userStore',
)(withTranslation(
    'user'
)(observer(ActiveAccountUsers)));