const config = [
    {
        headerKey: 'account:account.bundle.code',
        prop: 'productCode'
    },
    {
        headerKey: 'account:account.bundle.name',
        prop: 'name'
    },
    {
        headerKey: 'account:account.bundle.description',
        prop: 'description'
    }
];
export default config;