import React from 'react';
import {withTranslation} from 'react-i18next';
import columnConfig from './billing-runs-account-details.column-config';
import PropTypes from 'prop-types';
import TableSelect from '../../../components/table-select/TableSelect';
import {inject, observer} from 'mobx-react';
import {Button} from 'semantic-ui-react';
import poser, {PoseGroup} from 'react-pose';
import {
    profileColumnConfig,
    transactionColumnConfig
} from '../../accounts/pages/account-billing-runs/account-billing-run-details/account-billing-run-entry-details/index';
import AccountBillingRunEntryDetails
    from '../../accounts/pages/account-billing-runs/account-billing-run-details/account-billing-run-entry-details/index';
import RowDetails from '../../../components/row-details/RowDetails';
import rowDetailsConfig from './billing-run-details.row-details-config';

const BillingDetailsWrapper =  poser.div({
    enter: {
        x: 0,
        transition: {
            duration: 300,
            ease: 'easeInOut'
        }
    },
    exit: {
        x: '-100%',
        transition: {
            duration: 300,
            ease: 'easeInOut'

        }
    }
});

const BillingEntryWrapper =  poser.div({
    enter: {
        x: 0,
        transition: {
            duration: 300,
            ease: 'easeInOut'
        }
    },
    exit: {
        x: '100%',
        transition: {
            duration: 300,
            ease: 'easeInOut'
        }
    }
});

class BillingRunDetails extends React.Component {

    componentWillMount() {
        this.props.billingRunEntryStore.loadBillingRunEntries(this.props.billingRunSummary.id)
    }

    componentWillUnmount() {
        this.props.accountBillingRunEntryStore.reset();
        this.props.billingRunEntryStore.reset();
        this.props.billingRunsDetailsUIStore.reset();
    }

    handleRowSelection = (selectedAccountBillingRunEntry) => {
        if (selectedAccountBillingRunEntry && selectedAccountBillingRunEntry.length > 0) {
            this.props.billingRunsDetailsUIStore.setSelectedBillingRunEntries(selectedAccountBillingRunEntry);
        }
    };

    loadAndOpenBillingRunEntryDetails = (selectedAccountBillingRunEntry) => {
        this.props.accountBillingRunEntryStore.loadByEntryType(selectedAccountBillingRunEntry.id, selectedAccountBillingRunEntry.type);
        this.props.billingRunsDetailsUIStore.openBillingRunEntryDetails();
    };

    closeAccountBillingRunEntryDetails = () => {
        this.props.accountBillingRunEntryStore.reset();
        this.props.billingRunsDetailsUIStore.closeBillingRunEntryDetails();
    };

    render() {
        const {t} = this.props;

        return (
            <PoseGroup>
                {this.props.billingRunsDetailsUIStore.isBillingRunEntryDetailsOpen && !this.props.billingRunEntryStore.isLoading &&
                    <BillingEntryWrapper
                        key="billingRunEntry"
                        pose={(this.props.billingRunsDetailsUIStore.isBillingRunEntryDetailsOpen ? 'enter': 'exit')}>
                        {this.renderEntryDetails()}
                    </BillingEntryWrapper>
                }
                {!this.props.billingRunsDetailsUIStore.isBillingRunEntryDetailsOpen &&
                <BillingDetailsWrapper
                    key="billingRuns"
                    pose={this.props.billingRunsDetailsUIStore.isBillingRunEntryDetailsOpen ? 'exit': 'enter'}>
                    <div>
                        <div className='single__column'>
                            <h1 className="text-center">{t('account.billing.runs')}</h1>

                            <RowDetails dataObject={this.props.billingRunSummary}
                                        columnConfig={rowDetailsConfig}/>
                        </div>
                        <TableSelect
                            columnConfig={columnConfig}
                            rowData={this.props.billingRunEntryStore.billingRunEntries}
                            loading={this.props.billingRunEntryStore.isLoading}
                            handleRowsSelection={this.handleRowSelection}
                            handleRowDoubleClick={this.loadAndOpenBillingRunEntryDetails}
                            dataName={t('menu:menu.items')}
                            rowId="accountBillingRunEntryId"
                            tableButtons={this.renderTableButtons()}
                            isSticky={true}
                            stickyTopBarOffset={0}
                            stickyTopOffset={55}
                            filterInHeader={true}
                            defaultColumnSorted="accountName"
                        />
                    </div>
                </BillingDetailsWrapper>}
            </PoseGroup>
        );
    }

    renderTableButtons() {
        const {t} = this.props;

        return (
            <div>
                {
                    this.props.billingRunsDetailsUIStore.singleSelectedBillingRunEntry &&
                    <Button onClick={() => { this.loadAndOpenBillingRunEntryDetails(this.props.billingRunsDetailsUIStore.singleSelectedBillingRunEntry)}}>
                        {t('common:common.details')}
                    </Button>
                }
            </div>
        );
    }

    renderEntryDetails() {
        const {t} = this.props;
        var selectedAccountBillingRunEntry = this.props.billingRunsDetailsUIStore.singleSelectedBillingRunEntry;

        return (
            <AccountBillingRunEntryDetails
                backFunc={this.closeAccountBillingRunEntryDetails}
                header={selectedAccountBillingRunEntry.description}
                columnConfig={selectedAccountBillingRunEntry.type === 'TRANSACTION' ? transactionColumnConfig : profileColumnConfig}
                data={this.props.accountBillingRunEntryStore.getByEntryType(selectedAccountBillingRunEntry.type)}
                dataName={selectedAccountBillingRunEntry.type === 'TRANSACTION' ? t('account.transactions') : t('common:common.profiles')}
                loading={this.props.accountBillingRunEntryStore.isLoading}
            />
        );
    }
}

BillingRunDetails.propTypes = {
    billingRunSummary: PropTypes.object.isRequired
};

export default inject(
    'accountBillingRunEntryStore',
    'billingRunEntryStore',
    'billingRunsDetailsUIStore',
)(withTranslation([
    'account'
])(observer(BillingRunDetails)));