import BaseStore from '../base.store';
import {action, makeObservable, observable} from 'mobx';
import billingRunService from './billing-run.service';

class BillingRunEntryStore extends BaseStore {
    isLoading = false;
    billingRunEntries;

    constructor() {
        super();
        this.setIsLoading(true);
        this.setBillingRunEntries([]);

        makeObservable(this, {
            isLoading: observable,
            billingRunEntries: observable,
            setIsLoading: action,
            setBillingRunEntries: action,
            loadBillingRunEntries: action
        });

        super.createInitialState();
    }

    setIsLoading = (isLoading) => {
        this.isLoading = isLoading;
    };

    setBillingRunEntries = (billingRunEntries) => {
        this.billingRunEntries = billingRunEntries;
    };

    loadBillingRunEntries = (billingRunId) => {
        this.setIsLoading(true);

        return billingRunService.getDetailedBillingRun(billingRunId)
            .then(({data}) => {
                this.setBillingRunEntries(data.billingRunEntries || []);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };
}

const billingRunEntryStore = new BillingRunEntryStore()
export default billingRunEntryStore;