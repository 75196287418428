import React, { useEffect, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import TableSelect from '../../../../components/table-select/index';
import AssignProducts from './assign-products/index';
import { useTranslation } from 'react-i18next';
import EncModal from '../../../../components/enc-modal/index';
import columnConfig from './account-products.column-config';
import { MobXProviderContext, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

const AccountProducts = observer(() => {
    const { t } = useTranslation();
    const { accountId } = useParams();
    const { headerUIStore, accountStore, accountProductStore, accountProductsUIStore } = useContext(MobXProviderContext);

    useEffect(() => {
        accountProductStore.loadAccountProducts(accountId);
        accountStore.retrieveAccount(accountId)
            .then(() => headerUIStore.setTitleKey(accountStore.account.name));
    }, [accountId, accountProductStore, accountStore, headerUIStore]);

    const onSuccessAssignProduct = () => {
        accountProductsUIStore.closeAssignDataProductsModal();
        accountProductStore.loadAccountProducts(accountId);
    };

    const handleRowsSelection = selectedRows => {
        accountProductsUIStore.setSelectedDataProduct(selectedRows);
    };

    const handleUnassignAccountProducts = () => {
        const salesforceIds = accountProductsUIStore.selectedSalesforceIds;
        if (salesforceIds.length > 0) {
            accountProductStore.unassignProducts(salesforceIds)
                .then(() => accountProductStore.loadAccountProducts(accountId));
        }
    };

    const renderTableButtons = () => {
        const selectedDataProducts = accountProductsUIStore.selectedDataProducts || [];
        return selectedDataProducts.length === 0 ? null : <Button onClick={handleUnassignAccountProducts}>{t('account:account.unassign.product', { count: selectedDataProducts.length })}</Button>;
    };

    const renderModals = () => (
        <EncModal
            className="modal__overlay"
            isOpen={accountProductsUIStore.isAssignDataProductsModalOpen}
            closeModal={accountProductsUIStore.closeAssignDataProductsModal}>
            <AssignProducts
                assignedProductCodes={accountProductStore.accountProductCodes}
                accountId={accountId}
                onClose={accountProductsUIStore.closeAssignDataProductsModal}
                onSuccess={onSuccessAssignProduct}/>
        </EncModal>
    );

    return (
        <div className="content sticky-scroll">
            {renderModals()}
            <div className="section__full--width--padding mt-4">
                <Button className="button__primary" onClick={accountProductsUIStore.openAssignDataProductsModal}>
                    {t('account:account.assign.products')}
                </Button>
            </div>
            <TableSelect
                multiSelect={true}
                columnConfig={columnConfig}
                rowData={accountProductStore.accountProducts}
                loading={accountProductStore.isLoading}
                handleRowsSelection={handleRowsSelection}
                dataName={t('account:account.assigned.products')}
                rowId="salesforceId"
                tableButtons={renderTableButtons()}
                isSticky={true}
                stickyTopBarOffset={0}
                stickyTopOffset={55}
                defaultColumnSorted="lastModifiedDate"
            />
        </div>
    );
});

export default AccountProducts;
