import {dateTimeUtil} from '../../utils';

const config = [
    {
        headerKey: 'policyRunHistory:policy.run.history.encompass.policy.id',
        prop: 'encompassPolicyInstanceId',
        width: 200,
        id: 'encompassPolicyInstanceId'
    },
    {
        headerKey: 'policyRunHistory:policy.run.history.profile.name',
        prop: 'profileName',
        width: 300
    },
    {
        headerKey: 'policyRunHistory:policy.run.history.policy.search.name',
        prop: 'policySearchName',
        width: 300
    },
    {
        headerKey: 'policyRunHistory:policy.run.history.policy.search.criteria',
        prop: 'policySearchCriteria',
        width: 300
    },
    {
        headerKey: 'policyRunHistory:policy.run.history.policy.date',
        prop: 'policyDate',
        type: 'datetime',
        transform: policy => policy.policyDate ? dateTimeUtil.toDisplayDateTime(policy.policyDate) : null
    },
    {
        headerKey: 'policyRunHistory:policy.run.history.policy.status',
        prop: 'policyStatus'
    },
    {
        headerKey: 'policyRunHistory:policy.run.history.source.user.id',
        prop: 'sourceUserId'
    }
];
export default config;
