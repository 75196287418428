import {action, computed, makeObservable, observable} from 'mobx';
import BaseStore from '../../store/domain/base.store';
import moment from 'moment';

class BillingRunsUIStore extends BaseStore {

    dateRange;
    executeBillingRunStartDate;
    executeBillingRunEndDate;
    selectedBillingRuns;
    isNewBillingRunModalOpen = false;
    isBillingRunDetailsModalOpen = false;

    constructor() {
        super();
        this.setSelectedBillingRuns([]);
        this.initialiseDateRange();
        makeObservable(this, {
            dateRange: observable,
            setDateRange: action,
            initialiseDateRange: action,
            selectedBillingRuns: observable,
            isNewBillingRunModalOpen: observable,
            isBillingRunDetailsModalOpen: observable,
            setSelectedBillingRuns: action,
            openNewBillingRun: action,
            closeNewBillingRun: action,
            openBillingRunDetails: action,
            closeBillingRunDetails: action,
            singleSelectedBillingRun: computed
        });
        super.createInitialState();
    }

    setDateRange = dateRange => {
        this.dateRange = dateRange;
    };

    initialiseDateRange() {
        this.dateRange = {
            endDate: moment().utc(false),
            startDate: moment().utc(false).subtract(1, 'month')
        };
    };

    setExecuteBillingRunStartDate = executeBillingRunStartDate => {
        this.executeBillingRunStartDate = executeBillingRunStartDate;
    };

    setExecuteBillingRunEndDate = executeBillingRunEndDate => {
        this.executeBillingRunEndDate = executeBillingRunEndDate;
    };

    setSelectedBillingRuns = selectedBillingRuns => {
        this.selectedBillingRuns = selectedBillingRuns;
    };

    openNewBillingRun = () => {
        this.isNewBillingRunModalOpen = true;
    };

    closeNewBillingRun = () => {
        this.isNewBillingRunModalOpen = false;
    };

    openBillingRunDetails = () => {
        this.isBillingRunDetailsModalOpen = true;
    };

    closeBillingRunDetails = () => {
        this.isBillingRunDetailsModalOpen = false;
    };

    get singleSelectedBillingRun() {
        return this.selectedBillingRuns
            && this.selectedBillingRuns.length === 1
            && this.selectedBillingRuns[0];
    }
}

const billingRunsUIStore = new BillingRunsUIStore();
export default billingRunsUIStore;