import {dateTimeUtil} from '../../../../utils/index';

const config = [
    {
        headerKey: 'productBundles:product.bundle.product.id',
        prop: 'id',
        width: 175
    },
    {
        headerKey: 'productBundles:product.bundle.product.code',
        prop: 'chargeCode'
    },
    {
        headerKey: 'productBundles:product.bundle.product.name',
        prop: 'name'
    },
    {
        headerKey: 'productBundles:product.bundle.currency',
        prop: 'currencyIsoCode',
        width: 120
    },
    {
        headerKey: 'productBundles:product.bundle.price',
        prop: 'unitPrice',
        width: 100,
        type: 'number'
    },
    {
        headerKey: 'common.date.modified',
        prop: 'lastModifiedDate',
        width: 200,
        transform: product => {
            if (product.lastModifiedDate) {
                return dateTimeUtil.toDisplayDateTime(product.lastModifiedDate);
            }
            return null;
        }
    },
    {
        headerKey: 'common.last.modified.by',
        prop: 'lastModifiedById'
    }
];
export default config;