import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {inject} from 'mobx-react';
import {withTranslation} from 'react-i18next';

import MenuTrigger from '../MenuTrigger';
import {
    AccountsIcon,
    BillingRunsIcon,
    DashboardIcon,
    LogoutIcon,
    PolicyRunIcon,
    ProductBundlesIcon,
    ProfilesIcon,
    SettingsIcon,
    TransactionsIcon,
    UsersIcon
} from '../../../styles/icons/index';

let MainMenu = (props) => {
    let {authenticationStore, t, showCloseButton, closeOnClick} = props;

    let menuTrigger = !showCloseButton ? <div className="menu__trigger"/>
        : <MenuTrigger key="mainMenuTrigger" onClick={closeOnClick} isCloseTrigger={true}/>;

    return (
        <div className="app__menu">
            {menuTrigger}
            <div className="menu__items">
                <NavLink
                    to="/"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <DashboardIcon/>
                    {t('menu.dashboard')}
                </NavLink>
                <NavLink
                    to="/product-bundles"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <ProductBundlesIcon/>
                    {t('menu.product.bundles')}
                </NavLink>
                <NavLink
                    to="/accounts"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <AccountsIcon/>
                    {t('menu.accounts')}
                </NavLink>
                <NavLink
                    to="/users"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <UsersIcon/>
                    {t('menu.users')}
                </NavLink>
                <NavLink
                    to="/transactions"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <TransactionsIcon/>
                    {t('menu.transactions')}
                </NavLink>
                <NavLink
                    to="/profiles"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <ProfilesIcon/>
                    {t('menu.profiles')}
                </NavLink>
                <NavLink
                    to="/policy-run-history"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <PolicyRunIcon/>
                    {t('menu.policy.run.history')}
                </NavLink>
                <NavLink
                    to="/billing-runs"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <BillingRunsIcon/>
                    {t('menu.billing.runs')}
                </NavLink>
            </div>
            <div className="menu__items menu__items--common">
                <NavLink
                    to="/settings/admin/admin-users"
                    className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}>
                    <SettingsIcon/>
                    {t('menu.settings')}
                </NavLink>
                <Link to="/" className="menu__item" onClick={() => authenticationStore.logout()}>
                    <LogoutIcon/>
                    {t('menu.logout')}
                </Link>
            </div>
        </div>
    );
};

export default inject(
    'authenticationStore'
)(withTranslation(
    'menu'
)(MainMenu));