import React from 'react';
import {
    EncForm,
    EncInputField,
    EncRadioField,
    EncSelectField,
    withEncFormHandler
} from '../../../../../components/hocs/form-validation/index';

const currencyOptions = [
    {key: 'GBP', text: 'GBP', value: 'GBP'},
    {key: 'AUD', text: 'AUD', value: 'AUD'},
    {key: 'USD', text: 'USD', value: 'USD'},
    {key: 'EUR', text: 'EUR', value: 'EUR'},
    {key: 'SGD', text: 'SGD', value: 'SGD'}
];

const EditDataProduct = props => {
    const {formProperties, fieldProperties, existingProductCodes, excludedProductCode} = props;

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="name"
                label="productBundles:product.bundle.product.name"
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="chargeCode"
                label="productBundles:product.bundle.product.code"
                validator={{
                    type: 'string',
                    rules: ['required', 'uniqueProductCode'],
                    excludedProductCode: excludedProductCode,
                    existingProductCodes: existingProductCodes
                }}
            />
            <EncInputField
                id="unitPrice"
                label="productBundles:product.bundle.product.price"
                type="number"
                validator={{
                    type: 'number',
                    rules: ['required']
                }}
            />
            <EncSelectField
                id="currencyIsoCode"
                label="productBundles:product.bundle.currency"
                options={currencyOptions}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <div className="radio__group">
                <EncRadioField
                    id="productChargeCategory"
                    label="productBundles:product.bundle.none"
                    value={'NONE'}
                />
                <EncRadioField
                    id="productChargeCategory"
                    label="productBundles:product.bundle.taxable"
                    value={'TAXABLE'}
                />
                <EncRadioField
                    id="productChargeCategory"
                    label="productBundles:product.bundle.non.taxable"
                    value={'NON_TAXABLE'}
                />
            </div>
        </EncForm>
    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'productBundles:product.bundle.edit.data.product',
    successMessage: 'productBundles:product.bundle.data.product.updated',
    validateOnChange: false,
    validateOnBlur: true,
    closeOnPristine: true
};

export default withEncFormHandler(
    FORM_VALIDATION_OPTIONS
)(EditDataProduct);