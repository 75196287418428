import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../base.store';
import aboutService from './about.service';

class AboutStore extends BaseStore {
    buildInformation;
    isLoading = false;

    constructor() {
        super();

        this.setIsLoading(false);
        this.setBuildInformation(
            {
                gitCommitId : '',
                gitBuildTime : '',
                gitBranch: ''
            });

        makeObservable(this, {
            isLoading: observable,
            buildInformation: observable,
            setIsLoading: action,
            setBuildInformation: action
        });

        this.createInitialState();
    }

    setBuildInformation(buildInformation) {
        this.buildInformation = buildInformation;
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    loadBuildInformation = () => {
        this.setIsLoading(true);

        aboutService.retrieveBuildInformation()
            .then(({data}) => {
                this.setBuildInformation(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };
}

const aboutStore = new AboutStore()
export default aboutStore;