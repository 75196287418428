import React from 'react';
import {EncForm, EncInputField, withEncFormHandler} from '../../../../../../components/hocs/form-validation';

let AddAuth0Tenant = (props) => {
    const {formProperties, fieldProperties} = props;

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="name"
                label="common:common.name"
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="domain"
                label="auth0Tenant:auth0Tenant.domain"
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="clientId"
                label="auth0Tenant:auth0Tenant.client.id"
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="clientSecret"
                label="auth0Tenant:auth0Tenant.client.secret"
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
        </EncForm>
    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'auth0Tenant:auth0Tenant.add.auth0.tenant',
    successMessage: 'auth0Tenant:auth0Tenant.successfully.created',
    validateOnChange: false,
    validateOnBlur: true
};

export default withEncFormHandler(FORM_VALIDATION_OPTIONS)(AddAuth0Tenant);