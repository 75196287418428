import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {MobXProviderContext, observer} from 'mobx-react';
import {AnimatePresence, motion} from 'framer-motion';

import './Menu.scss';
import menuHelper from './menu.helper';
import MainMenu from './main-menu';
import AccountMenu from './account-menu';
import ProductBundleMenu from './product-bundle-menu';
import SettingsMenu from './settings-menu';

const ACCOUNT_SUB_MENU = "accountSubMenu";
const PRODUCT_BUNDLE_SUB_MENU = "productBundleSubMenu";
const SETTINGS_SUB_MENU = "settingsSubMenu";

const MenuWrapperVariants = {
    enter: {
        x: 0,
        y: -56,
        transition: { ease: "easeOut", duration: 0.2 }
    },
    exit: {
        x: -220,
        y: -56
    }
};

const Menu = observer(() => {
    const location = useLocation();
    const {authenticationStore} = useContext(MobXProviderContext);
    const [subMenu, setSubMenu] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        const setMenu = () => {
            let newSubMenu;

            if (menuHelper.isAccountSubMenu(location)) {
                newSubMenu = ACCOUNT_SUB_MENU;
            } else if (menuHelper.isProductBundleSubMenu(location)) {
                newSubMenu = PRODUCT_BUNDLE_SUB_MENU;
            } else if (menuHelper.isSettingsSubMenu(location)) {
                newSubMenu = SETTINGS_SUB_MENU;
            } else {
                newSubMenu = null;
            }

            setSubMenu(newSubMenu);
            setOpenMenu(!newSubMenu);
        };

        setMenu();
    }, [location]);

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

    const getSubMenuComponent = () => {
        switch (subMenu) {
            case ACCOUNT_SUB_MENU:
                return <AccountMenu key={'accountMenu'}
                                    accountUrl={menuHelper.getAccountProfileURL(location)}
                                    menuTriggerOnClick={toggleMenu} />;
            case PRODUCT_BUNDLE_SUB_MENU:
                return <ProductBundleMenu key={'productBundleMenu'}
                                          productBundleUrl={menuHelper.getProductProfileURL(location)}
                                          menuTriggerOnClick={toggleMenu} />;
            case SETTINGS_SUB_MENU:
                return <SettingsMenu key={'settingsMenu'}
                                     settingsUrl={menuHelper.getSettingsUrl(location)}
                                     menuTriggerOnClick={toggleMenu} />;
            default:
                return null;
        }
    };

    if (!authenticationStore.isSessionValidAndAdminUIUser) {
        return null;
    }

    if (!subMenu) {
        return <MainMenu showCloseButton={false} />;
    }

    return (
        <AnimatePresence>
            {getSubMenuComponent()}
            {openMenu && (
                <motion.div key={'mainMenu'}
                            variants={MenuWrapperVariants}
                            initial="exit"
                            animate="enter"
                            exit="exit">
                    <MainMenu showCloseButton={true}
                              closeOnClick={toggleMenu} />
                </motion.div>
            )}
        </AnimatePresence>
    );
});

export default Menu;
