const FIRST_ELEMENT = 0;

export default class ComponentUtil {

    findChildComponent(children, desiredChildId) {

        if (Array.isArray(children)) {

            let [head, ...tail] = children;
            if (!head || desiredChildId === head.props.id) {
                return head;
            }

            tail = tail.length === 1
                ? tail[FIRST_ELEMENT]
                : tail;

            return this.findChildComponent(tail, desiredChildId);

        } else {
            if (!children || desiredChildId === children.props.id) {
                return children;
            }
            return this.findChildComponent(children.props.children, desiredChildId);
        }
    };
}