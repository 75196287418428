import React from 'react';

function auth0TenantIcon() {

    return (
        <svg id="Layer_1"
             data-name="Layer 1"
             xmlns="http://www.w3.org/2000/svg"
             width="24"
             height="24"
             viewBox="0 0 24 24">
            <title>auth0-tenants</title>
            <path
                d="M12,24a.9.9,0,0,1-.54-.18L5.7,19.57l0,0a10.81,10.81,0,0,1-3.85-12L4,.68A.91.91,0,0,1,4.88,0H19.12A.91.91,0,0,1,20,.68l2.18,6.76a.14.14,0,0,1,0,.06,10.79,10.79,0,0,1-3.87,12.05h0l-5.76,4.22A.9.9,0,0,1,12,24ZM7.79,18.83,12,21.91l4.21-3.09L12,15.74ZM3.24,9.33a9,9,0,0,0,2.55,7.91l1.58-4.89Zm13.4,3,1.57,4.89a9,9,0,0,0,2.55-7.91ZM12,13.69a.9.9,0,0,1,.54.18L16,16.41l-1.33-4.13a.91.91,0,0,1,.33-1l3.5-2.56H14.2a.91.91,0,0,1-.87-.64L12,3.94,10.67,8.06a.91.91,0,0,1-.87.64H5.49L9,11.26a.9.9,0,0,1,.33,1L8,16.41l3.48-2.54A.9.9,0,0,1,12,13.69Zm2.87-6.82h5.19l-1.61-5H13.26Zm-10.93,0H9.13l1.61-5H5.55Z"/>
        </svg>
    )
}
export default auth0TenantIcon;
