import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const PROFILE_ENDPOINT = '/api/v1/profile';

class ProfileService {

    getPageableProfiles = (params) => {
        return axiosInterface.get(`${PROFILE_ENDPOINT}`, {params})
            .then(response => {
                return response;
            }).catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading profiles: ${error}`);
                throw error;
            });
    };

    getProfileCSV = (params) => {
        return axiosInterface.get(`${PROFILE_ENDPOINT}/csv`, {params})
            .then(response => {
                return response;
            });
    };
}

const profileService = new ProfileService()
export default profileService;