import {dateTimeUtil} from '../../utils';

const config = [
    {
        headerKey: 'account:account.profile.accountName',
        prop: 'accountName'
    },
    {
        headerKey: 'account:account.profile.owner',
        prop: 'profileOwner',
        width: 300
    },
    {
        headerKey: 'account:account.profile.name',
        prop: 'profileName',
        width: 300
    },
    {
        headerKey: 'account:account.profile.created',
        prop: 'createdDate',
        type: 'datetime',
        transform: profile => profile.createdDate ? dateTimeUtil.toDisplayDateTime(profile.createdDate) : null
    },
    {
        headerKey: 'account:account.profile.status',
        prop: 'currentStatus'
    },
    {
        headerKey: 'account:account.profile.id',
        prop: 'id',
        width: 200
    }
];
export default config;