import axios from 'axios'
import authenticationStore from '../domain/authentication/authentication.store';

const axiosInterface = () => {

    const baseURL = process.env.REACT_APP_ENV === 'DEV'
        ? 'http://localhost:8081'
        : `${window.location.protocol}//${window.location.host}`;

    const newAxiosInstance = axios.create({
        baseURL
    });

    newAxiosInstance.interceptors.request.use(
        config => {
            return authenticationStore.getAccessTokenSilently().then(accessToken => {
                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }
                return config;
            });
        },
        error => Promise.reject(error)
    );

    return newAxiosInstance;
};

const axiosInstance = axiosInterface();
export default axiosInstance;
