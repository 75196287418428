import {action, computed, makeObservable, observable} from 'mobx';
import BaseStore from '../../store/domain/base.store';

class AccountsUIStore extends BaseStore {
    selectedAccounts = [];
    isNewAccountModalOpen = false;
    isEditAccountModalOpen = false;
    isConfirmArchiveOpen = false;
    selectedSalesforceId = '';
    archiveAccountReason = '';

    constructor() {
        super();

        makeObservable(this, {
            selectedAccounts: observable,
            selectedSalesforceId: observable,
            isNewAccountModalOpen: observable,
            isEditAccountModalOpen: observable,
            isConfirmArchiveOpen: observable,
            setSelectedAccounts: action,
            setArchiveAccountReason: action,
            openEditAccountModal: action,
            closeEditAccountModal: action,
            openNewAccountModal: action,
            closeNewAccountModal: action,
            openConfirmArchiveModal: action,
            closeConfirmArchiveModal: action,
            singleSelectedAccount: computed
        });
        super.createInitialState();
    }

    setSelectedAccounts(selectedAccounts) {
        this.selectedAccounts = selectedAccounts;
        this.selectedSalesforceId = selectedAccounts.length === 1 ? selectedAccounts[0].id : '';

    }

    setArchiveAccountReason(archiveAccountReason) {
        this.archiveAccountReason = archiveAccountReason;
    }

    openEditAccountModal = () => {
        this.isEditAccountModalOpen = true;
    };

    closeEditAccountModal = () => {
        this.isEditAccountModalOpen = false;
    };

    openNewAccountModal = () => {
        this.isNewAccountModalOpen = true;
    };

    closeNewAccountModal = () => {
        this.isNewAccountModalOpen = false;
    };

    openConfirmArchiveModal = () => {
        this.isConfirmArchiveOpen = true;
    };

    closeConfirmArchiveModal = () => {
        this.isConfirmArchiveOpen = false;
    };

    get singleSelectedAccount() {
        return this.selectedAccounts
            && this.selectedAccounts.length === 1
            && this.selectedAccounts[0];
    }
}

const accountsUIStore = new AccountsUIStore();
export default accountsUIStore;