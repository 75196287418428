import i18n from 'i18next';
import en from './locales/en/common_en.json';
import account_en from './locales/en/account_en.json';
import auth0Tenant_en from './locales/en/auth0-tenant_en';
import dashboard_en from './locales/en/dashboard_en.json';
import productBundles_en from './locales/en/product-bundles_en.json';
import transactions_en from './locales/en/transactions_en.json';
import datepicker_en from './locales/en/date-picker_en.json';
import user_en from './locales/en/user_en.json';
import menu_en from './locales/en/menu_en.json';
import admin_en from './locales/en/admin_en.json';
import about_en from './locales/en/about_en.json';
import profiles_en from './locales/en/profiles_en.json';
import formValidation_en from './locales/en/form-validation_en.json';
import policyRunHistory_en from './locales/en/policy-run-history_en';
import {initReactI18next} from 'react-i18next';

const options = {
    // React already handles escaping (not needed for react)
    interpolation: { escapeValue: false },
    lng: 'en',
    fallbackLng: ['en'],
    debug: false,
    resources: {
        en: {
            common: en,
            about: about_en,
            account: account_en,
            auth0Tenant: auth0Tenant_en,
            dashboard: dashboard_en,
            productBundles: productBundles_en,
            transactions: transactions_en,
            datepicker: datepicker_en,
            user: user_en,
            menu: menu_en,
            admin: admin_en,
            formValidation: formValidation_en,
            profiles: profiles_en,
            policyRunHistory: policyRunHistory_en
        }
    },
    // allows keys to have phrases with . and :
    keySeparator: true,
    ns: [
        'about',
        'common',
        'account',
        'auth0Tenant',
        'dashboard',
        'productBundles',
        'transactions',
        'datepicker',
        'user',
        'menu',
        'admin',
        'formValidation',
        'profiles',
        'policyRunHistory'
    ],
    defaultNS: 'common'
};

i18n
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init(options);

export default i18n;