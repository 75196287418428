import {action, computed, makeObservable, observable} from 'mobx';

import BaseStore from '../../../store/domain/base.store';

class ArchivedUsersUIStore extends BaseStore {
    selectedUsers;
    isRestoreConfirmModalOpen = false;

    constructor() {
        super();
        this.setSelectedUsers([]);
        this.closeRestoreConfirmModal();

        makeObservable(this, {
            selectedUsers: observable,
            isRestoreConfirmModalOpen: observable,

            setSelectedUsers: action,
            openRestoreConfirmModal: action,
            closeRestoreConfirmModal: action,

            singleSelectedUser: computed,
            isSelectedUsersRestorable: computed
        });

        this.createInitialState();
    }

    setSelectedUsers = (selectedUsers) => {
        this.selectedUsers = selectedUsers;
    };

    openRestoreConfirmModal = () => {
        this.isRestoreConfirmModalOpen = true;
    };

    closeRestoreConfirmModal = () => {
        this.isRestoreConfirmModalOpen = false;
    };

    get singleSelectedUser() {
        return this.selectedUsers
            && this.selectedUsers.length > 0
            && this.selectedUsers[0];
    }

    get isSelectedUsersRestorable() {
        return this.selectedUsers
            && this.selectedUsers.length > 0
            && this.selectedUsers.every(user => user.accountValidORPC && !user.transferred);
    }
}

const archivedUsersUIStore = new ArchivedUsersUIStore()
export default archivedUsersUIStore;