import {fileUtil, paginationUtil} from '../../../utils';
import policyRunHistoryService from './policy-run-history.service';
import BaseStore from '../base.store';
import {makeObservable} from 'mobx';

class PolicyRunHistoryStore extends BaseStore {

    constructor() {
        super();

        makeObservable(this, {});

        this.createInitialState();
    }

    getPageablePolicyRunHistory = ({startDate, endDate}, accountId = undefined, contactId = undefined) =>
        (currentPage, pageSize, sortingFields, filter, tableSelectCallback) => {

        const params = {
            accountId: accountId,
            contactId: contactId,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            page: currentPage,
            size: pageSize,
            filter: filter,
            sort: paginationUtil.createSingleSortingParameter(sortingFields)
        };

        return policyRunHistoryService.getPageablePolicyRunHistory(params)
            .then(({data}) => {
                tableSelectCallback(data);
            });
    };

    exportToCsv = ({startDate, endDate}, filter, {accountId, accountName} = {}, {contactId, contactName} = {}) => {
        const params = {
            accountId: accountId,
            contactId: contactId,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            filter
        };

        let fileName;

        if (accountName) {
            fileName = `Account policy run histories ${accountName}`;
        } else if (contactName) {
            fileName = `User policy run histories ${contactName}`;
        } else {
            fileName = 'Encompass policy run histories';
        }

        fileName = `${fileName} ${startDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`;

        return policyRunHistoryService.getPolicyRunHistoryCSV(params)
            .then(({data}) => {
                fileUtil.download(data, fileName, '.csv');
            });
    };
}

const policyRunHistoryStore = new PolicyRunHistoryStore()
export default policyRunHistoryStore;
