import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {ConfirmMessage} from '../../../../../../../../components/enc-confirm/index';
import PropTypes from 'prop-types';
import EncConfirm from '../../../../../../../../components/enc-confirm/EncConfirm';

let ActiveAccountUsersConfirmMessages = (props) => {
    const {
        refreshTable,
        t, activeAccountUsersUIStore, userStore
    } = props;

    function handleArchiveUser() {
        userStore.archiveUser(activeAccountUsersUIStore.selectedUsers, activeAccountUsersUIStore.archiveUserReason)
            .then(() => refreshTable());

        activeAccountUsersUIStore.closeArchiveConfirmModal();
    }

    function activateUsers() {
        userStore.activateUsers(activeAccountUsersUIStore.selectedUsers)
            .then(() => refreshTable());

        activeAccountUsersUIStore.closeActivateConfirmModal();
    }

    function deactivateUsers() {
        userStore.deactivateUsers(activeAccountUsersUIStore.selectedUsers)
            .then(() => refreshTable());

        activeAccountUsersUIStore.closeDeactivateConfirmModal();
    }

    const archiveMessage = (
        <ConfirmMessage
            itemsArray={activeAccountUsersUIStore.selectedUsers}
            itemProperty={'email'}
            itemKey={'user'}
            itemsKey={'users'}
            confirmMessageTranslationKey={'account:account.archive.selected.user.message'}
            confirmMessageLimitDisplayTranslationKey={'account:account.archive.selected.users.message'}
        />
    );

    const activateMessage = (
        <ConfirmMessage
            itemsArray={activeAccountUsersUIStore.selectedUsers}
            itemProperty={'email'}
            itemKey={t('user:user.this.user')}
            itemsKey={t('user:user.these.users')}
            confirmMessageTranslationKey={'user:user.activate.user.message'}
            confirmMessageLimitDisplayTranslationKey={'user:user.activate.users.limit.display.message'}
        />
    );

    const deactivateMessage = (
        <ConfirmMessage
            itemsArray={activeAccountUsersUIStore.selectedUsers}
            itemProperty={'email'}
            itemKey={t('user:user.this.user')}
            itemsKey={t('user:user.these.users')}
            confirmMessageTranslationKey={'user:user.deactivate.user.message'}
            confirmMessageLimitDisplayTranslationKey={'user:user.deactivate.users.limit.display.message'}
        />
    );

    const additionalFields = (
        <span>
            <label htmlFor="reason">{t('account:account.reason.for.archival')}</label>
            <input type="text" onChange={(event) => {
                activeAccountUsersUIStore.setArchiveUserReason(event.target.value);
            }}/>
        </span>
    );

    return (
        <div>
            <EncConfirm
                openConfirm={activeAccountUsersUIStore.isArchiveConfirmOpen}
                closeConfirm={activeAccountUsersUIStore.closeArchiveConfirmModal}
                confirmFunc={handleArchiveUser}
                confirmButtonLabel={t('user.archive.user', {count: activeAccountUsersUIStore.selectedUsers.length})}
                confirmTitle={t('account:account.archive.confirm')}
                confirmMessage={archiveMessage}
                additionalFields={additionalFields}
            />

            <EncConfirm
                openConfirm={activeAccountUsersUIStore.isActivateConfirmOpen}
                closeConfirm={activeAccountUsersUIStore.closeActivateConfirmModal}
                confirmFunc={activateUsers}
                confirmButtonLabel={t('user.activate.user', {count: activeAccountUsersUIStore.selectedUsers.length})}
                confirmTitle={t('user.confirm.activate')}
                confirmMessage={activateMessage}
            />

            <EncConfirm
                openConfirm={activeAccountUsersUIStore.isDeactivateConfirmOpen}
                closeConfirm={activeAccountUsersUIStore.closeDeactivateConfirmModal}
                confirmFunc={deactivateUsers}
                confirmButtonLabel={t('user.deactivate.user', {count: activeAccountUsersUIStore.selectedUsers.length})}
                confirmTitle={t('user.confirm.deactivate')}
                confirmMessage={deactivateMessage}
            />
        </div>
    );
};

ActiveAccountUsersConfirmMessages.propTypes = {
    refreshTable: PropTypes.func.isRequired
};

export default inject(
    'activeAccountUsersUIStore',
    'userStore'
)(withTranslation(
    'user'
)(observer(ActiveAccountUsersConfirmMessages)));