import {action, computed, makeObservable, observable} from 'mobx';
import BaseStore from '../../../store/domain/base.store';

class BillingRunDetailsUIStore extends BaseStore {
    selectedBillingRunEntries;
    isBillingRunEntryDetailsOpen = false;

    constructor() {
        super();
        this.setSelectedBillingRunEntries([]);
        makeObservable(this, {
            selectedBillingRunEntries: observable,
            isBillingRunEntryDetailsOpen: observable,
            setSelectedBillingRunEntries: action,
            openBillingRunEntryDetails: action,
            closeBillingRunEntryDetails: action,
            singleSelectedBillingRunEntry: computed
        });
        super.createInitialState();
    }

    setSelectedBillingRunEntries = selectedBillingRunEntries => {
        this.selectedBillingRunEntries = selectedBillingRunEntries;
    };

    openBillingRunEntryDetails = () => {
        this.isBillingRunEntryDetailsOpen = true;
    };

    closeBillingRunEntryDetails = () => {
        this.isBillingRunEntryDetailsOpen = false;
    };

    get singleSelectedBillingRunEntry() {
        return this.selectedBillingRunEntries
            && this.selectedBillingRunEntries.length === 1
            && this.selectedBillingRunEntries[0];
    }
}

const billingRunDetailsUIStore = new BillingRunDetailsUIStore()
export default billingRunDetailsUIStore;