import {dateTimeUtil} from '../../utils';

const config = [
    {
        headerKey: 'transactions:transactions.matter.reference',
        prop: 'matterReference'
    },
    {
        headerKey: 'transactions:transactions.transaction.date',
        prop: 'endTransaction',
        width: 200,
        transform: transaction => {
            if (transaction.endTransaction) {
                return dateTimeUtil.toDisplayDateTime(transaction.endTransaction);
            }
            return null;
        }
    },
    {
        headerKey: 'transactions:transactions.product',
        prop: 'encompassSearchProduct'
    },
    {
        headerKey: 'transactions:transactions.price',
        prop: 'productPrice',
        width: 145
    },
    {
        headerKey: 'transactions:transactions.billed.by',
        prop: 'billedBy'
    },
    {
        headerKey: 'common.email',
        prop: 'channelPartnerUserId'
    }
];
export default config;