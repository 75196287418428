import StringUtil from './string.util';
import RegExpUtil from './regexp.util';
import DateTimeUtil from './datetime.util';
import FileUtil from './file.util';
import LoggingUtil from './logging.util';
import NumberUtil from './number.util';
import PaginationUtil from './pagination.util';
import ComponentUtil from './component.util';
import CountryCodeUtil from './country-code.util';

export const stringUtil = new StringUtil();
export const regExpUtil = new RegExpUtil();
export const dateTimeUtil = new DateTimeUtil();
export const fileUtil = new FileUtil();
export const loggingUtil = new LoggingUtil();
export const numberUtil = new NumberUtil();
export const paginationUtil = new PaginationUtil();
export const componentUtil = new ComponentUtil();
export const countryCodeUtil = new CountryCodeUtil();