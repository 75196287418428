import {dateTimeUtil} from '../../../utils';

const config = [
    {
        headerKey: 'user:user.partnerUserId',
        prop: 'partnerUserId'
    },
    {
        headerKey: 'Account',
        prop: 'accountName'
    },
    {
        headerKey: 'user:user.archive.reason',
        prop: 'archiveReason',
        width: 300
    },
    {
        headerKey: 'user:user.last.login',
        prop: 'lastLoginDateTime',
        width: 200,
        type: 'datetime',
        transform: accountUser => accountUser.lastLoginDateTime ? dateTimeUtil.toDisplayDateTime(accountUser.lastLoginDateTime) : null
    },
    {
        headerKey: 'user:user.type',
        prop: 'type',
        width: 80
    },
    {
        headerKey: 'common.date.modified',
        prop: 'lastModifiedDate',
        width: 200,
        sortMethod: (d1, d2) => dateTimeUtil.compareDisplayDateTime(d1, d2),
        transform: accountUser => accountUser.lastModifiedDate ? dateTimeUtil.toDisplayDateTime(accountUser.lastModifiedDate) : null
    },
    {
        headerKey: 'common.last.modified.by',
        prop: 'lastModifiedBy',
    }
];
export default config;