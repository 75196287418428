import {dateTimeUtil} from '../../../../utils';

const config = [
    {
        headerKey: 'common.name',
        prop: 'name',
        width: 400
    },
    {
        headerKey: 'common.created.date',
        prop: 'createdDate',
        type: 'datetime',
        transform: (billingRun) => billingRun.createdDate ? dateTimeUtil.toDisplayDateTime(billingRun.createdDate) : null
    },
    {
        headerKey: 'common.start.date',
        prop: 'startDate',
        type: 'datetime',
        transform: (billingRun) => billingRun.startDate ? dateTimeUtil.toDisplayDate(billingRun.startDate) : null
    },
    {
        headerKey: 'common.end.date',
        prop: 'endDate',
        type: 'datetime',
        transform: (billingRun) => billingRun.endDate ? dateTimeUtil.toDisplayDate(billingRun.endDate) : null
    },
    {
        headerKey: 'common.created.by',
        prop: 'createdBy',
        width: 500
    }
];
export default config;