import {dateTimeUtil} from '../../../../../utils';

const config = [
    {
        headerKey: 'account:account.billing.run.id',
        prop: 'id'
    },
    {
        headerKey: 'common.start.date',
        prop: 'startDate',
        transform: (startDate) => dateTimeUtil.toDisplayDate(startDate)
    },
    {
        headerKey: 'common.end.date',
        prop: 'endDate',
        transform: (endDate) => dateTimeUtil.toDisplayDate(endDate)
    },
    {
        headerKey: 'account:account.zuora.account.number',
        prop: 'zuoraAccountNumber'
    },
    {
        headerKey: 'account:account.zuora.subscription.number',
        prop: 'zuoraSubscriptionNumber'
    },
    {
        headerKey: 'common.created.date',
        prop: 'createdDate',
        transform: (createdDate) => dateTimeUtil.toDisplayDateTime(createdDate)
    },
    {
        headerKey: 'common.created.by',
        prop: 'createdBy'
    }
];
export default config;