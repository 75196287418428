import {dateTimeUtil} from '../../../../utils/index';

const config = [
    {
        headerKey: 'productBundles:product.bundle.salesforce.id',
        prop: 'salesforceId'
    },
    {
        headerKey: 'productBundles:product.bundle.product.code',
        prop: 'productCode'
    },
    {
        headerKey: 'productBundles:product.bundle.bundle.name',
        prop: 'name'
    },
    {
        headerKey: 'productBundles:product.bundle.bundle.description',
        prop: 'description'
    },
    {
        headerKey: 'productBundles:product.bundle.number.of.data.products',
        prop: 'numberOfDataProducts'
    },
    {
        headerKey: 'productBundles:product.bundle.number.of.feature.products',
        prop: 'numberOfFeatureProducts'
    },
    {
        headerKey: 'productBundles:product.bundle.type',
        prop: 'type'
    },
    {
        headerKey: 'common.date.modified',
        prop: 'lastModifiedDate',
        transform: lastModifiedDate => lastModifiedDate && dateTimeUtil.toDisplayDateTime(lastModifiedDate)
    },
    {
        headerKey: 'common.last.modified.by',
        prop: 'lastModifiedById'
    },
    {
        headerKey: 'common.created.date',
        prop: 'createdDate',
        transform: createdDate => createdDate && dateTimeUtil.toDisplayDateTime(createdDate)
    },
    {
        headerKey: 'common.created.by',
        prop: 'createdById'
    }
];
export default config;