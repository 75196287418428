import {dateTimeUtil} from '../../../../../../utils';

const config = [
    {
        headerKey: 'user:user.firstName',
        prop: 'firstName'
    },
    {
        headerKey: 'user:user.lastName',
        prop: 'lastName'
    },
    {
        headerKey: 'user:user.date.of.birth',
        prop: 'birthDate',
        transform: birthDate => birthDate && dateTimeUtil.toDisplayDate(birthDate)
    },
    {
        headerKey: 'user:user.email',
        prop: 'email'
    },
    {
        headerKey: 'user:user.telephone.number',
        prop: 'phone'
    },
    {
        headerKey: 'user:user.connection',
        prop: 'connection'
    },
    {
        headerKey: 'common.sub.account',
        prop: 'subAccountName'
    },
    {
        headerKey: 'user:user.username',
        prop: 'partnerUserId'
    },
    {
        headerKey: 'user:user.last.login',
        prop: 'lastLoginDateTime',
        transform: lastLoginDateTime => lastLoginDateTime && dateTimeUtil.toDisplayDateTime(lastLoginDateTime)
    },
    {
        headerKey: 'user:user.salesforce.id',
        prop: 'salesforceId'
    },
    {
        headerKey: 'user:user.type',
        prop: 'type'
    },
    {
        headerKey: 'user:user.account.admin',
        prop: 'accountAdmin'
    },
    {
        headerKey: 'user:user.api.access',
        prop: 'apiAccessAllowed'
    },
    {
        headerKey: 'user:user.ui.access',
        prop: 'uiAccessAllowed'
    },
    {
        headerKey: 'user:user.factset.access',
        prop: 'factsetAccessAllowed'
    },
    {
        headerKey: 'common.date.modified',
        prop: 'lastModifiedDate',
        transform: lastModifiedDate => lastModifiedDate && dateTimeUtil.toDisplayDateTime(lastModifiedDate)
    },
    {
        headerKey: 'common.last.modified.by',
        prop: 'lastModifiedBy'
    }
];
export default config;
