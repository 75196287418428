import validator from 'validator';
import {getI18n} from 'react-i18next';
import * as Yup from 'yup';

const t = (msg, values) => getI18n().t(msg, values);

function alphanumericValidator() {

    Yup.addMethod(Yup.string, 'alphanumeric', function () {
        return this.test('alphanumericTest', 'Invalid password', function (value) {
            const {path, createError} = this;

            return value && validator.isAlphanumeric(value) ? true
                : createError({path, message: t('formValidation:form.validation.alphanumeric')});
        });
    });
    Yup.addMethod(Yup.string, 'alphanumericWithSpace', function () {
        return this.test('alphanumericWithSpaceTest', 'Invalid password', function (value) {
            const {path, createError} = this;

            return value && validator.isAlphanumeric(value.replace(/\s/g, '')) ? true
                : createError({path, message: t('formValidation:form.validation.alphanumeric.with.space')});
        });
    });

    Yup.addMethod(Yup.string, 'alphanumericWithSpaceAndAmpersand', function () {
        return this.test('alphanumericWithSpaceTest', 'Invalid password', function (value) {
            const {path, createError} = this;

            return value && validator.matches(value, /^[a-zA-Z0-9& ]*$/) ? true
                : createError({path, message: t('formValidation:form.validation.alphanumeric.with.space.ampersand')});
        });
    });

    Yup.addMethod(Yup.string, 'alphanumericWithSpaceParenthesesAndAmpersand', function () {
        return this.test('alphanumericWithSpaceTest', 'Invalid password', function (value) {
            const {path, createError} = this;

            return value && validator.matches(value, /^[a-zA-Z0-9&() ]*$/) ? true
                : createError({
                    path,
                    message: t('formValidation:form.validation.alphanumeric.with.space.parentheses.ampersand')
                });
        });
    });

}
export default alphanumericValidator;
