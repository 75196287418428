class MenuHelper {

    isAccountSubMenu = (location) => {
        if (location && location.pathname) {
            let matches = location.pathname.match('accounts/[a-zA-Z0-9]');
            return !!(matches && matches.length > 0);
        }

        return false;
    };

    isProductBundleSubMenu = (location) => {
        if (location && location.pathname) {
            let matches = location.pathname.match('product-bundles/[a-zA-Z0-9]');
            return !!(matches && matches.length > 0);
        }

        return false;
    };

    isAccountsUserSubMenu(location) {
        return !!this.getAccountsUserURL(location);
    }

    isSettingsSubMenu(location) {
        if (location && location.pathname) {
            let matches = location.pathname.match('settings/[a-zA-Z0-9]');
            return !!(matches && matches.length > 0);
        }

        return false;
    }

    getAccountsUserURL(location) {
        let matches = undefined;
        if (location && location.pathname) {
            matches = location.pathname.match('/accounts/[a-zA-Z0-9]+/users/[a-zA-Z0-9]+');
        }

        return (!matches || matches.length === 0) ? null : matches[0];
    }

    getAccountProfileURL(location) {
        let matches = undefined;
        if (location && location.pathname) {
            matches = location.pathname.match('/accounts/[a-zA-Z0-9]+');
        }

        return (!matches || matches.length === 0) ? undefined : matches[0];
    }

    getProductProfileURL(location) {
        let matches = undefined;
        if (location && location.pathname) {
            matches = location.pathname.match('/product-bundles/[a-zA-Z0-9]+');
        }

        return (!matches || matches.length === 0) ? undefined : matches[0];
    }

    getSettingsUrl(location) {
        let matches = undefined;
        if (location && location.pathname) {
            matches = location.pathname.match('/settings/[a-zA-Z0-9]+');
        }

        return (!matches || matches.length === 0) ? undefined : matches[0];
    }
}

const menuHelper = new MenuHelper();
export default menuHelper;