let host = window.location.host;
let aaaAuth0ClientId;
let auth0Domain;

if (process.env.REACT_APP_ENV === 'DEV' || host.includes('uat') || host.includes('test') || host.includes('dev') || host.includes('localhost')) {
    aaaAuth0ClientId = 'c7ac5P6EFY4qwhZKGU127rE4mJMyaWrJ';
    auth0Domain = 'auth.qa.encompassconfirm.com';
} else {
    // PROD AAA
    aaaAuth0ClientId = '96z0HEO39WkStRN19WIvSnuQOluGYiRM';
    auth0Domain = 'auth.encompassconfirm.com';
}

let auth0Config = {clientId: aaaAuth0ClientId, domain: auth0Domain, audience: 'https://encompasscorporation.com/aaa/api/v1'};
export default auth0Config;