import BaseStore from '../base.store';
import {action, computed, makeObservable, observable} from 'mobx';
import accountProductService from './account-product.service';

class AccountProductStore extends BaseStore {
    isLoading = false;
    accountProducts;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setAccountProducts([{}]);

        makeObservable(this, {
            accountProducts: observable,
            isLoading: observable,
            setAccountProducts: action,
            setIsLoading: action,
            accountProductCodes: computed
        });

        super.createInitialState();
    }

    setAccountProducts = (accountProducts) => {
        this.accountProducts = accountProducts;
    };

    setIsLoading = (isLoading) => {
        this.isLoading = isLoading;
    };

    loadAccountProducts = (accountId) => {
        this.setIsLoading(true);

        return accountProductService.getAccountProducts(accountId)
            .then(({data}) => {
                this.setAccountProducts(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    unassignProducts = (accountProductIds) => {
        this.setIsLoading(true);

        return accountProductService.unassignAccountProducts(accountProductIds)
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    saveAccountProducts = (selectedProductBundles, accountId, onSuccess) => {
        let accountBundles = [];

        if (selectedProductBundles instanceof Array) {
            accountBundles = selectedProductBundles.map(selectedItem => {
                return {
                    'name': selectedItem.name,
                    'accountId': accountId,
                    'productId': selectedItem.salesforceId
                };
            });
        }

        return accountProductService.createAccountProducts(accountBundles)
            .then(() => {
                onSuccess && onSuccess();

            });
    };

    get accountProductCodes() {
        if (!(this.accountProducts instanceof Array)) {
            return [];
        }

        return this.accountProducts.map(product => product.productCode);
    };
}

const accountProductStore = new AccountProductStore()
export default accountProductStore;