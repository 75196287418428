import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import ActiveAccounts from './active-accounts/index';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import ArchivedAccounts from './archived-accounts/index';


class Accounts extends React.Component {

    constructor(props) {
        super(props);

        this.props.headerUIStore.setTitleKey('account:account.title');
    }

    render() {
        const {t} = this.props;

        return (
            <div className="content sticky-scroll">

                <Tabs>
                    <TabList>
                        <Tab>{t('account:account.title')}</Tab>
                        <Tab>{t('account:account.archived.accounts')}</Tab>
                    </TabList>
                    <TabPanel>
                        <ActiveAccounts/>
                    </TabPanel>
                    <TabPanel>
                        <ArchivedAccounts/>
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

export default inject(
    'headerUIStore',
)(withTranslation()(observer(Accounts)));