import axiosInterface from '../../axios/axios-instance';
import {toastError, toastSuccess} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const PRODUCT_ENDPOINT = '/api/v1/products';

class ProductService {

    getAllProducts() {
        return axiosInterface.get(PRODUCT_ENDPOINT)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading products: ${error}`);
                throw error;
            });
    };

    getProductById(productId) {
        return axiosInterface.get(`${PRODUCT_ENDPOINT}/${productId}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                loggingUtil.error(`An error has occurred when loading product. Id: [${productId}]: ${error}`);
                throw error;
            });
    };

    archiveProducts(salesforceIds) {
        return axiosInterface.delete(PRODUCT_ENDPOINT, {data: salesforceIds})
            .then(() => {
                toastSuccess({statusMessage: 'productBundles:product.bundle.product.bundle.archived'});
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when archiving products: ${error}`);
                throw error;
            });
    };

    updateProduct(product) {
        return axiosInterface.put(PRODUCT_ENDPOINT, product)
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when updating product: Id: [${product.salesforceId}]: ${error}`);
                throw error;
            });
    }

    createProduct(product) {
        return axiosInterface.post(PRODUCT_ENDPOINT, product)
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when creating product: ${error}`);
                throw error;
            });
    };
}

const productService = new ProductService()
export default productService;