import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import EditProductBundle from './edit-product-bundle/index';
import TableSelect from '../../components/table-select/index';
import AddProductBundle from './add-product-bundle/index';
import '../../components/button/Button.scss';
import EncModal from '../../components/enc-modal';
import ConfirmMessage from '../../components/enc-confirm/ConfirmMessage';
import EncConfirm from '../../components/enc-confirm/EncConfirm';
import columnConfig from './product-bundles.column-config';
import { MobXProviderContext } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

const ProductBundles = observer(() => {
    const { productBundleStore, productBundleArrayStore, productBundlesUIStore, headerUIStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        headerUIStore.setTitleKey('productBundles:product.bundle.title');
        productBundleArrayStore.loadProductBundles();

        return () => {
            productBundlesUIStore.reset();
            productBundleArrayStore.reset();
        };
    }, [headerUIStore, productBundleArrayStore, productBundlesUIStore]);

    const handleRowsSelection = selectedProductBundles => {
        productBundlesUIStore.setSelectedProductBundles(selectedProductBundles);
    };

    const handleRowDoubleClick = selectedProductBundle => {
        navigateToProductBundleOverview(selectedProductBundle);
    };

    const navigateToProductBundleOverview = selectedProductBundle => {
        navigate(`/product-bundles/${selectedProductBundle.id}`);
    };

    const archiveProductBundles = () => {
        productBundleArrayStore.archiveProductBundles(productBundlesUIStore.selectedSalesforceIds)
            .then(() => {
                productBundleArrayStore.loadProductBundles();
                productBundlesUIStore.closeArchiveConfirmModal();
            });
    };

    const successfulAddProductBundle = () => {
        productBundleArrayStore.loadProductBundles();
        productBundlesUIStore.closeAddNewProductBundleModal();
    };

    const getSingleSelectedProductBundle = () => {
        let salesforceId = productBundlesUIStore.singleSelectedProductBundle.salesforceId;
        const productBundle = productBundleArrayStore.productBundles.find(productBundle => productBundle.salesforceId === salesforceId) || {};

        return {
            name: productBundle.name,
            productCode: productBundle.productCode,
            description: productBundle.description
        };
    };

    const onSuccessEditProductBundle = () => {
        productBundleArrayStore.loadProductBundles();
        productBundlesUIStore.closeEditProductBundleModal();
    };

    const renderTableButtons = () => {
        return (
            <div>
                {
                    productBundlesUIStore.selectedProductBundles.length === 1 &&
                    <span>
                        <Button onClick={() => navigateToProductBundleOverview(productBundlesUIStore.singleSelectedProductBundle)}>{t('common:common.open')}</Button>
                        <Button onClick={productBundlesUIStore.openEditProductBundleModal}>{t('common:common.edit')}</Button>
                    </span>
                }
                {
                    productBundlesUIStore.selectedProductBundles.length > 0 &&
                    <Button onClick={productBundlesUIStore.openArchiveConfirmModal}>{t('common:common.archive')}</Button>
                }
            </div>
        );
    };

    const renderModals = () => {
        return (
            <div>
                <EncModal
                    isOpen={productBundlesUIStore.isAddNewProductBundleModalOpen}
                    closeModal={productBundlesUIStore.closeAddNewProductBundleModal}>
                    <AddProductBundle
                        submitFunc={productBundleStore.createProductBundle}
                        closeFunc={productBundlesUIStore.closeAddNewProductBundleModal}
                        onSuccess={successfulAddProductBundle}/>
                </EncModal>
                <EncModal
                    isOpen={productBundlesUIStore.isEditProductBundleModalOpen}
                    closeModal={productBundlesUIStore.closeEditProductBundleModal}>
                    <EditProductBundle
                        initialValues={getSingleSelectedProductBundle()}
                        closeFunc={productBundlesUIStore.closeEditProductBundleModal}
                        submitFunc={productBundleStore.updateProductBundle(productBundlesUIStore.singleSelectedProductBundle.salesforceId)}
                        onSuccess={onSuccessEditProductBundle}
                    />
                </EncModal>
            </div>
        );
    };

    const renderConfirm = () => {
        const confirmButtonLabel = productBundlesUIStore.selectedProductBundles.length > 1
            ? t('productBundles:product.bundle.archive.bundles')
            : t('productBundles:product.bundle.archive.bundle');

        const archiveMessage = (
            <ConfirmMessage
                itemsArray={productBundlesUIStore.selectedProductBundles}
                itemProperty={'name'}
                itemKey={'Product bundle'}
                itemsKey={'Product bundles'}
                confirmMessageTranslationKey={'productBundles:product.bundle.archive.selected.bundle.message'}
            />
        );

        return (
            <EncConfirm
                openConfirm={productBundlesUIStore.isArchiveConfirmOpen}
                closeConfirm={productBundlesUIStore.closeArchiveConfirmModal}
                confirmFunc={archiveProductBundles}
                confirmButtonLabel={confirmButtonLabel}
                confirmTitle={t('productBundles:product.bundle.archive.confirm')}
                confirmMessage={archiveMessage}
            />
        );
    };

    return (
        <div>
            {renderModals()}
            {renderConfirm()}
            <div className="content sticky-scroll">
                <div className="section__full--width--padding mt-4">
                    <Button className="button__primary" onClick={productBundlesUIStore.openAddNewProductBundleModal}>
                        {t('common:common.new')}
                    </Button>
                </div>
                <TableSelect
                    multiSelect={true}
                    columnConfig={columnConfig}
                    rowData={productBundleArrayStore.productBundles}
                    loading={productBundleArrayStore.isLoading}
                    handleRowsSelection={handleRowsSelection}
                    handleRowDoubleClick={handleRowDoubleClick}
                    rowId="id"
                    isSticky={true}
                    stickyTopOffset={55}
                    stickyTopBarOffset={0}
                    dataName={t('productBundles:product.bundle.data.name')}
                    tableButtons={renderTableButtons()}
                    defaultColumnSorted="lastModifiedDate"
                />
            </div>
        </div>
    );
});

export default ProductBundles;
