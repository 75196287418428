import React, {useEffect} from 'react';
import {HashRouter, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import '../translation/i18.js';
import MobxContextProvider from '../store/mobx-context-provider';
import Header from './header';
import Menu from './menu';
import Dashboard from '../pages/dashboard';
import ProductBundles from '../pages/product-bundles';
import ProductBundleOverview from '../pages/product-bundles/pages/product-bundle-overview';
import ProductBundleDataProducts from '../pages/product-bundles/pages/product-bundle-data-products';
import AuthenticatedRoute from './authenticated-route';
import About from '../pages/settings/about';
import Accounts from '../pages/accounts';
import AccountOverview from '../pages/accounts/pages/account-overview';
import AccountProducts from '../pages/accounts/pages/account-products';
import AccountProfiles from '../pages/accounts/pages/account-profiles';
import AccountTransactions from '../pages/accounts/pages/account-transactions';
import AccountUsers from '../pages/accounts/pages/account-users';
import BillingRuns from '../pages/billing-runs';
import AccountBillingRuns from '../pages/accounts/pages/account-billing-runs';
import AccountPolicyRunHistory from '../pages/accounts/pages/account-policy-run-history';
import AdminUsers from '../pages/settings/admin-users';
import Transactions from '../pages/transactions';
import Users from '../pages/users';
import UserOverview from '../pages/accounts/pages/account-users/pages/user-overview';
import UserProfiles from '../pages/accounts/pages/account-users/pages/user-profiles';
import UserTransactions from '../pages/accounts/pages/account-users/pages/user-transactions';
import UserLoginHistory from '../pages/accounts/pages/account-users/pages/user-login-history';
import UserPolicyRunHistory from '../pages/accounts/pages/account-users/pages/user-policy-run-history';
import Profiles from '../pages/profiles';
import PolicyRunHistory from '../pages/policy-run-history';
import {Auth0Tenants} from '../pages/settings/auth0-tenants';
import {useAuth0} from "@auth0/auth0-react";
import authenticationStore from '../store/domain/authentication/authentication.store';
import adminUserStore from '../store/domain/adminuser/admin-user.store';
import {autorun} from "mobx";
import {useTranslation} from "react-i18next";

export default function App() {
    const {getAccessTokenSilently, isAuthenticated, user, isLoading, logout} = useAuth0();
    const {t} = useTranslation('common');
    authenticationStore.setGetAccessTokenSilently(getAccessTokenSilently);
    authenticationStore.setAuth0Logout(logout);

    useEffect(() => {
        function handleAdminUIAccessFailed(error) {
            localStorage.setItem('error_message', error);
            authenticationStore.logout();
        }

        return autorun(() => {
            authenticationStore.setIsAuthenticated(isAuthenticated);
            if (isAuthenticated) {
                localStorage.removeItem('error_message');
                adminUserStore.doesUserHaveAdminUIAccess(user.email)
                    .then(({data}) => {
                        if (!!data) {
                            authenticationStore.setIsAdminUIUser(true);
                        } else {
                            handleAdminUIAccessFailed(t('common.authentication.invalid.admin.ui.user'));
                        }
                    })
                    .catch(() => {
                        handleAdminUIAccessFailed(t('common.authentication.invalid.admin.ui.user'));
                    });
            }
        });
    }, [isAuthenticated, user, t]);

    const checkSession = () => {
        if (isAuthenticated) {
            getAccessTokenSilently().catch(() => {
                authenticationStore.logout();
            });
        }
    };

    useEffect(() => {
        setInterval(() => {
            checkSession();
        }, 30000);
    });

    if (isLoading) {
        return <div></div>;
    }

    return (
        <HashRouter>
            <MobxContextProvider>
                <>
                    <ToastContainer autoClose={5000}/>
                    <Header/>
                    <Menu/>
                    <Routes>
                        <Route path="/" element={
                            <AuthenticatedRoute>
                                <Dashboard/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/login" element={
                            <AuthenticatedRoute>
                                <div></div>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/product-bundles" element={
                            <AuthenticatedRoute>
                                <ProductBundles/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/product-bundles/:productBundleId" element={
                            <AuthenticatedRoute>
                                <ProductBundleOverview/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/product-bundles/:productBundleId/data-products" element={
                            <AuthenticatedRoute>
                                <ProductBundleDataProducts/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts" element={
                            <AuthenticatedRoute>
                                <Accounts/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId" element={
                            <AuthenticatedRoute>
                                <AccountOverview/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/products" element={
                            <AuthenticatedRoute>
                                <AccountProducts/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/profiles" element={
                            <AuthenticatedRoute>
                                <AccountProfiles/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/transactions" element={
                            <AuthenticatedRoute>
                                <AccountTransactions/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/policy-run-history" element={
                            <AuthenticatedRoute>
                                <AccountPolicyRunHistory/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/users" element={
                            <AuthenticatedRoute>
                                <AccountUsers/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/users/:userId/login-history" element={
                            <AuthenticatedRoute>
                                <UserLoginHistory/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/users/:userId" element={
                            <AuthenticatedRoute>
                                <UserOverview/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/users/:userId/profiles" element={
                            <AuthenticatedRoute>
                                <UserProfiles/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/users/:userId/policy-run-history" element={
                            <AuthenticatedRoute>
                                <UserPolicyRunHistory/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/users/:userId/transactions" element={
                            <AuthenticatedRoute>
                                <UserTransactions/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/accounts/:accountId/billing-runs" element={
                            <AuthenticatedRoute>
                                <AccountBillingRuns/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/billing-runs" element={
                            <AuthenticatedRoute>
                                <BillingRuns/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/transactions" element={
                            <AuthenticatedRoute>
                                <Transactions/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/users" element={
                            <AuthenticatedRoute>
                                <Users/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/profiles" element={
                            <AuthenticatedRoute>
                                <Profiles/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/policy-run-history" element={
                            <AuthenticatedRoute>
                                <PolicyRunHistory/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/settings/admin/admin-users" element={
                            <AuthenticatedRoute>
                                <AdminUsers/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/settings/admin/auth0-tenants" element={
                            <AuthenticatedRoute>
                                <Auth0Tenants/>
                            </AuthenticatedRoute>
                        }/>
                        <Route path="/settings/admin/about" element={
                            <AuthenticatedRoute>
                                <About/>
                            </AuthenticatedRoute>
                        }/>
                        <Route element={
                            <AuthenticatedRoute>
                                <Dashboard/>
                            </AuthenticatedRoute>
                        }/>
                    </Routes>
                </>
            </MobxContextProvider>
        </HashRouter>
    );
};