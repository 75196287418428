import axiosInterface from '../../axios/axios-instance';
import {toastError, toastSuccess} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const ACCOUNT_PRODUCT_ENDPOINT = '/api/v1/account-products';

class AccountProductService {

    getAccountProducts(accountId) {
        return axiosInterface.get(`${ACCOUNT_PRODUCT_ENDPOINT}/${accountId}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading account products. ${error}`);
                throw error;
            });
    }

    unassignAccountProducts(accountProductIds) {
        return axiosInterface.delete(ACCOUNT_PRODUCT_ENDPOINT, {data: accountProductIds})
            .then(() => {
                toastSuccess({statusMessage: 'account:account.unassigned.products.successful'});
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when unassignign product.: ${error}`);
                throw error;
            });
    }

    createAccountProducts(accountProducts) {
        return axiosInterface.post(`${ACCOUNT_PRODUCT_ENDPOINT}/bunch`, accountProducts)
            .then(() => {
                toastSuccess({statusMessage: 'account:account.account.products.assigned.successfully'});
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when assigning product bundles to an account: ${error}`);
                throw error;
            });

    };
}

const accountProductService = new AccountProductService()
export default accountProductService;