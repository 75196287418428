/**
 * Higher-Order Component for handling forms
 * Required parameters:
 *  - header: Title displayed
 *  - successMessage: Message to display on toast success
 *  - errorMessage: Message to display on toast error (defaults to common error message)
 *  - validateOnChange: Validate form on change
 *  - validateOnBlur: Validate form on blur
 *
 *  Optional parameters:
 *  - confirmAlertMsg: A function that returns an object {header, messages} to generate
 *                     a ConfirmMessage when submitting the form. Return undefined to show no message
 *
 * Required props:
 *  - submitFunc: Promise called when submit is clicked
 *  - onSuccess: Function called when submitFunc is successful
 *  - closeFunc: Function called when cancel button is clicked
 *
 * Optional props:
 *  - initialValues: Object containing initial values (Or a function that returns the object)
 *
 * Note: Any component that uses this should use the Form component with formProperties & fieldProperties passed as props
 */

import React from 'react';
import {Formik} from 'formik';
import formValidationHelper from './form-validation.helper';
import Loader from '../../loader/index';
import {toastGeneral, toastSuccess} from '../../notifications/index';
import ErrorMessage from './ErrorMessage';
import {getI18n} from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import EncConfirm from '../../enc-confirm/EncConfirm';
import ConfirmMessage from '../../enc-confirm/ConfirmMessage';

const t = (msg, values) => getI18n().t(msg, values);

const withEncFormHandler = ({
    header, successMessage, submitLabel, validateOnChange, validateOnBlur, confirmAlertMsg, closeOnPristine
}) => WrappedComponent => {
    class EncFormHandler extends React.Component {

        constructor(props) {
            super(props);

            this.state = {
                loading: true,
                initialValues: {},
                isConfirmOpen: false,
                focusedField: undefined
            };

            validateOnChange = false;
            validateOnBlur = false;
            closeOnPristine = closeOnPristine || false;
            submitLabel = submitLabel || 'common:common.save';

            this.childProps = _.omit(this.props, ['submitFunc', 'onSuccess', 'closeFunc', 'initialValues']);
        }

        componentDidMount() {
            this.initialiseValues();
        }

        initialiseValues() {
            if (this.props.initialValues) {
                Promise.resolve(this.props.initialValues instanceof Function ? this.props.initialValues() : this.props.initialValues)
                    .then(initialValues => {
                        let nullSafeInitialValues = {};

                        Object.keys(initialValues).forEach(key => {
                            if (typeof initialValues[key] === 'boolean' || typeof initialValues[key] === 'number') {
                                nullSafeInitialValues[key] = initialValues[key];
                            } else {
                                nullSafeInitialValues[key] = initialValues[key] || undefined;
                            }
                        });

                        this.setState({
                            initialValues: nullSafeInitialValues,
                            loading: false
                        });
                    })
                    .catch(() => {});
            } else {
                this.setState({loading: false});
            }
        }

        onSubmit = (values, {setSubmitting, setErrors}) => {
            if (this.confirmAlertResult) {
                this.setState({isConfirmOpen: true});
            } else {
                this.doSubmitFunc(values, setSubmitting, setErrors);
            }
        };

        doSubmitFunc = (values, setSubmitting, setErrors) => {
            Promise.resolve(this.props.submitFunc(values))
                .then(response => {
                    toastSuccess({statusMessage: successMessage});
                    this.props.onSuccess(response);
                })
                .catch(error => {
                    setSubmitting(false);
                    setErrors({
                        submit: error && error.response && error.response.data && error.response.data.message
                            ? error.response.data.message
                            : t('common:common.unknown.error.encountered')
                    });
                    this.setState({isConfirmOpen: false});
                });
        };

        setFieldValue = (formikApi) => (field, value) => {
            if (field !== value) {
                formikApi.setFieldValue(field, value);
            }
        };

        handleSubmit = (formikApi) => (event, {children: formFields}) => {
            if (closeOnPristine && !formikApi.dirty) {
                toastGeneral({statusMessage: 'common:common.no.changes'});
                this.props.onSuccess();
            } else {
                this.initialValues = formikApi.initialValues;
                this.schema = formValidationHelper.getSchema(formFields[0]);
                formikApi.handleSubmit(event);
            }
        };

        handleChange = (formikApi) => (event, form) => {
            // Field.Select && Field.Radio doesn't handle changes correctly, this fixes the issue
            if ('selection' in form || form.type === 'radio') {
                event.target.id = form.id;
                event.target.name = form.name;
                event.target.value = form.value;
            }

            formikApi.handleChange(event);
        };

        handleChangeWithNoEvent = (formikApi) => (id, value) => {
            formikApi.setFieldValue(id, value || undefined);
        };

        handleFocusChange = (formikApi) => (id) => {
            if (this.state.focusedField === id) {
                this.setState({focusedField: undefined});
                formikApi.setFieldTouched(id);
            } else {
                this.setState({focusedField: id});
            }
        };

        render() {
            if (this.state.loading) {
                return <Loader/>;
            }

            return (
                <Formik onSubmit={this.onSubmit}
                        enableReinitialize={true}
                        initialValues={this.state.initialValues}
                        validationSchema={() => this.schema}
                        validateOnChange={validateOnChange}
                        validateOnBlur={validateOnBlur}>
                    {
                        (formikApi) => (
                            <div className="single__column">
                                <h1 className="text-center">{t(header)}</h1>
                                {this.renderConfirmMessage(formikApi)}

                                <ErrorMessage error={formikApi.errors.submit}/>
                                <WrappedComponent{...this.childProps}
                                                 setFieldValue={this.setFieldValue(formikApi)}
                                                 initialValues={formikApi.initialValues}
                                                 formProperties={{
                                                     handleSubmit: this.handleSubmit(formikApi),
                                                     isSubmitting: formikApi.isSubmitting,
                                                     submitLabel: submitLabel,
                                                     closeFunc: this.props.closeFunc
                                                 }}
                                                 fieldProperties={{
                                                     values: formikApi.values,
                                                     errors: formikApi.errors,
                                                     focusedField: this.state.focusedField,
                                                     handleBlur: formikApi.handleBlur,
                                                     handleChange: this.handleChange(formikApi),
                                                     handleChangeWithNoEvent: this.handleChangeWithNoEvent(formikApi),
                                                     handleFocusChange: this.handleFocusChange(formikApi)
                                                 }}/>
                            </div>
                        )
                    }
                </Formik>
            );
        }

        renderConfirmMessage(formikApi) {
            this.confirmAlertResult = typeof confirmAlertMsg !== 'function' ? undefined : confirmAlertMsg(formikApi.initialValues, formikApi.values);

            if (!this.confirmAlertResult) {
                return null
            }

            return (
                <EncConfirm
                    openConfirm={this.state.isConfirmOpen}
                    closeConfirm={() => {
                        formikApi.setSubmitting(false);
                        this.setState({isConfirmOpen: false})
                    }}
                    confirmFunc={() => {
                        this.setState({isConfirmOpen: false});
                        this.doSubmitFunc(formikApi.values, formikApi.setSubmitting, formikApi.setErrors);
                    }}
                    confirmButtonLabel={t('common.confirm')}
                    confirmTitle={t(confirmAlertMsg(formikApi.initialValues, formikApi.values).header)}
                    confirmMessage={
                        <ConfirmMessage
                            itemsArray={[formikApi.values]}
                            itemProperty={this.confirmAlertResult.property}
                            itemKey={t(this.confirmAlertResult.itemKey)}
                            itemsKey={t(this.confirmAlertResult.itemsKey)}
                            confirmMessageTranslationKey={this.confirmAlertResult.messages}
                        />
                    }
                />
            );
        }
    }

    EncFormHandler.propTypes = {
        submitFunc: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        closeFunc: PropTypes.func.isRequired,
        initialValues: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.object
        ])
    };

    return EncFormHandler;
};

export default withEncFormHandler;