import React from 'react';

class StickyWrapper extends React.Component {

    handleStickyShadow = () => {
        const overlay = document.querySelector('.modal__overlay');
        if (overlay) {
            const scrollingElement = document.querySelector('.modal__overlay'),
                stickyElement = document.querySelector('.sticky__section.sticky__section--footer'),
                scrollTop = scrollingElement.scrollTop;

            if ((scrollingElement && stickyElement) && (scrollTop < (scrollingElement.scrollHeight - scrollingElement.clientHeight))) {
                scrollingElement.classList.add('is-sticky');
            } else {
                scrollingElement.classList.remove('is-sticky');
            }
        }
    };

    componentDidUpdate() {
        const scrollingElement = document.querySelector('.modal__overlay');

        if (scrollingElement) {
            this.handleStickyShadow();
            scrollingElement.addEventListener('scroll', this.handleStickyShadow, false);
            window.addEventListener('resize', this.handleStickyShadow, false);
        }
    }

    componentWillUnmount() {
        const scrollingElement = document.querySelector('.modal__overlay');

        if (scrollingElement) {
            this.handleStickyShadow();
            scrollingElement.addEventListener('scroll', this.handleStickyShadow, false);
            window.addEventListener('resize', this.handleStickyShadow, false);
        }
    }

    render() {
        return (
            <div className={`sticky__section ${this.props.className}`} style={
                {
                    position: 'sticky',
                    top: this.props.offsetTop !== undefined ? this.props.offsetTop : 'initial',
                    bottom: this.props.stickyFooter ? (this.props.offsetBottom || 0) : 'initial'
                }
            }>
                {this.props.children}
            </div>
        )
    }
}

export default StickyWrapper;