import {dateTimeUtil} from '../../../../../../utils';

const config = [
    {
        headerKey: 'account:account.profile.id',
        prop: 'salesforceId',
        width: 200,
        id: 'salesforceId'
    },
    {
        headerKey: 'account:account.profile.name',
        prop: 'profileName',
        width: 300,
        id: 'profileName'
    },
    {
        headerKey: 'account:account.profile.owner',
        prop: 'profileOwner',
        width: 300,
        id: 'profileOwner'
    },
    {
        headerKey: 'account:account.profile.created',
        prop: 'profileCreationDate',
        id: 'profileCreationDate',
        type: 'datetime',
        transform: profile => {
            if (profile.profileCreationDate) {
                return dateTimeUtil.toDisplayDateTime(profile.profileCreationDate);
            }
            return null;
        }
    },
    {
        headerKey: 'account:account.profile.status',
        prop: 'currentStatus',
        id: 'currentStatus'
    }
];
export default config;