export default class FileUtil {

    download(data, filename, filetype) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename + filetype);
        document.body.appendChild(link);
        link.click();
    }
}