import React from 'react';
import {withTranslation} from 'react-i18next';

class RowDetails extends React.Component {

    returnRow(columnConfig, index, heading) {
        let value = this.props.dataObject[columnConfig.prop];
        if(columnConfig.transform) {
           value = columnConfig.transform(value);
        }
        if (typeof value === 'boolean') {
            value = value ? this.props.t('common.yes') : this.props.t('common.no');
        }
        return (
            <div key={index} className="key__value--row">
                <label>{heading}</label>
                <label className="value">{value}</label>
            </div>
        );
    }

    returnTableData() {
        return this.props.columnConfig.map((columnConfig, index) => {
            let heading = this.props.t(columnConfig.headerKey);
            return this.returnRow(columnConfig,index, heading);
        });
    }

    render() {
        return (
            <div className="key__value--list">
                {this.returnTableData()}
            </div>
        );
    }

}

export default withTranslation()(RowDetails);