import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {ActiveUsers} from './active-users';
import {ArchivedUsers} from './archived-users';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';

let Users = (props) => {
    const {
        t, headerUIStore, userStore
    } = props;

    useEffect(() => {
        headerUIStore.setTitleKey('user:user.users');
    }, [headerUIStore]);

    return (
        <Tabs className="content sticky-scroll">
            <TabList>
                <Tab disabled={userStore.isLoading}>{t('user.users')}</Tab>
                <Tab disabled={userStore.isLoading}>{t('user.archived.users')}</Tab>
            </TabList>
            <TabPanel>
                <ActiveUsers/>
            </TabPanel>
            <TabPanel>
                <ArchivedUsers/>
            </TabPanel>
        </Tabs>
    );
};

export default inject(
    'headerUIStore',
    'userStore'
)(withTranslation(
    'user'
)(observer(Users)));