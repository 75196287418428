import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {Button} from 'semantic-ui-react';

let Auth0TenantsTableButtons = (props) => {
    const {t, auth0TenantStore, auth0TenantUIStore} = props;

    if (!auth0TenantUIStore.selectedAuth0Tenant) {
        return null;
    }

    function handleDelete() {
        auth0TenantStore.delete(auth0TenantUIStore.selectedAuth0Tenant.id)
            .then(() => auth0TenantStore.load());
    }

    return (
        <div>
            <Button onClick={handleDelete}>
                {t('common:common.delete')}
            </Button>
        </div>
    );
};

export default inject(
    'auth0TenantStore',
    'auth0TenantUIStore'
)(withTranslation(
    'user'
)(observer(Auth0TenantsTableButtons)));