import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../../../store/domain/base.store';

class AdminUsersUIStore extends BaseStore {
    isAddModalOpen = false;
    selectedAdminUsers = [];
    isConfirmArchiveModalOpen = false;

    constructor() {
        super();
        makeObservable(this, {
            isAddModalOpen: observable,
            selectedAdminUsers: observable,
            isConfirmArchiveModalOpen: observable,
            setSelectedAdminUsers: action,
            openConfirmArchiveModal: action,
            closeConfirmArchiveModal: action,
            openAddModal: action,
            closeAddModal: action
        });
        super.createInitialState();
    }

    openAddModal = () => {
        this.isAddModalOpen = true;
    };

    closeAddModal = () => {
        this.isAddModalOpen = false;
    };

    openConfirmArchiveModal = () => {
        this.isConfirmArchiveModalOpen = true;
    };

    closeConfirmArchiveModal = () => {
        this.isConfirmArchiveModalOpen = false;
    };

    setSelectedAdminUsers(selectedAdminUsers) {
        this.selectedAdminUsers = selectedAdminUsers;
    }

}

const adminUsersUIStore = new AdminUsersUIStore()
export default adminUsersUIStore;