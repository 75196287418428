import {action, computed, makeObservable, observable} from 'mobx';
import BaseStore from '../../../../store/domain/base.store';
import moment from 'moment';

class AccountBillingRunsUIStore extends BaseStore {

    dateRange;
    selectedAccountBillingRuns;
    isNewBillingRunModalOpen = false;
    isBillingRunDetailsModalOpen = false;

    constructor() {
        super();
        this.setSelectedAccountBillingRuns([]);
        this.initialiseDateRange();

        makeObservable(this, {
            dateRange: observable,
            setDateRange: action,
            initialiseDateRange: action,
            selectedAccountBillingRuns: observable,
            isNewBillingRunModalOpen: observable,
            isBillingRunDetailsModalOpen: observable,
            setSelectedAccountBillingRuns: action,
            openNewBillingRun: action,
            closeNewBillingRun: action,
            openBillingRunDetails: action,
            closeBillingRunDetails: action,
            singleSelectedAccountBillingRun: computed
        });

        super.createInitialState();
    }

    setDateRange = dateRange => {
        this.dateRange = dateRange;
    };

    initialiseDateRange() {
        this.dateRange = {
            endDate: moment().utc(false),
            startDate: moment().utc(false).subtract(1, 'month')
        };
    };

    setSelectedAccountBillingRuns = selectedAccountBillingRuns => {
        this.selectedAccountBillingRuns = selectedAccountBillingRuns;
    };

    openNewBillingRun = () => {
        this.isNewBillingRunModalOpen = true;
    };

    closeNewBillingRun = () => {
        this.isNewBillingRunModalOpen = false;
    };

    openBillingRunDetails = () => {
        this.isBillingRunDetailsModalOpen = true;
    };

    closeBillingRunDetails = () => {
        this.isBillingRunDetailsModalOpen = false;
    };

    get singleSelectedAccountBillingRun() {
        return this.selectedAccountBillingRuns
            && this.selectedAccountBillingRuns.length === 1
            && this.selectedAccountBillingRuns[0];
    }
}

const accountBillingRunsUIStore = new AccountBillingRunsUIStore();
export default accountBillingRunsUIStore;