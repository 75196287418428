import {dateTimeUtil} from '../../../utils';

const config = [
    {
        headerKey: 'common:common.name',
        prop: 'name'
    },
    {
        headerKey: 'auth0Tenant:auth0Tenant.domain',
        prop: 'domain'
    },
    {
        headerKey: 'auth0Tenant:auth0Tenant.client.id',
        prop: 'clientId'
    },
    {
        headerKey: 'common:common.date.modified',
        prop: 'lastModifiedDate',
        type: 'datetime',
        transform: auth0Tenant => {
            if (auth0Tenant.lastModifiedDate) {
                return dateTimeUtil.toDisplayDateTime(auth0Tenant.lastModifiedDate);
            }
            return null;
        }
    },
    {
        headerKey: 'common:common.last.modified.by',
        prop: 'lastModifiedBy'
    }
];
export default config;