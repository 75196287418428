import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';

import {dateTimeUtil, numberUtil, stringUtil} from '../../utils';
import Loader from '../loader';
import StickyWrapper from '../wrappers/StickyWrapper';

const CheckboxTable = checkboxHOC(ReactTable);
const TableLoader = props => props.isLoading ? <Loader/> : null;

class BaseTableSelect extends React.Component {

    static propTypes = {
        t: PropTypes.func.isRequired,
        rowId: PropTypes.string.isRequired,
        columnConfig: PropTypes.array.isRequired,
        pageSize: PropTypes.number,
        stickyTopOffset: PropTypes.number,
        stickyTopBarOffset: PropTypes.number,
        disableFilter: PropTypes.bool,
        filterInHeader: PropTypes.bool,
        multiSelect: PropTypes.bool,
        selectable: PropTypes.bool,
        isSticky: PropTypes.bool,
        dataName: PropTypes.string,
        defaultColumnSorted: PropTypes.string,
        handleRowDoubleClick: PropTypes.func,
        handleRowsSelection: PropTypes.func,
        noDataText: PropTypes.element,
        tableButtons: PropTypes.element,
        additionalFilteringComponents: PropTypes.element
    };

    constructor(props) {
        super(props);

        this.mapColumns(props);
        this.state = {
            selection: [],
            selectAll: false,
            textFilter: ''
        };
    }

    componentWillUnmount() {

        const scrollEl = document.querySelector('body.ReactModal__Body--open')
            ? document.querySelector('.modal__overlay .modal__overlay--content')
            : document.querySelector('.sticky-scroll');

        if (scrollEl) {
            this.handlePaginationShadow();
            scrollEl.removeEventListener('scroll', this.handlePaginationShadow);
            window.removeEventListener('resize', this.handlePaginationShadow);
        }
    }

    onComponentUpdate(prevProps, prevState, isLoading) {

        const scrollEl = document.querySelector('body.dimmable')
            ? document.querySelector('.modal__overlay .modal__overlay--content')
            : document.querySelector('.sticky-scroll');

        if (scrollEl) {
            this.handlePaginationShadow();
            ['scroll'].forEach(event => scrollEl.addEventListener(event, this.handlePaginationShadow, false));
            ['resize', 'load'].forEach(event => window.addEventListener(event, this.handlePaginationShadow, false));
        }

        if (!isLoading) {
            document.querySelector('.rt-thead .rt-tr').setAttribute('name', 'tableElement');
            document.querySelector('.rt-tbody').setAttribute('name', 'tableElement');
        }
    }

    updateSelectedRow(prevState, currentData) {
        if (prevState.selection.length === 1) {
            const selectedRow = prevState.selection[0];
            const updatedRow = currentData.find(row => this.getRowId(row) === this.getRowId(selectedRow));

            if (updatedRow && !_.isEqual(updatedRow, selectedRow)) {
                this.setState({
                    selection: [updatedRow]
                }, () => {
                    this.props.handleRowsSelection && this.props.handleRowsSelection(this.state.selection);
                });
            }
        }
    }

    removeDeletedRow(prevState, prevData, currentData) {
        if (currentData && prevData && currentData.length !== prevData.length) {
            const currentRowIds = currentData.map(row => this.getRowId(row));

            this.setState(() => {
                    _.remove(prevState.selection, row => currentRowIds.indexOf(this.getRowId(row)) < 0);
                    return {
                        selection: prevState.selection
                    };
                }, () => {
                    this.props.handleRowsSelection && this.props.handleRowsSelection(this.state.selection);
                }
            );
        }
    }

    getPageSize() {
        return this.props.pageSize || 50;
    }

    getRowId = (row) => {
        return row[this.props.rowId];
    };

    mapColumns({columnConfig, t}) {
        this.columns = columnConfig
            .filter(column => stringUtil.isNotBlank(column.prop))
            .map(column => {
                let header = (column.headerKey === undefined) ? column.heading : t(column.headerKey);
                const config = {
                    Header: header,
                    accessor: column.prop,
                    width: column.width,
                    className: column.className,
                    sortMethod: column.sortMethod,
                    type: column.type
                };

                if (column.transform) {
                    config.Cell = ({row}) => column.transform(row);
                }

                if (column.type === 'number') {
                    config.sortMethod = (a, b) => numberUtil.sortNumbersDesc(a, b);
                } else if (column.type === 'datetime') {
                    config.sortMethod = (d1, d2) => dateTimeUtil.compareDisplayDateTime(d1, d2);
                }

                return config;
            });
    };

    selectRow = (row, event, checkboxClick) => {
        if (event) {
            event.persist();
        }

        this.setState(
            (prevState, props) => {
                let selection = props.multiSelect ? prevState.selection : [];
                const selectedRowIds = prevState.selection.map(aRow => this.getRowId(aRow));
                checkboxClick = !event;
                let reactTableInstance = this.checkboxSelectTableReference.getWrappedInstance();
                let currentRows = reactTableInstance.getResolvedState().sortedData;

                if (event) {

                    if (event.shiftKey) {
                        event.stopPropagation();

                        if (this.isSelected(this.getRowId(row))) {
                            selection = [];
                            prevState.selectAll = false;
                            selection.push(row);
                        } else {
                            if (selection.length > 0) {


                                const firstSelectedRow = currentRows.find(row => this.isSelected(row._original.id));
                                const firstSelectedIndex = currentRows.indexOf(firstSelectedRow);
                                const lastSelectedRowId = row.id;
                                const lastSelectedRow = currentRows.find(row => row._original.id === lastSelectedRowId);
                                const lastSelectedRowIndex = currentRows.indexOf(lastSelectedRow);

                                let rowsToSelect = lastSelectedRowIndex > firstSelectedIndex
                                    ? currentRows.slice(firstSelectedIndex, lastSelectedRowIndex + 1)
                                    : currentRows.slice(lastSelectedRowIndex, firstSelectedIndex + 1);

                                selection = [];
                                prevState.selectAll = false;
                                this.select(rowsToSelect, selection);

                            } else {
                                selection.push(row);
                            }
                        }

                    } else if ((event.ctrlKey || event.metaKey)) {
                        if (selectedRowIds.indexOf(this.getRowId(row)) && !this.isSelected(row.id)) {
                            selection.push(row);

                        } else if (selectedRowIds.includes(this.getRowId(row)) && this.isSelected(row.id)) {
                            const index = selectedRowIds.indexOf(this.getRowId(row));
                            prevState.selectAll = false;
                            selection.splice(index, 1);
                        }

                    } else if (selectedRowIds.indexOf(this.getRowId(row))) {
                        selection = [];
                        prevState.selectAll = false;
                        selection.push(row);

                    } else if (selectedRowIds.indexOf(this.getRowId(row)) === 0) {
                        const index = selectedRowIds.indexOf(this.getRowId(row));
                        selection.splice(index, 1);
                    }

                } else if (checkboxClick && selectedRowIds.indexOf(this.getRowId(row))) {
                    selection.push(row);
                }
                return {
                    selection: selection
                };
            }, () => {
                return this.props.handleRowsSelection && this.props.handleRowsSelection(this.state.selection);
            });
    };

    select(rows, arr) {
        rows.forEach(row => arr.push(row._original));
    };

    isSelected = (id) => {
        return this.state.selection
            .filter(row => this.getRowId(row) === id)
            .length > 0;
    };

    toggleSelection = (weirdId, shift, row) => {

        const originalId = _.trimStart(weirdId, 'select-');
        if (!this.isSelected(this.getRowId(row))) {
            this.selectRow(row);
        } else {
            this.setState(
                prevState => {
                    _.remove(prevState.selection, row => this.getRowId(row) === originalId);
                    return {
                        selection: prevState.selection
                    };
                }, () => {
                    return this.props.handleRowsSelection && this.props.handleRowsSelection(this.state.selection);
                }
            );
        }
    };

    toggleAll = () => {

        const selectAll = !this.state.selectAll;
        let selection = [];

        if (selectAll) {
            let reactTableInstance = this.checkboxSelectTableReference.getWrappedInstance();
            let currentRows = reactTableInstance.getResolvedState().sortedData;

            selection = currentRows.map(row => row._original);
        }

        this.setState({
            selectAll,
            selection
        }, () => this.props.handleRowsSelection && this.props.handleRowsSelection(this.state.selection));
    };

    openSelectedRow = row => {
        this.props.handleRowDoubleClick && this.props.handleRowDoubleClick(row);
    };

    getTableRowProperties = (state, rowInfo) => {

        if (!rowInfo) {
            return {};
        }

        const row = rowInfo.original;
        if(row[this.props.propertyToDisableRow]) {
            return {
                className: classNames({
                    'disabled': this.props.propertyToDisableRow
                })
            }
        }
        return {
            onClick: (event, checkboxClick) => {
                this.selectRow(row, event, checkboxClick);
            },
            onDoubleClick: () => {
                this.selectRow(row, undefined, true);
                this.openSelectedRow(row);
            },
            className: classNames({
                'selected': this.isSelected(this.getRowId(row))
            })
        };
    };

    getTableHeadProperties = (state) => {
        return {
            style: {
                position: this.props.isSticky ? 'sticky' : 'relative',
                top: this.props.isSticky ? this.props.stickyTopOffset : 0
            },
            className: classNames({
                'selected': state.data.every(row => (this.isSelected(this.getRowId(row))))
            })
        };
    };

    handlePaginationShadow = () => {

        const paginationFooter = document.querySelector('body.ReactModal__Body--open')
            ? document.querySelector('.modal__overlay .pagination-bottom')
            : document.querySelector('.pagination-bottom');

        const scrollEl = document.querySelector('body.ReactModal__Body--open')
            ? document.querySelector('.modal__overlay')
            : document.querySelector('.sticky-scroll');

        if (paginationFooter) {
            const scrollTop = scrollEl.scrollTop;
            if (scrollTop >= (scrollEl.scrollHeight - scrollEl.clientHeight) || scrollEl.scrollHeight === scrollEl.clientHeight) {
                paginationFooter.classList.add('un-stuck');
            } else {
                paginationFooter.classList.remove('un-stuck');
            }
        }
    };

    onTextFilterChange(event, callback) {
        this.setState({
            textFilter: event.target.value
        }, callback);

        this.handlePaginationShadow();
    };

    getDefaultColumnSorted() {

        if (this.props.defaultColumnSorted) {
            return [
                {
                    id: this.props.defaultColumnSorted
                }
            ];
        }
    };

    getNoDataComponent(isLoading) {
        return !isLoading
            ? this.props.noDataText || <div className="rt-noData">{this.props.t('common.no.results.found')}</div>
            : null;
    };

    getReactTableProperties(data, isLoading, showPaginationBar) {

        const {
            rowId,
            selectable,
            multiSelect
        } = this.props;

        let tableProperties = {
            selectAll: this.state.selectAll,
            isSelected: this.isSelected,
            toggleSelection: this.toggleSelection,
            toggleAll: this.toggleAll,
            getTrProps: this.getTableRowProperties,
            selectType: 'checkbox',
            keyField: rowId,
            data: isLoading ? [] : data,
            columns: this.columns,
            defaultSorted: this.getDefaultColumnSorted(),
            className: '-highlight' + (selectable !== false ? ' -selectable' : '') + (multiSelect ? ' -multi' : ''),
            showPageSizeOptions: false,
            defaultPageSize: this.getPageSize(),
            minRows: 0,
            loading: isLoading,
            getTheadProps: this.getTableHeadProperties,
            showPaginationBottom: showPaginationBar,
            getTbodyProps: () => ({className: 'syncscroll'}),
            getTheadTrProps: () => ({className: 'syncscroll'}),
            NoDataComponent: () => this.getNoDataComponent(isLoading),
            LoadingComponent: () => <TableLoader isLoading={isLoading}/>,
            ref: reference => (this.checkboxSelectTableReference = reference)
        };

        if (!multiSelect) {
            Object.assign(tableProperties, {
                SelectAllInputComponent: () => <div></div>,
                SelectInputComponent: () => <div></div>
            });
        }

        return tableProperties;
    };

    getAdditionalComponents = () => {

        const {
            tableButtons: buttons,
            additionalFilteringComponents
        } = this.props;

        const additionalComponents =
            Array.isArray(additionalFilteringComponents)
                ? additionalFilteringComponents
                : [additionalFilteringComponents];

        const filters = additionalComponents.map((additionalComponent, index) => {
            return <div key={index}>{additionalComponent}</div>;
        });

        return {
            buttons,
            filters
        };
    };

    renderCustomisedComponent(reactTableProperties, totalElements, onTextFilterChange) {
        const {filters, buttons} = this.getAdditionalComponents();

        const {
            t,
            dataName,
            multiSelect,
            disableFilter,
            stickyTopBarOffset,
            additionalFilteringComponents,
            filterInHeader
        } = this.props;

        return (
            <>
                {
                    filters
                    && !disableFilter
                    && additionalFilteringComponents && <h2 className="ml-4 mt-4">{t('common.filters')}</h2>
                }
                {
                    (multiSelect || filters)
                    && (!disableFilter && !filterInHeader)
                    && <div className={`section__full--width--padding ${filters ? 'mt-2' : 'mt-3'}`}>
                        {
                            filters
                            && <label className="capitalize" htmlFor="filter">{t('common.filter')}</label>
                        }
                        <input
                            type="text"
                            id="text-filter"
                            placeholder={filters ? '' : t('common.filter')}
                            style={{maxWidth: 45 + 'rem'}}
                            onChange={onTextFilterChange}
                            value={this.state.textFilter}/>
                        {
                            filters
                        }
                    </div>
                }
                <StickyWrapper
                    offsetTop={0 || stickyTopBarOffset}
                    className="section__full--width--padding section__inline--elements border-top mt-4">
                    {
                        multiSelect
                            ? <h3>{this.state.selection.length} {t('common.select')}</h3>
                            : <h3>{`${totalElements} ${dataName ? dataName.toLowerCase() : undefined}`}</h3>
                    }
                    {
                        buttons !== undefined && !reactTableProperties.loading &&
                        <div className="button-box ml-3">{buttons}</div>
                    }
                    {
                        multiSelect
                            ? <h3 className="pull-right">{`${totalElements} ${dataName}`}</h3>
                            : filterInHeader
                            && <div className="pull-right">
                                <input type="text"
                                       id="text-filter"
                                       placeholder={t('common.filter')}
                                       style={{maxWidth: 45 + 'rem'}}
                                       onChange={onTextFilterChange}
                                       value={this.state.textFilter}
                                />
                            </div>
                    }
                </StickyWrapper>
                <div className="table__wrapper">
                    <CheckboxTable {...reactTableProperties} />
                </div>
            </>
        );
    }
}

export default BaseTableSelect;