import React from 'react';
import {useAuth0} from '@auth0/auth0-react';

const AuthenticatedRoute = ({ children }) => {
    const {isAuthenticated, isLoading, loginWithRedirect} = useAuth0();

    if (isLoading) {
        return <div></div>;
    }

    if (isAuthenticated) {
        return children;
    } else {
        loginWithRedirect();
        return <div></div>;
    }
};

export default AuthenticatedRoute;