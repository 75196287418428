import {dateTimeUtil} from '../../../../../utils';

const config = [
    {
        headerKey: 'account:account.zuora.account.number',
        prop: 'accountNumber'
    },
    {
        headerKey: 'account:account.zuora.account.id',
        prop: 'zuoraAccountId'
    },
    {
        headerKey: 'account:account.data.usage.charge.number',
        prop: 'dataUsageChargeNumber'
    },
    {
        headerKey: 'account:account.individual.profile.usage.charge.number',
        prop: 'individualProfileUsageChargeNumber'
    },
    {
        headerKey: 'account:account.organisation.profile.usage.charge.number',
        prop: 'organisationProfileUsageChargeNumber'
    },
    {
        headerKey: 'account:account.zuora.last.refresh',
        prop: 'lastRefreshDateTime',
        transform: lastRefreshDateTime => lastRefreshDateTime && dateTimeUtil.toDisplayDateTime(lastRefreshDateTime)
    }
];
export default config;