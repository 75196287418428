import * as Yup from 'yup';
import {getI18n} from 'react-i18next';

const t = (msg, values) => getI18n().t(msg, values);

function customRequiredValidator() {

    Yup.addMethod(Yup.string, 'requiredIf', function (requiredIf, conditionalMessage) {
        return this.test('requiredIfTest', 'This field is required', function (value) {
            const {path, createError} = this;

            if (!requiredIf) {
                return true;
            }

            return !!value ? true : createError({
                path,
                message: t('formValidation:form.validation.field.is.required.when', {message: t(conditionalMessage).toLowerCase()})
            });
        });
    });
}
export default customRequiredValidator;
