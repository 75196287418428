import React from 'react';
import {EncForm, EncInputField, withEncFormHandler} from '../../../components/hocs/form-validation/index';

let AddProductBundle = (props) => {
    const {formProperties, fieldProperties} = props;

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="name"
                label="productBundles:product.bundle.bundle.name"
                validator={{
                    type: 'string',
                    rules: ['required', 'alphanumericWithSpaceAndAmpersand']
                }}
            />
            <EncInputField
                id="productCode"
                label="productBundles:product.bundle.bundle.code"
                validator={{
                    type: 'string',
                    rules: ['required', 'alphanumericWithSpace']
                }}
            />
            <EncInputField
                id="description"
                label="productBundles:product.bundle.bundle.description"
                validator={{
                    type: 'string',
                    rules: ['required', 'alphanumericWithSpace']
                }}
            />
        </EncForm>
    );
};
const FORM_VALIDATION_OPTIONS = {
    header: 'productBundles:product.bundle.add.product.bundle',
    successMessage: 'productBundles:product.bundle.data.product.added',
    validateOnChange: false,
    validateOnBlur: true
};

export default withEncFormHandler(FORM_VALIDATION_OPTIONS)(AddProductBundle);


