const config = [
    {
        headerKey: 'common.account.name',
        prop: 'accountName'
    },
    {
        headerKey: 'common.account.number',
        prop: 'accountId'
    },
    {
        headerKey: 'common.description',
        prop: 'description'
    },
    {
        headerKey: 'common.quantity',
        prop: 'quantity'
    },
    {
        headerKey: 'account:account.zuora.subscription.number',
        prop: 'zuoraSubscriptionNumber'
    },
    {
        headerKey: 'account:account.charge.number',
        prop: 'zuoraChargeNumber'
    }
];
export default config;