import {countries} from 'country-data';

export default class CountryCodeUtil {

    constructor() {
        this.billingCountryCodeOptions = loadCountryCodeOptions();
    }

    getBillingCountryCodeOptions() {
        return this.billingCountryCodeOptions;
    }
}

function loadCountryCodeOptions() {
    return countries.all
        .filter(country => country.status === 'assigned')
        .map(country => {
            return {
                key: country.alpha2,
                text: country.name,
                value: country.alpha2
            };
        })
        .sort((c1, c2) => c1.text.localeCompare(c2.text));
}