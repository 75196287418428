import React from 'react';

const SettingsIcon = () => {
    return (
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path
                d="M23.24 13.67L22 12.84a1 1 0 0 1 0-1.68l1.29-.83a1 1 0 0 0 .44-1.05 11.8 11.8 0 0 0-1.5-3.62 1 1 0 0 0-.83-.45h-.22l-1.5.32h-.21a1 1 0 0 1-1-1.21l.32-1.5a1 1 0 0 0-.43-1 11.8 11.8 0 0 0-3.64-1.5h-.21a1 1 0 0 0-.84.46l-.83 1.29a1 1 0 0 1-1.68 0L10.33.76A1 1 0 0 0 9.49.3h-.21a11.8 11.8 0 0 0-3.62 1.5 1 1 0 0 0-.43 1.05l.32 1.5a1 1 0 0 1-1 1.21.76.76 0 0 1-.21 0l-1.5-.32h-.22a1 1 0 0 0-.83.45A11.8 11.8 0 0 0 .32 9.28a1 1 0 0 0 .44 1.05l1.29.83a1 1 0 0 1 0 1.68l-1.29.83a1 1 0 0 0-.44 1.05 11.8 11.8 0 0 0 1.5 3.62 1 1 0 0 0 .83.45h.22l1.5-.32a.76.76 0 0 1 .21 0 1 1 0 0 1 1 1.21l-.32 1.5a1 1 0 0 0 .43 1.05 11.8 11.8 0 0 0 3.62 1.5h.21a1 1 0 0 0 .84-.46l.8-1.27a1 1 0 0 1 1.68 0l.83 1.29a1 1 0 0 0 .84.46h.21a11.8 11.8 0 0 0 3.62-1.5 1 1 0 0 0 .43-1.05l-.32-1.5a1 1 0 0 1 1-1.21.76.76 0 0 1 .21 0l1.5.32h.22a1 1 0 0 0 .83-.45 11.8 11.8 0 0 0 1.5-3.62 1 1 0 0 0-.47-1.07zm-2.4 3l-.79-.17a3.38 3.38 0 0 0-.63-.07 3 3 0 0 0-2.93 3.63l.17.79a9.66 9.66 0 0 1-1.7.7l-.44-.67a3 3 0 0 0-5 0l-.52.66a9.66 9.66 0 0 1-1.7-.7l.17-.79a3 3 0 0 0-2.93-3.63 3.38 3.38 0 0 0-.63.07l-.79.17a9.66 9.66 0 0 1-.7-1.7l.67-.44a3 3 0 0 0 0-5L2.46 9a9.66 9.66 0 0 1 .7-1.7l.84.21a3.38 3.38 0 0 0 .63.07A3 3 0 0 0 7.51 4l-.17-.79A9.66 9.66 0 0 1 9 2.46l.44.67a3 3 0 0 0 5 0l.56-.67a9.66 9.66 0 0 1 1.7.7l-.21.84a3 3 0 0 0 2.93 3.63 3.38 3.38 0 0 0 .63-.07l.79-.17a9.66 9.66 0 0 1 .7 1.7l-.67.44a3 3 0 0 0 0 5l.67.44a9.66 9.66 0 0 1-.7 1.69z"/>
            <path d="M12 9a3 3 0 1 1-3 3 3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"/>
        </svg>
    )
};

export default SettingsIcon;