function customHandleRowSelectionHelper(setSelectedDataFunc, onDoubleClick) {

    return (selectedData) => {
        const isDoubleClick = !(selectedData instanceof Array);

        setSelectedDataFunc(isDoubleClick ? [selectedData] : selectedData);
        isDoubleClick && onDoubleClick && onDoubleClick(selectedData);
    };
}

export default customHandleRowSelectionHelper;
