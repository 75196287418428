import {observable, action, makeObservable} from 'mobx';

import BaseStore from '../../store/domain/base.store';

class EncDateRangePickerUIStore extends BaseStore {

    focusedInput = undefined;
    startDate = undefined;
    endDate = undefined;
    errorMessage = '';

    constructor() {
        super();

        makeObservable(this, {
            startDate: observable,
            endDate: observable,
            focusedInput: observable,
            errorMessage: observable,
            setStartDate: action,
            setEndDate: action,
            setFocusedInput: action,
            setErrorMessage: action
        });

        super.createInitialState();
    }

    setStartDate = startDate => {
        this.startDate = startDate;
    };

    setEndDate = endDate => {
        this.endDate = endDate;
    };

    setFocusedInput = focusedInput => {
        this.focusedInput = focusedInput;
    };

    setErrorMessage = errorMessage => {
        this.errorMessage = errorMessage;
    };
}

const encDateRangePickerUIStore = new EncDateRangePickerUIStore();
export default encDateRangePickerUIStore;
