import React from 'react';
import {Slide, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './icons.scss';
import {getI18n} from 'react-i18next';

const t = (msg, values) => getI18n().t(msg, values);

let notificationStatus = (content) => {
    return (
        <div className="status">
            <div className="status-icon"/>
            <div className="status-info">
                <div className="status-message">{t(content.statusMessage, content.statusValues)}</div>
                {content.label && <label>{t(content.label)}</label>}
            </div>
        </div>
    );
};

const defaultOptions = (className) => ({
    position: 'bottom-left',
    className: className,
    hideProgressBar: true,
    draggable: false,
    newestOnTop: true,
    closeOnClick: false,
    transition: Slide
});

export const toastSuccess = (content) => toast(notificationStatus(content), defaultOptions('success'));

export const toastError = (content) => toast(notificationStatus(content), defaultOptions('error'));

export const toastGeneral = (content) => toast(notificationStatus(content), defaultOptions('general'));