import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../../store/domain/base.store';
import moment from 'moment';

class TransactionsUIStore extends BaseStore {

    dateRange;

    constructor() {
        super();
        this.initialiseDateRange();
        makeObservable(this, {
            dateRange: observable,
            setDateRange: action,
            initialiseDateRange: action
        });
        super.createInitialState();
    }

    reset() {
        this.initialiseDateRange();
    }

    initialiseDateRange() {
        this.dateRange = {
            endDate: moment().utc(false),
            startDate: moment().utc(false).subtract(1, 'month')
        };
    };

    setDateRange = dateRange => {
        this.dateRange = dateRange;
    }
}

const transactionsUIStore = new TransactionsUIStore()
export default transactionsUIStore;