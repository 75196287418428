import * as Yup from 'yup';
import {getI18n} from 'react-i18next';

const t = (msg, values) => getI18n().t(msg, values);
const REPEATED_CHAR_PATTERN = /([a-zA-Z0-9])\\1/;
const SPECIAL_CHAR_PATTERN = /(?=[!@#$%^&*])/;
const ALPHABET_PATTERN = /(?=[a-zA-Z])/;
const NUMBER_PATTERN = /(?=[0-9])/;


function auth0PasswordValidator() {

    Yup.addMethod(Yup.string, 'auth0Password', function (policy, minLength, passwordRequired) {
        return this.test('auth0PasswordTest', 'Invalid password', function (password) {
            const {path, createError} = this;

            if (!password) {
                return passwordRequired ? createError({
                    path,
                    message: t('user:user.minimum.characters.in.length', {passwordMinLength: minLength})
                }) : true;
            }

            const passwordRequirements = getRemainingPasswordRequirements(password, policy, minLength);

            if (passwordRequirements.length > 0) {
                return createError({
                    path,
                    message: `${t('user:user.password.requirements')} ${passwordRequirements.join(' | ')}`
                });
            }

            return true;
        }).nullable();
    });
}
function getRemainingPasswordRequirements(password, policy, minLength) {

    let passwordRequirements = [];
    switch (policy) {
        case 'EXCELLENT':
            if (REPEATED_CHAR_PATTERN.test(password)) {
                passwordRequirements.push(t('user:user.no.more.two.identical.characters'));
            }
        //noinspection FallthroughInSwitchStatementJS
        case 'GOOD':
            if (!SPECIAL_CHAR_PATTERN.test(password)) {
                passwordRequirements.push(t('user:user.minimum.one.special.character'));
            }
        //noinspection FallthroughInSwitchStatementJS
        case 'FAIR':
            if (!ALPHABET_PATTERN.test(password) || !NUMBER_PATTERN.test(password)) {
                passwordRequirements.push(t('user:user.minimum.one.number.and.one.letter'));
            }
        //noinspection FallthroughInSwitchStatementJS
        case 'LOW':
            if (password.length < minLength) {
                passwordRequirements.push(t('user:user.minimum.characters.in.length', {passwordMinLength: minLength}));
            }
            break;
        default:
    }

    return passwordRequirements;

}
export default auth0PasswordValidator;
