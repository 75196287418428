import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../../../store/domain/base.store';

class Auth0TenantsUIStore extends BaseStore {
    selectedAuth0Tenant;
    isAddAuth0TenantModalOpen = false;

    constructor() {
        super();
        this.setSelectedAuth0Tenant(undefined);
        this.closeAddAuth0TenantModal();
        makeObservable(this, {
            selectedAuth0Tenant: observable,
            isAddAuth0TenantModalOpen: observable,
            setSelectedAuth0Tenant: action,
            openAddAuth0TenantModal: action,
            closeAddAuth0TenantModal: action
        });
        super.createInitialState();
    }

    setSelectedAuth0Tenant = (selectedAuth0Tenant) => {
        if (Array.isArray(selectedAuth0Tenant)) {
            selectedAuth0Tenant = selectedAuth0Tenant.length > 0 ? selectedAuth0Tenant[0] : undefined;
        }
        this.selectedAuth0Tenant = selectedAuth0Tenant;
    };

    openAddAuth0TenantModal = () => {
        this.isAddAuth0TenantModalOpen = true;
    };

    closeAddAuth0TenantModal = () => {
        this.isAddAuth0TenantModalOpen = false;
    };
}

const auth0TenantsUIStore = new Auth0TenantsUIStore()
export default auth0TenantsUIStore;