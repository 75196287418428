import React from 'react';
import {EncForm, EncInputField, withEncFormHandler} from '../../../components/hocs/form-validation/index';


let EditProductBundle = (props) => {
    const {formProperties, fieldProperties} = props;
    return (

        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="name"
                label={'productBundles:product.bundle.bundle.name'}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="productCode"
                label={'productBundles:product.bundle.bundle.code'}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="description"
                label={'productBundles:product.bundle.bundle.description'}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
        </EncForm>

    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'productBundles:product.bundle.edit.product.bundle',
    successMessage: 'productBundles:product.bundle.product.bundle.updated',
    validateOnChange: false,
    validateOnBlur: true,
    closeOnPristine: true
};

export default withEncFormHandler(FORM_VALIDATION_OPTIONS)(EditProductBundle);
