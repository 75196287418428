import React from 'react';
import RowDetails from '../../../../../components/row-details/index';
import {withTranslation} from 'react-i18next';
import {dateTimeUtil} from '../../../../../utils/index';

const DataProductDetails = (props) => {
    const {t, dataProduct, dataProductName} = props;

    const columnConfig = [
        {
            headerKey: 'productBundles:product.bundle.product.id',
            prop: 'id'
        },
        {
            headerKey: 'productBundles:product.bundle.product.code',
            prop: 'chargeCode'
        },
        {
            headerKey: 'productBundles:product.bundle.product.name',
            prop: 'name'
        },
        {
            headerKey: 'productBundles:product.bundle.currency',
            prop: 'currencyIsoCode'
        },
        {
            headerKey: 'productBundles:product.bundle.price',
            prop: 'unitPrice'
        },
        {
            headerKey: 'productBundles:product.bundle.tax.category',
            prop: 'productChargeCategory'
        },
        {
            headerKey: 'common.date.modified',
            prop: 'lastModifiedDate',
            transform: lastModifiedDate => lastModifiedDate && dateTimeUtil.toDisplayDateTime(lastModifiedDate)
        },
        {
            headerKey: 'common.last.modified.by',
            prop: 'lastModifiedById'
        }
    ];

    return (
        <div className="single__column">
            <h1 className="text-center">{dataProductName}</h1>
            <h2>{t('common:common.details')}</h2>
            <RowDetails dataObject={dataProduct} columnConfig={columnConfig}/>
        </div>
    );
};

export default withTranslation()(DataProductDetails);
