import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { autorun } from 'mobx';

import '../../../../styles/label.scss';
import './AccountOverview.scss';
import RowDetails from '../../../../components/row-details';
import Loader from '../../../../components/loader';
import EditAccount from '../../active-accounts/edit-account';
import { Button } from 'semantic-ui-react';
import EncModal from '../../../../components/enc-modal';
import ZuoraAccountDetails from './zuora-account-details';
import { MobXProviderContext } from 'mobx-react';
import rowDetailsConfig from './account-overview.row-details-config';

const AccountOverview = observer(() => {
    const { headerUIStore, accountStore, auth0TenantStore, subscriptionTypeStore, accountsUIStore } = useContext(MobXProviderContext);
    const { accountId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        accountStore.retrieveAccount(accountId);
        auth0TenantStore.load();
        subscriptionTypeStore.loadSubscriptionTypes();
        accountStore.retrieveAccountBillingDetails(accountId);

        const headerTitleListenerDisposer = autorun(() => {
            headerUIStore.setTitleKey(accountStore.account.name);
        });

        return () => {
            auth0TenantStore.reset();
            accountStore.reset();
            headerTitleListenerDisposer();
        };
    }, [accountId, accountStore, auth0TenantStore, headerUIStore, subscriptionTypeStore]);

    const onSuccessEditAccount = () => {
        accountStore.retrieveAccount(accountId);
        accountsUIStore.closeEditAccountModal();
    };

    const getORPCStatus = () => {
        if (accountStore.account.validORPC !== undefined && !accountStore.account.validORPC) {
            return <label className="error mb-1">Invalid Order Rate Plan Charge</label>;
        }
        return '';
    };

    if (accountStore.isLoading) {
        return <Loader />;
    }

    return (
        <div className="content sticky-scroll">
            <div className="section__full--width--padding">
                <div className="section__columns">
                    <div className="section__column grid-12">
                        <h2 className="mt-4">{t('account:account.overview')}</h2>
                        {getORPCStatus()}
                        {accountStore.account.archived !== true && accountStore.account.validORPC &&
                            <Button className="button__primary mt-2 mb-2"
                                    onClick={accountsUIStore.openEditAccountModal}>Edit</Button>
                        }
                        <RowDetails dataObject={accountStore.account} columnConfig={rowDetailsConfig}/>
                    </div>
                    <div className="section__column grid-12 mt-4">
                        <ZuoraAccountDetails
                            accountDetails={accountStore.accountBillingDetails || {}}
                            refreshAccountDetails={() => accountStore.retrieveAccountBillingDetails(accountId)}
                        />
                    </div>
                </div>
            </div>

            <EncModal
                isOpen={accountsUIStore.isEditAccountModalOpen}
                closeModal={accountsUIStore.closeEditAccountModal}>
                <EditAccount
                    closeFunc={accountsUIStore.closeEditAccountModal}
                    submitFunc={accountStore.updateAccount(accountId)}
                    onSuccess={onSuccessEditAccount}
                    auth0TenantOptions={auth0TenantStore.auth0TenantsOptions}
                    subscriptionTypeOptions={subscriptionTypeStore.subscriptionTypeOptions}
                    initialValues={{
                        name: accountStore.account.name,
                        auth0Id: accountStore.account.auth0Id,
                        billingCity: accountStore.account.billingCity,
                        billingCountryCode: accountStore.account.billingCountryCode,
                        subscriptionType: accountStore.account.subscriptionType,
                        taxable: accountStore.account.taxable,
                        status: accountStore.account.status,
                        auth0ExternalConnectionNames: accountStore.account.auth0ExternalConnectionNames
                    }}/>
            </EncModal>
        </div>
    );
});

export default AccountOverview;
