import React from 'react';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import MenuTrigger from '../MenuTrigger';
import {AboutIcon, AdminUsersIcon, Auth0TenantIcon} from '../../../styles/icons';

let SettingsMenu = props => {
    const {
        t,
        settingsUrl,
        menuTriggerOnClick
    } = props;

    return !settingsUrl ? null : (
        <div className="app__menu">
            <MenuTrigger key="settingsMenuTrigger" onClick={menuTriggerOnClick}/>

            <div className="menu__items">
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={settingsUrl + '/admin-users'}
                         >
                    <AdminUsersIcon/>
                    {t('menu.admin.users')}
                </NavLink>
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={settingsUrl + '/auth0-tenants'}
                         >
                    <Auth0TenantIcon/>
                    {t('menu.auth0.tenants')}
                </NavLink>
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={settingsUrl + '/about'}
                         >
                    <AboutIcon/>
                    {t('menu.about')}
                </NavLink>
            </div>
        </div>
    )
};

export default withTranslation(
    'menu'
)(SettingsMenu);