import {dateTimeUtil} from '../../utils';

const config = [
    {
        headerKey: 'productBundles:product.bundle.product.code',
        prop: 'productCode'
    },
    {
        headerKey: 'productBundles:product.bundle.bundle.name',
        prop: 'name',
        className: 'text-wrap'
    },
    {
        headerKey: 'productBundles:product.bundle.bundle.description',
        prop: 'description',
        className: 'text-wrap'
    },
    {
        headerKey: 'common:common.data.products',
        prop: 'numberOfDataProducts',
        width: 145,
        type: 'number'
    },
    {
        headerKey: 'common:common.feature.products',
        prop: 'numberOfFeatureProducts',
        width: 160,
        type: 'number'
    },
    {
        headerKey: 'common:common.date.modified',
        prop: 'lastModifiedDate',
        width: 200,
        type: 'datetime',
        transform: product => {
            if (product.lastModifiedDate) {
                return dateTimeUtil.toDisplayDateTime(product.lastModifiedDate);
            }
            return null;
        }
    },
    {
        headerKey: 'common:common.last.modified.by',
        prop: 'lastModifiedById'
    }
];
export default config;