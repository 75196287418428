import React from 'react';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import MenuTrigger from '../MenuTrigger';
import {OverviewIcon, ProductsIcon} from '../../../styles/icons/index';

let ProductBundleMenu = (props) => {
    let {t, productBundleUrl, menuTriggerOnClick} = props;

    return (
        <div className="app__menu">
        <MenuTrigger key="productBundleMenuTrigger" onClick={menuTriggerOnClick}/>

            <div className="menu__items">
                <NavLink className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'} to={productBundleUrl}  exact="true">
                    <OverviewIcon/>
                    {t('menu.product.bundle.overview')}
                </NavLink>
                <NavLink className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'} to={`${productBundleUrl}/data-products`}  exact="true">
                    <ProductsIcon/>
                    {t('menu.data.products')}
                </NavLink>
            </div>
        </div>
    );
};

export default withTranslation(
    'menu'
)(ProductBundleMenu);