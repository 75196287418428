import BaseStore from '../base.store';
import {action, makeObservable, observable} from 'mobx';
import {fileUtil} from '../../../utils';
import billingRunService from './billing-run.service';

class BillingRunStore extends BaseStore {
    isLoading = false;
    billingRuns;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setBillingRuns([]);

        makeObservable(this, {
            isLoading: observable,
            billingRuns: observable,
            setIsLoading: action,
            setBillingRuns: action,
            loadBillingRuns: action
        });

        super.createInitialState();
    }

    setIsLoading = isLoading => {
        this.isLoading = isLoading;
    };

    setBillingRuns = billingRuns => {
        this.billingRuns = billingRuns;
    };

    loadBillingRuns = (startDate, endDate) => {
        this.setIsLoading(true);

        let params = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        };

        return billingRunService.getBillingRuns(params)
            .then(({data}) => {
                this.setBillingRuns(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    generateCsv = (billingRunId, billingRunName, accountName, formattedStartDate, formattedEndDate) => {
        const fileName = billingRunName ? billingRunName : `Billing run ${accountName} ${formattedStartDate}-${formattedEndDate}`;

        return billingRunService.getBillingRunCsv(billingRunId)
            .then(({data}) => {
                fileUtil.download(data, fileName, '.csv');
            });
    };

    executeBillingRun = (billingRun) => {
        return billingRunService.executeBillingRun(billingRun);
    };
}

const billingRunStore = new BillingRunStore();
export default billingRunStore;