import React from 'react';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import MenuTrigger from '../MenuTrigger';
import UserMenu from './user-menu/index';
import {
    BillingRunsIcon,
    OverviewIcon,
    PolicyRunIcon,
    ProductsIcon,
    ProfilesIcon,
    TransactionsIcon,
    UsersIcon
} from '../../../styles/icons/index';

let AccountMenu = props => {

    const {
        t,
        accountUrl,
        menuTriggerOnClick
    } = props;

    return !accountUrl ? null : (
        <div className="app__menu">
            <MenuTrigger key="accountMenuTrigger" onClick={menuTriggerOnClick}/>

            <div className="menu__items">
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={accountUrl}
                         >
                    <OverviewIcon/>
                    {t('menu.account.overview')}
                </NavLink>
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={accountUrl + '/products'}
                         >
                    <ProductsIcon/>
                    {t('menu.account.products')}
                </NavLink>
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={accountUrl + '/users'}
                         >
                    <UsersIcon/>
                    {t('menu.account.users')}
                </NavLink>
                <UserMenu/>
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={accountUrl + '/transactions'}
                         >
                    <TransactionsIcon/>
                    {t('menu.account.transactions')}
                </NavLink>
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={accountUrl + '/profiles'}
                         >
                    <ProfilesIcon/>
                    {t('menu.account.profiles')}
                </NavLink>
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={accountUrl + '/policy-run-history'}
                         >
                    <PolicyRunIcon/>
                    {t('menu.account.policy.run.history')}
                </NavLink>
                <NavLink exact="true"
                         className={({isActive}) => isActive ? 'menu__item is-active' : 'menu__item'}
                         to={accountUrl + '/billing-runs'}
                         >
                    <BillingRunsIcon/>
                    {t('menu.account.billing.runs')}
                </NavLink>
            </div>
        </div>
    );
};

export default withTranslation(
    'menu'
)(AccountMenu);