import React from 'react';
import {Button} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from 'mobx-react';

import PageableTableSelect from '../../components/table-select/pageable/index';
import EncDateRangePicker from '../../components/date-range-picker/index';
import NoResultsMessage from '../../components/table-select/NoResultsMessage';
import columnConfig from './transactions.column-config';

class Transactions extends React.Component {

    constructor(props) {
        super(props);

        this.props.headerUIStore.setTitleKey('transactions:transactions.title');
    }

    componentWillUnmount() {
        this.props.transactionsUIStore.reset();
    }

    exportToCSV = () => {
        this.props.transactionStore.exportToCsv(
            this.props.transactionsUIStore.dateRange,
            this.tableSelectFilter
        );
    };

    handleTextFilterChange = filter => this.tableSelectFilter = filter;

    getPageableTransactions = (...params) => {
        this.props.transactionStore.getPageableTransactions(this.props.transactionsUIStore.dateRange)(...params);
    };

    render() {
        const {t} = this.props;

        return (
            <div className="content sticky-scroll">
                <div className="section__full--width--padding mt-4">
                    <Button className="button__primary"
                            onClick={this.exportToCSV}>
                        {t('common:common.export.to.csv')}
                    </Button>
                </div>

                <PageableTableSelect
                    multiSelect={false}
                    selectable={false}
                    columnConfig={columnConfig}
                    fetchPageableData={this.getPageableTransactions}
                    rowId="id"
                    isSticky={true}
                    stickyTopOffset={55}
                    stickyTopBarOffset={0}
                    dataName={t('transactions.data.name')}
                    noDataText={this.renderNoResultsMessage()}
                    defaultColumnSorted="endTransaction"
                    tableSelectRef={(tableSelectRef) => this.tableSelectRef = tableSelectRef}
                    handleTextFilterChange={this.handleTextFilterChange}
                    additionalFilteringComponents={this.renderAdditionalTableFilters()}/>
            </div>
        );
    }

    renderAdditionalTableFilters() {
        const {transactionsUIStore} = this.props;

        return (
            <EncDateRangePicker
                startDate={transactionsUIStore.dateRange.startDate}
                endDate={transactionsUIStore.dateRange.endDate}
                handleRange={dateRange => {
                    transactionsUIStore.setDateRange(dateRange);
                    this.tableSelectRef.refreshTable();
                }}/>
        );
    }

    renderNoResultsMessage() {
        const {transactionsUIStore} = this.props;

        return (
            <NoResultsMessage
                startDate={transactionsUIStore.dateRange.startDate}
                endDate={transactionsUIStore.dateRange.endDate}/>
        );
    }
}

export default inject(
    'transactionStore',
    'transactionsUIStore',
    'headerUIStore'
)(withTranslation([
    'transactions',
    'common'
])(observer(Transactions)));
