import {dateTimeUtil} from '../../../../utils';

const config = [
    {
        headerKey: 'account:account.product.code',
        prop: 'productCode',
        width: 150
    },
    {
        headerKey: 'account:account.product.name',
        prop: 'name',
        width: 150
    },
    {
        headerKey: 'account:account.product.description',
        prop: 'description'
    },
    {
        headerKey: 'account:account.product.type',
        prop: 'type',
        width: 150
    },
    {
        headerKey: 'account:account.date.assigned',
        prop: 'lastModifiedDate',
        width: 200,
        type: 'datetime',
        transform: accountProduct => {
            if (accountProduct.lastModifiedDate) {
                return dateTimeUtil.toDisplayDateTime(accountProduct.lastModifiedDate);
            }
            return null;
        }
    },
    {
        headerKey: 'account:account.last.assigned.by',
        prop: 'lastModifiedById'
    }
];
export default config;