import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import EncModal from '../../../../../components/enc-modal/index';
import AddAuth0Tenant from './add-auth0-tenant/AddAuth0Tenant';

let Auth0TenantsModel = (props) => {
    const {auth0TenantStore, auth0TenantUIStore} = props;

    function createAuth0TenantSuccess() {
        auth0TenantStore.load();
        auth0TenantUIStore.closeAddAuth0TenantModal();
    }

    return (
        <div>
            <EncModal isOpen={auth0TenantUIStore.isAddAuth0TenantModalOpen}
                      closeModal={auth0TenantUIStore.closeAddAuth0TenantModal}>
                <AddAuth0Tenant closeFunc={auth0TenantUIStore.closeAddAuth0TenantModal}
                                submitFunc={auth0TenantStore.create}
                                onSuccess={createAuth0TenantSuccess}/>
            </EncModal>
        </div>
    );
};

export default inject(
    'auth0TenantStore',
    'auth0TenantUIStore'
)(withTranslation(
    'auth0Tenant'
)(observer(Auth0TenantsModel)));