import React from 'react';
import {EncForm, EncSingleDatePicker, EncInputField, withEncFormHandler} from '../../../../../components/hocs/form-validation/index';
import moment from 'moment';

let ExecuteBillingRun = (props) => {
    const {formProperties, fieldProperties} = props;

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id='name'
                label="common:common.name"
            />
            <EncSingleDatePicker
                id='startDate'
                label='common:common.start.date'
                isOutsideRange={isStartDateOutsideRange}
                validator={{
                    type: 'date',
                    rules: ['required']
                }}
            />
            <EncSingleDatePicker
                id='endDate'
                label='common:common.end.date'
                isOutsideRange={isEndDateOutsideRange}
                validator={{
                    type: 'date',
                    rules: ['required']
                }}
            />
        </EncForm>
    );
};

function isStartDateOutsideRange(startDate, values) {
    return !values.endDate
        || startDate.startOf('day').isAfter(values.endDate.endOf('day'))
        || startDate.startOf('day').isAfter(moment().endOf('day'));
}

function isEndDateOutsideRange(endDate, values) {
    return !values.startDate
        || endDate.endOf('day').isBefore(values.startDate.startOf('day'))
        || endDate.startOf('day').isAfter(moment().endOf('day'));
}

const FORM_VALIDATION_OPTIONS = {
    header: 'account:account.execute.billing.run',
    successMessage: 'account:account.billing.run.success',
    submitLabel: 'common:common.execute',
    validateOnChange: false,
    validateOnBlur: true
};

export default withEncFormHandler(FORM_VALIDATION_OPTIONS)(ExecuteBillingRun);