import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../base.store';
import accountService from './account.service';
import {computedFn} from 'mobx-utils';

class AccountNameStore extends BaseStore {
    isLoading = false;
    accountNames;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setAccountNames([]);

        makeObservable(this, {
            isLoading: observable,
            accountNames: observable,
            setIsLoading: action,
            setAccountNames: action,
            loadAccountNames: action
        });

        super.createInitialState();
    }

    setIsLoading(loading) {
        this.isLoading = loading;
    }

    setAccountNames = (accountNames) => {
        this.accountNames = accountNames;
    };

    loadAccountNames = () => {
        this.setIsLoading(true);

        return accountService.getAccountNames()
            .then(({data}) => {
                this.setAccountNames(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    accountNameOptions = computedFn(function(accountId) {
        if (!(this.accountNames instanceof Array)) {
            return [];
        }

        return this.accountNames.filter(accountName => accountId ? accountName.id !== accountId : true)
            .map(accountName => {
                return {
                    key: accountName.id,
                    text: accountName.name,
                    value: accountName.id
                };
            }).sort((s1, s2) => s1.text.localeCompare(s2.text));
    });
}

const accountNameStore = new AccountNameStore()
export default accountNameStore;