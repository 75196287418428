import { action, computed, makeObservable, observable } from 'mobx';
import BaseStore from '../base.store';
import auth0Config from '../../../auth0-config';

class AuthenticationStore extends BaseStore {
    isAdminUIUser = false;
    isAuthenticated = false;
    getAccessTokenSilently;
    auth0Logout;

    constructor() {
        super();
        makeObservable(this, {
            isAdminUIUser: observable,
            isAuthenticated: observable,
            setIsAdminUIUser: action,
            setIsAuthenticated: action,
            setGetAccessTokenSilently: action,
            setAuth0Logout: action,
            logout: action,
            isSessionValid: computed,
            isSessionValidAndAdminUIUser: computed,
        });
        this.createInitialState();
    }

    setGetAccessTokenSilently(getAccessTokenSilently) {
        this.getAccessTokenSilently = getAccessTokenSilently;
    }

    setIsAdminUIUser(isAdminUIUser) {
        this.isAdminUIUser = isAdminUIUser;
    }

    setIsAuthenticated(isAuthenticated) {
        this.isAuthenticated = isAuthenticated;
    }

    setAuth0Logout(logout) {
        this.auth0Logout = logout;
    }

    logout() {
        this.reset();
        this.setIsAuthenticated(false);
        this.auth0Logout({
            client_id: auth0Config.clientId,
            logoutParams: {
                returnTo: window.location.origin
            }
        });
    }

    get isSessionValid() {
        return this.isAuthenticated;
    }

    get isSessionValidAndAdminUIUser() {
        return this.isAuthenticated && this.isAdminUIUser;
    }
}

const authenticationStore = new AuthenticationStore();
export default authenticationStore;
