import {dateTimeUtil} from '../../../../../../utils/index';

const config = [
    {
        headerKey: 'user:user.username',
        prop: 'partnerUserId',
        width: 400,
    },
    {
        headerKey: 'user:user.last.login',
        prop: 'lastLoginDateTime',
        width: 200,
        type: 'datetime',
        transform: accountUser => accountUser.lastLoginDateTime ? dateTimeUtil.toDisplayDateTime(accountUser.lastLoginDateTime) : null
    },
    {
        headerKey: 'user:user.type',
        prop: 'type',
        width: 100
    },
    {
        headerKey: 'common.date.modified',
        prop: 'lastModifiedDate',
        width: 200,
        sortMethod: (d1, d2) => dateTimeUtil.compareDisplayDateTime(d1, d2),
        transform: accountUser => accountUser.lastModifiedDate ? dateTimeUtil.toDisplayDateTime(accountUser.lastModifiedDate) : null
    },
    {
        headerKey: 'common.last.modified.by',
        prop: 'lastModifiedBy'
    },
    {
        headerKey: 'common.sub.account',
        prop: 'subAccountName'
    }
];
export default config;