import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import TableSelect from '../../../components/table-select';
import columnConfig from './auth0-tenants.column-config';
import {Auth0TenantsTableButtons} from './sub-components/table-buttons';
import {Button} from 'semantic-ui-react';
import {Auth0TenantsModals} from './sub-components/modals';

let Auth0Tenants = (props) => {
    const {
        t, auth0TenantStore, auth0TenantUIStore, headerUIStore
    } = props;

    useEffect(() => {
        headerUIStore.setTitleKey('auth0Tenant:auth0Tenant.auth0.tenants');
    }, [headerUIStore]);

    useEffect(() => {
        auth0TenantStore.load();
        return () => auth0TenantStore.reset();
    }, [auth0TenantStore]);

    function handleRowSelection(selectedRow) {
        auth0TenantUIStore.setSelectedAuth0Tenant(selectedRow);
    }

    return (
        <div className="content sticky-scroll">
            <Auth0TenantsModals/>

            <div className="section__full--width--padding mt-4">
                <Button className="button__primary" onClick={auth0TenantUIStore.openAddAuth0TenantModal}>
                    {t('common:common.new')}
                </Button>
            </div>

            <TableSelect
                multiSelect={false}
                columnConfig={columnConfig}
                rowData={auth0TenantStore.auth0Tenants}
                loading={auth0TenantStore.isLoading}
                handleRowsSelection={handleRowSelection}
                rowId="id"
                tableButtons={<Auth0TenantsTableButtons/>}
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('auth0Tenant:auth0Tenant.auth0.tenants')}
                defaultColumnSorted="name"
            />
        </div>
    );
};

export default inject(
    'auth0TenantStore',
    'auth0TenantUIStore',
    'headerUIStore'
)(withTranslation(
    'auth0Tenant'
)(observer(Auth0Tenants)));