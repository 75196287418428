import React, {useContext, useEffect} from 'react';
import {MobXProviderContext, observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Button} from 'semantic-ui-react';
import EncModal from '../../../../components/enc-modal/EncModal';
import ExecuteBillingRun from './execute-billing-run/index';
import TableSelect from '../../../../components/table-select/TableSelect';
import columnConfig from './account-billing-runs.column-config';
import AccountBillingRunDetails from './account-billing-run-details/index';
import {dateTimeUtil} from '../../../../utils';
import moment from 'moment';
import EncDateRangePicker from '../../../../components/date-range-picker/EncDateRangePicker';
import {useParams} from 'react-router-dom';
import {autorun} from "mobx";

const AccountBillingRuns = observer(() => {
    const { t } = useTranslation();
    const { accountBillingRunStore, accountBillingRunsUIStore, accountStore, headerUIStore } = useContext(MobXProviderContext);
    const { accountId } = useParams();

    useEffect(() => {

        const disposeAutorun = autorun(() => {
            const { startDate, endDate } = accountBillingRunsUIStore.dateRange;
            if (startDate || endDate) {
                accountBillingRunStore.loadAccountBillingRuns(
                    accountId,
                    accountBillingRunsUIStore.dateRange.startDate,
                    accountBillingRunsUIStore.dateRange.endDate
                );
            }
        });

        accountBillingRunStore.loadAccountBillingRuns(
            accountId,
            accountBillingRunsUIStore.dateRange.startDate,
            accountBillingRunsUIStore.dateRange.endDate
        );

        accountStore.retrieveAccount(accountId)
            .then(() => headerUIStore.setTitleKey(accountStore.account.name));

        return () => {
            accountBillingRunStore.reset();
            accountBillingRunsUIStore.reset();
            disposeAutorun();
        };
    }, [accountId, accountBillingRunStore, accountBillingRunsUIStore, accountStore, headerUIStore]);

    const onSuccessExecuteBillingRun = () => {
        accountBillingRunStore.loadAccountBillingRuns(
            accountId,
            accountBillingRunsUIStore.dateRange.startDate,
            accountBillingRunsUIStore.dateRange.endDate
        );
        accountBillingRunsUIStore.closeNewBillingRun();
    };

    const handleRowDoubleClick = (selectedAccountBillingRuns) => {
        accountBillingRunsUIStore.setSelectedAccountBillingRuns([selectedAccountBillingRuns]);
        accountBillingRunsUIStore.openBillingRunDetails();
    };

    const exportToCSV = () => {
        let selectedBillingRun = accountBillingRunsUIStore.singleSelectedAccountBillingRun;
        let accountName = accountStore.account.name;
        const formattedStartDate = dateTimeUtil.toDayMonthYearDate(selectedBillingRun.startDate);
        const formattedEndDate = dateTimeUtil.toDayMonthYearDate(selectedBillingRun.endDate);
        accountBillingRunStore.generateCsv(
            selectedBillingRun.id,
            selectedBillingRun.name,
            accountName,
            formattedStartDate,
            formattedEndDate
        );
    };

    const renderTableButtons = () => {
        let length = accountBillingRunsUIStore.selectedAccountBillingRuns.length;
        return (
            <div>
                {length === 1 && (
                    <Button onClick={accountBillingRunsUIStore.openBillingRunDetails}>
                        {t('common:common.details')}
                    </Button>
                )}
                {length === 1 && (
                    <Button onClick={exportToCSV}>
                        {t('common:common.export.to.csv')}
                    </Button>
                )}
            </div>
        );
    };

    const renderModals = () => {
        return (
            <div>
                <EncModal
                    isOpen={accountBillingRunsUIStore.isNewBillingRunModalOpen}
                    closeModal={accountBillingRunsUIStore.closeNewBillingRun}>
                    <ExecuteBillingRun
                        closeFunc={accountBillingRunsUIStore.closeNewBillingRun}
                        submitFunc={accountBillingRunStore.executeAccountBillingRun(accountStore.account.id)}
                        onSuccess={onSuccessExecuteBillingRun}
                        initialValues={{
                            startDate: moment().utc(false).subtract(1, 'month'),
                            endDate: moment().utc(false)
                        }}/>
                </EncModal>

                <EncModal
                    isOpen={accountBillingRunsUIStore.isBillingRunDetailsModalOpen}
                    closeModal={accountBillingRunsUIStore.closeBillingRunDetails}>
                    <AccountBillingRunDetails
                        accountName={accountStore.account.name}
                        billingRun={accountBillingRunsUIStore.singleSelectedAccountBillingRun}/>
                </EncModal>
            </div>
        );
    };

    const renderAdditionalTableFilters = () => {
        return (
            <EncDateRangePicker
                startDate={accountBillingRunsUIStore.dateRange.startDate}
                endDate={accountBillingRunsUIStore.dateRange.endDate}
                handleRange={dateRange => {
                    accountBillingRunsUIStore.setDateRange(dateRange);
                }}
            />
        );
    };

    return (
        <div className="content sticky-scroll">
            {renderModals()}
            <div className="section__full--width--padding mt-4">
                <Button className="button__primary" onClick={accountBillingRunsUIStore.openNewBillingRun}>
                    {t('common:common.new')}
                </Button>
            </div>

            <TableSelect
                multiSelect={true}
                columnConfig={columnConfig}
                rowData={accountBillingRunStore.accountBillingRuns}
                loading={accountBillingRunStore.isLoading}
                handleRowsSelection={accountBillingRunsUIStore.setSelectedAccountBillingRuns}
                handleRowDoubleClick={handleRowDoubleClick}
                dataName={t('account:account.billing.runs')}
                rowId="id"
                tableButtons={renderTableButtons()}
                isSticky={true}
                stickyTopBarOffset={0}
                stickyTopOffset={55}
                defaultColumnSorted="createdDate"
                additionalFilteringComponents={renderAdditionalTableFilters()}
            />
        </div>
    );
});

export default AccountBillingRuns;
