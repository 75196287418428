import React from 'react';
import {DateRangePicker} from 'react-dates';
import {withTranslation} from 'react-i18next';
import {inject, observer} from 'mobx-react';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import {dateTimeUtil} from '../../utils';

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const DATE_FORMAT = 'DD/MM/YYYY';

class EncDateRangePicker extends React.Component {

    constructor(props) {
        super(props);

        const {
            endDate,
            startDate,
            encDateRangePickerUIStore
        } = props;

        encDateRangePickerUIStore.setStartDate(startDate);
        encDateRangePickerUIStore.setEndDate(endDate);
    }

    onDateChange = ({startDate, endDate}) => {

        const {
            setStartDate,
            setEndDate,
            setErrorMessage
        } = this.props.encDateRangePickerUIStore;

        setStartDate(startDate);
        setEndDate(endDate);
        setErrorMessage('');

        if (startDate && endDate) {
            this.props.handleRange({
                startDate: moment(startDate).utc(false).startOf('day'),
                endDate: moment(endDate).utc(false).endOf('day')
            });
        }
    };

    validateDates = () => {

        const {
            endDate,
            startDate,
            focusedInput,
            setFocusedInput,
            setErrorMessage
        } = this.props.encDateRangePickerUIStore;

        setFocusedInput(null);

        if (focusedInput === START_DATE && !startDate) {
            setErrorMessage('datepicker.invalid.start.date');

        } else if (focusedInput === END_DATE && !endDate) {
            setErrorMessage('datepicker.invalid.end.date');
        }
    };

    onFocusChange = focusedInput => {
        if (focusedInput) {
            this.props.encDateRangePickerUIStore.setFocusedInput(focusedInput);
        }
    };

    isDatesOutsideRange = day => {

        const {
            startDate,
            focusedInput
        } = this.props.encDateRangePickerUIStore;

        if (focusedInput === START_DATE) {
            return dateTimeUtil.isAfterDay(day, moment());

        } else if (focusedInput === END_DATE) {

            const isEndDateBeforeStartDate = dateTimeUtil.isBeforeDay(day, startDate);
            const isEndDateAfterToday = dateTimeUtil.isAfterDay(day, moment());

            return isEndDateBeforeStartDate
                || isEndDateAfterToday
                || this.isEndDateAfterRangeLimit(day, startDate, this.props.rangeLimitInDays);
        }

        return false;
    };

    isEndDateAfterRangeLimit = (day, startDate, rangeLimitInDays) => {
        let isEndDateAfterDateLimit = false;
        if (startDate && rangeLimitInDays) {
            const rangeLimit = this.handleRangeLimit(startDate, rangeLimitInDays);
            isEndDateAfterDateLimit = dateTimeUtil.isAfterDay(day, rangeLimit);
        }
        return isEndDateAfterDateLimit;
    };

    handleRangeLimit = (startDate, rangeLimitInDays) => {

        if (rangeLimitInDays === 30 || rangeLimitInDays === 31) {
            return startDate.clone().add(1, 'month');
        } else {
            return startDate.clone().add(rangeLimitInDays, 'days');
        }
    };

    render() {

        const {
            t,
            encDateRangePickerUIStore
        } = this.props;

        return (
            <div className="field--date-picker">
                <label>{t('datepicker.date.range')}</label>
                <DateRangePicker
                    startDateId={START_DATE}
                    endDateId={END_DATE}
                    startDate={encDateRangePickerUIStore.startDate}
                    endDate={encDateRangePickerUIStore.endDate}
                    focusedInput={encDateRangePickerUIStore.focusedInput}
                    onDatesChange={this.onDateChange}
                    onFocusChange={this.onFocusChange}
                    showClearDates={true}
                    reopenPickerOnClearDates={true}
                    isOutsideRange={this.isDatesOutsideRange}
                    onClose={this.validateDates}
                    displayFormat={DATE_FORMAT}
                    startDatePlaceholderText={t('datepicker.start.date')}
                    endDatePlaceholderText={t('datepicker.end.date')}
                    hideKeyboardShortcutsPanel={true}
                    noBorder={true}
                    minimumNights={0}
                />
                <label className="validation-error attention">
                    {t(encDateRangePickerUIStore.errorMessage)}
                </label>
            </div>
        );
    }
}

export default inject(
    'encDateRangePickerUIStore'
)(withTranslation(
    'datepicker'
)(observer(EncDateRangePicker)));