import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import RowDetails from '../../../../components/row-details/index';
import EditProductBundle from '../../edit-product-bundle/index';
import { Button } from 'semantic-ui-react';
import EncModal from '../../../../components/enc-modal/index';
import rowConfig from './product-bundle-overview.row-details-config';
import { useParams } from 'react-router-dom';
import { MobXProviderContext } from 'mobx-react';

const ProductBundleOverview = observer(() => {
    const { productBundleStore, productBundlesUIStore, headerUIStore } = useContext(MobXProviderContext);
    const { productBundleId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        productBundleStore.loadProductBundle(productBundleId)
            .then(() => headerUIStore.setTitleKey(productBundleStore.productBundle.name));

        return () => {
            productBundleStore.reset();
            productBundlesUIStore.reset();
        };
    }, [productBundleId, productBundleStore, productBundlesUIStore, headerUIStore]);

    const onSuccessEditProductBundle = () => {
        productBundleStore.loadProductBundle(productBundleId);
        productBundlesUIStore.closeEditProductBundleModal();
    };

    const renderModals = () => (
        <EncModal
            isOpen={productBundlesUIStore.isEditProductBundleModalOpen}
            closeModal={productBundlesUIStore.closeEditProductBundleModal}>
            <EditProductBundle
                closeFunc={productBundlesUIStore.closeEditProductBundleModal}
                submitFunc={productBundleStore.updateProductBundle(productBundleStore.salesforceId)}
                initialValues={productBundleStore.formValues}
                onSuccess={onSuccessEditProductBundle}
            />
        </EncModal>
    );

    return (
        <div className="content sticky-scroll">
            {renderModals()}

            <div className="section__full--width--padding mt-4">
                <section className="section__columns">
                    <section className="section__column grid-12">
                        <h2>{t('productBundles:product.bundle.bundle.details')}</h2>
                        <Button className="button__primary my-2"
                                onClick={productBundlesUIStore.openEditProductBundleModal}>
                            {t('common:common.edit')}
                        </Button>
                        <RowDetails dataObject={productBundleStore.productBundle}
                                    columnConfig={rowConfig}/>
                    </section>
                </section>
            </div>
        </div>
    );
});

export default ProductBundleOverview;
