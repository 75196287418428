import React from 'react';
import {EncForm, EncInputField, withEncFormHandler} from '../../../../components/hocs/form-validation';

let AddAdminUser = (props) => {
    const {formProperties, fieldProperties} = props;

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="sourceUserId"
                label="admin:admin.source.user.id"
                validator={{
                    type: 'string',
                    rules: ['required', 'email']
                }}
            />
        </EncForm>

    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'admin:admin.admin.users',
    successMessage: 'admin:admin.successfully.added.to.admin.users',
    validateOnChange: false,
    validateOnBlur: true
};

export default withEncFormHandler(FORM_VALIDATION_OPTIONS)(AddAdminUser);


