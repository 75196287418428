import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const POLICY_RUN_HISTORY_ENDPOINT = '/api/v1/policy-run-history';

class PolicyRunHistoryService {

    getPageablePolicyRunHistory = (params) => {
        return axiosInterface.get(`${POLICY_RUN_HISTORY_ENDPOINT}`, {params})
            .then(response => {
                return response;
            }).catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading policy run history: ${error}`);
                throw error;
            });
    };

    getPolicyRunHistoryCSV = (params) => {
        return axiosInterface.get(`${POLICY_RUN_HISTORY_ENDPOINT}/csv`, {params})
            .then(response => {
                return response;
            });
    };
}

const policyRunHistoryService = new PolicyRunHistoryService()
export default policyRunHistoryService;