import BaseStore from '../base.store';
import {makeObservable} from 'mobx';
import {paginationUtil} from '../../../utils';
import userLoginHistoryService from './userloginhistory.service';

class UserLoginHistoryStore extends BaseStore {

    constructor() {
        super();

        makeObservable(this, {});

        this.createInitialState();
    }

    getPageableUserLoginHistory = ({startDate, endDate}, contactId = undefined) =>
        (currentPage, pageSize, sortingFields, filter, tableSelectCallback) => {

            const params = {
                contactId: contactId,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                page: currentPage,
                size: pageSize,
                filter: filter,
                sort: paginationUtil.createSingleSortingParameter(sortingFields)
            };

            return userLoginHistoryService.getPageableUserLoginHistory(params)
                .then(({data}) => {
                    tableSelectCallback(data);
                });
        };
}

const userLoginHistoryStore = new UserLoginHistoryStore()
export default userLoginHistoryStore;