import React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from 'mobx-react';

import '../../../styles/label.scss';
import RowDetails from '../../../components/row-details';
import Loader from '../../../components/loader';
import {dateTimeUtil} from '../../../utils';

class About extends React.Component {

    constructor(props) {
        super(props);
        this.createColumnConfig();
        this.props.headerUIStore.setTitleKey('about:about.title');
    }

    componentDidMount() {
        this.props.aboutStore.loadBuildInformation();
    }

    componentWillUnmount() {
        this.props.aboutStore.reset();
    }

    createColumnConfig() {
        this.columnConfig = [
            {
                headerKey: 'about:git.commit.id',
                prop: 'gitCommitId'
            },
            {
                headerKey: 'about:git.build.time',
                prop: 'gitBuildTime',
                transform: gitBuildTime => gitBuildTime && dateTimeUtil.toDisplayDateTime(gitBuildTime)
            },
            {
                headerKey: 'about:git.branch',
                prop: 'gitBranch'
            }
        ];
    }

    render() {
        return (
            this.props.aboutStore.isLoading
                ? <Loader />
                : <div className="content sticky-scroll">
                    <div className="single__column ml-4">
                        <h2 className="mt-4">{this.props.t('about:build.information')}</h2>
                        <RowDetails dataObject={this.props.aboutStore.buildInformation}
                                    columnConfig={this.columnConfig}/>
                    </div>
                </div>
        );
    }
}

export default inject(
    'aboutStore',
    'headerUIStore'
)(withTranslation()(observer(About)));