import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import menuHelper from '../../menu.helper';

const UserMenu = () => {
    const location = useLocation();
    const { t } = useTranslation('menu');
    const accountsUserURL = menuHelper.getAccountsUserURL(location);

    return accountsUserURL && (
        <div className="sub__menu">
            <NavLink
                className={({ isActive }) => isActive ? "menu__item is-active" : "menu__item"}
                to={accountsUserURL}>
                {t("menu.account.users.overview")}
            </NavLink>
            <NavLink
                className={({ isActive }) => isActive ? "menu__item is-active" : "menu__item"}
                to={`${accountsUserURL}/login-history`}>
                {t("menu.account.users.login.history")}
            </NavLink>
            <NavLink
                className={({ isActive }) => isActive ? "menu__item is-active" : "menu__item"}
                to={`${accountsUserURL}/transactions`}>
                Transactions
            </NavLink>
            <NavLink
                className={({ isActive }) => isActive ? "menu__item is-active" : "menu__item"}
                to={`${accountsUserURL}/profiles`}>
                {t("menu.account.users.profiles")}
            </NavLink>
            <NavLink
                className={({ isActive }) => isActive ? "menu__item is-active" : "menu__item"}
                to={`${accountsUserURL}/policy-run-history`}>
                {t("menu.account.users.policy.run.history")}
            </NavLink>
        </div>
    );
};

export default UserMenu;
