import {action, computed, makeObservable, observable} from 'mobx';
import BaseStore from '../base.store';
import connectionInformationService from './connection-information.service';

class ConnectionInformationStore extends BaseStore {
    isLoading = false;
    connectionInformations;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setConnectionInformations([]);

        makeObservable(this, {
            isLoading: observable,
            connectionInformations: observable,
            setIsLoading: action,
            setConnectionInformations: action,
            loadConnectionInformations: action,
            connectionInformationOptions: computed
        });

        this.createInitialState();
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setConnectionInformations(connectionInformations) {
        this.connectionInformations = connectionInformations;
    }

    loadConnectionInformations = (accountId) => {
        if (!(this.connectionInformations instanceof Array) || this.connectionInformations.length === 0) {
            this.setIsLoading(true);

            connectionInformationService.retrieveConnectionInformation({accountId})
                .then(({data}) => {
                    this.setConnectionInformations(data);
                })
                .finally(() => {
                    this.setIsLoading(false);
                });
        }
    };

    get connectionInformationOptions() {
        if (!(this.connectionInformations instanceof Array)) {
            return [];
        }

        return this.connectionInformations.map(connectionInfo => {
            return {
                key: connectionInfo.name,
                text: connectionInfo.name,
                value: connectionInfo.name
            };
        }).sort((s1, s2) => s1.text.localeCompare(s2.text));
    }
}

const connectionInformationStore = new ConnectionInformationStore()
export default connectionInformationStore;