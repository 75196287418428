import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../base.store';
import accountService from './account.service';
import {paginationUtil} from '../../../utils';

class AccountStore extends BaseStore {
    isLoading = false;
    account = {};
    accountBillingDetails = {};

    constructor() {
        super();
        makeObservable(this, {
            isLoading: observable,
            account: observable,
            accountBillingDetails: observable,
            setIsLoading: action,
            setAccount: action,
            setAccountBillingDetails: action
        });
        super.createInitialState();
    }

    setIsLoading(loading) {
        this.isLoading = loading;
    }

    setAccount = (account) => {
        this.account = account;
    };

    setAccountBillingDetails = (accountBillingDetails) => {
        this.accountBillingDetails = accountBillingDetails;
    };

    archiveAccount = (salesforceId, archiveReason) => {
        this.setIsLoading(true);

        return accountService.archiveAccount(salesforceId, archiveReason)
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    retrieveAccount = (accountId) => {
        return accountService.retrieveDetailedAccount(accountId)
            .then(({data}) => {
                this.setAccount(data);
            });
    };

    retrieveAccountBillingDetails = (accountId) => {
        return accountService.retrieveAccountBillingDetails(accountId)
            .then(({data}) => {
                this.setAccountBillingDetails(data);
            });
    };

    createAccount = (account) => {
        return accountService.createAccount(account);
    };

    updateAccount = (accountId) => (account) => {
        return accountService.updateAccount(accountId, account);
    };

    getPageableAccounts = (currentPage, pageSize, sortingFields, filter, tableSelectCallback, archived) => {
        let params = {
            page: currentPage,
            size: pageSize,
            filter,
            sort: paginationUtil.createSingleSortingParameter(sortingFields),
            archived: archived
        };

        return accountService.getPageableAccounts(params)
            .then(response => {
                tableSelectCallback(response.data);
            });
    };
}

const accountStore = new AccountStore()
export default accountStore;