import addAlphanumericValidator from './alphanumeric.validator';
import addAuth0PasswordValidator from './auth0-password.validator';
import addCustomRequiredValidator from './custom-required.validator';
import addUniqueProductCodeValidator from './unique-product-code.validator';

function customValidations() {
    addAlphanumericValidator();
    addAuth0PasswordValidator();
    addCustomRequiredValidator();
    addUniqueProductCodeValidator();
}

export default customValidations;
