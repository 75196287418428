import {FastField} from 'formik';
import addAllCustomValidations from './custom-validations/index';
import * as Yup from 'yup';

class FormValidationHelper {

    constructor() {
        addAllCustomValidations();
    }

    getSchema = (formFields) => {
        return buildSchema(formFields);
    };
}

function buildSchema(formFields) {
    formFields = formFields instanceof Array ? formFields : [formFields];
    let shape = {};

    formFields.map(formField => formField && formField.type === FastField ? formField.props.render() : formField)
        .filter(formField => formField.props.validator)
        .map(formField => formField.props)
        .forEach(formProps => shape[formProps.id] = buildValidatorSchema(formProps.validator, formProps.label));

    return Yup.object().shape(shape);
}

/**
 * The "rules" & "type" are defined by the schema functions provided by Yup
 * You can find the documentation here https://github.com/jquense/yup
 */
function buildValidatorSchema(options, label) {
    const rulesArray = options.rules instanceof Array ? options.rules : [options.rules];
    let validatorSchema = Yup[options.type.toLowerCase()]().label(label);

    rulesArray.forEach(rule => {
        // Specific rule cases are only required if you need to customise the error message
        switch (rule) {
            case 'required':
                validatorSchema = options.type.toLowerCase() === 'date' ? validatorSchema.required('Valid date is required') : validatorSchema.required('This field is required');
                break;
            case 'requiredIf':
                validatorSchema = validatorSchema.requiredIf(options.requiredIf, options.requiredIfMessage);
                break;
            case 'email':
                validatorSchema = validatorSchema.email('Please enter a valid email address');
                break;
            case 'auth0Password':
                validatorSchema = validatorSchema.auth0Password(
                    options.passwordPolicy || 'GOOD',
                    options.passwordMinLength || 8,
                    options.passwordRequired === undefined ? true : options.passwordRequired);
                break;
            case 'uniqueProductCode':
                validatorSchema = validatorSchema.uniqueProductCode(
                    options.existingProductCodes,
                    options.excludedProductCode
                );
                break;
            default:
                validatorSchema = validatorSchema[rule]();
        }
    });

    return validatorSchema;
}

const formValidationHelper = new FormValidationHelper();
export default formValidationHelper;