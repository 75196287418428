import React from 'react';
import {Trans, withTranslation} from 'react-i18next';

const NoResultsMessage = (props) => {

    const {
        t,
        endDate,
        startDate,
        tableData
    } = props;

    return (
        (!tableData || tableData.length === 0) && startDate && endDate ?
            <div className="rt-noData">
                <span>
                    <Trans i18nKey="datepicker:datepicker.no.results.found.for.date.range.selected">
                        {{dateRange: `${startDate.format('ll')} - ${endDate.format('ll')}`}}
                    </Trans>
                </span>
                <label className="light">
                    {t('datepicker:datepicker.please.select.different.period')}
                </label>
            </div> :
            <div className="rt-noData">
                {t('common:common.no.results.found')}
            </div>
    )
};

export default withTranslation()(NoResultsMessage);