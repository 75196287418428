import React, {useContext, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import columnConfig from './user-login-history.column-config';
import PageableTableSelect from '../../../../../../components/table-select/pageable/PageableTableSelect';
import EncDateRangePicker from '../../../../../../components/date-range-picker/EncDateRangePicker';
import {MobXProviderContext, observer} from 'mobx-react';
import {useParams} from 'react-router-dom';

const UserLoginHistory = observer(() => {
    const { t } = useTranslation();
    const { userLoginHistoryStore, userLoginHistoryUIStore } = useContext(MobXProviderContext);
    const { userId } = useParams();
    const tableSelectRef = useRef(null);

    const setTableSelectRef = (node) => {
        tableSelectRef.current = node;
    };

    useEffect(() => {
        return () => {
            userLoginHistoryUIStore.reset();
        };
    }, [userLoginHistoryUIStore]);

    const getPageableUserLoginHistory = (...params) => {
        userLoginHistoryStore.getPageableUserLoginHistory(userLoginHistoryUIStore.dateRange, userId)(...params);
    };

    const renderAdditionalTableFilters = () => (
        <EncDateRangePicker
            startDate={userLoginHistoryUIStore.dateRange.startDate}
            endDate={userLoginHistoryUIStore.dateRange.endDate}
            handleRange={dateRange => {
                userLoginHistoryUIStore.setDateRange(dateRange);
                if (tableSelectRef.current) {
                    tableSelectRef.current.refreshTable();
                }
            }}
        />
    );

    return (
        <div className="content sticky-scroll">
            <PageableTableSelect
                multiSelect={false}
                selectable={false}
                columnConfig={columnConfig}
                fetchPageableData={getPageableUserLoginHistory}
                rowId="id"
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('user:user.login.history.entries')}
                defaultColumnSorted="loginDate"
                tableSelectRef={setTableSelectRef}
                additionalFilteringComponents={renderAdditionalTableFilters()}
            />
        </div>
    );
});

export default UserLoginHistory;
