import React, {useEffect, useRef, useContext, useState} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import columnConfig from './user-policy-run-history.column-config';
import EncDateRangePicker from '../../../../../../components/date-range-picker/EncDateRangePicker';
import NoResultsMessage from '../../../../../../components/table-select/NoResultsMessage';
import { Button } from 'semantic-ui-react';
import PageableTableSelect from '../../../../../../components/table-select/pageable/index';
import { MobXProviderContext } from 'mobx-react';
import { useParams } from 'react-router-dom';

const UserPolicyRunHistory = observer(() => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const { policyRunHistoryStore, userPolicyRunHistoryUIStore, userStore } = useContext(MobXProviderContext);
    const tableSelectRef = useRef(null);
    const [tableSelectFilter, setTableSelectFilter] = useState('');

    const setTableSelectRef = (node) => {
        tableSelectRef.current = node;
    };

    useEffect(() => {
        userStore.retrieveUser(userId);

        return () => {
            userPolicyRunHistoryUIStore.reset();
        };
    }, [userId, userStore, userPolicyRunHistoryUIStore]);

    const getPageablePolicyRunHistory = (...params) => {
        policyRunHistoryStore.getPageablePolicyRunHistory(userPolicyRunHistoryUIStore.dateRange, undefined, userId)(...params);
    };

    const exportToCSV = () => {
        policyRunHistoryStore.exportToCsv(
            userPolicyRunHistoryUIStore.dateRange,
            tableSelectFilter,
            undefined,
            { contactId: userId, contactName: userStore.user.partnerUserId }
        );
    };

    const handleTextFilterChange = filter => {
        if (tableSelectRef.current) {
            setTableSelectFilter(filter);
        }
    };

    const renderAdditionalFilteringComponents = () => (
        <EncDateRangePicker
            startDate={userPolicyRunHistoryUIStore.dateRange.startDate}
            endDate={userPolicyRunHistoryUIStore.dateRange.endDate}
            handleRange={dateRange => {
                userPolicyRunHistoryUIStore.setDateRange(dateRange);
                if (tableSelectRef.current) {
                    tableSelectRef.current.refreshTable();
                }
            }}
        />
    );

    const renderNoResultsMessage = () => (
        <NoResultsMessage
            startDate={userPolicyRunHistoryUIStore.dateRange.startDate}
            endDate={userPolicyRunHistoryUIStore.dateRange.endDate}
        />
    );

    return (
        <div className="content sticky-scroll">
            <div className="section__full--width--padding mt-4">
                <Button className="button__primary" onClick={exportToCSV}>
                    {t('common:common.export.to.csv')}
                </Button>
            </div>

            <div className="sticky-scroll">
                <PageableTableSelect
                    multiSelect={false}
                    columnConfig={columnConfig}
                    fetchPageableData={getPageablePolicyRunHistory}
                    rowId="id"
                    isSticky={true}
                    stickyTopOffset={55}
                    stickyTopBarOffset={0}
                    dataName={t('common:common.profiles')}
                    additionalFilteringComponents={renderAdditionalFilteringComponents()}
                    selectable={false}
                    noDataText={renderNoResultsMessage()}
                    defaultColumnSorted="policyDate"
                    handleTextFilterChange={handleTextFilterChange}
                    tableSelectRef={setTableSelectRef}
                />
            </div>
        </div>
    );
});

export default UserPolicyRunHistory;
