import {action, computed, makeObservable, observable} from 'mobx';

import BaseStore from '../../../../store/domain/base.store';

class AccountProductsUIStore extends BaseStore {

    selectedDataProducts;
    isAssignDataProductsModalOpen = false;

    constructor() {
        super();
        this.setSelectedDataProduct([]);

        makeObservable(this, {
            isAssignDataProductsModalOpen: observable,
            selectedDataProducts: observable,
            openAssignDataProductsModal: action,
            closeAssignDataProductsModal: action,
            setSelectedDataProduct: action,
            selectedSalesforceIds: computed
        });

        super.createInitialState();
    }

    setSelectedDataProduct(selectedDataProducts) {
        this.selectedDataProducts = selectedDataProducts;
    }

    openAssignDataProductsModal = () => {
        this.isAssignDataProductsModalOpen = true;
    };

    closeAssignDataProductsModal = () => {
        this.isAssignDataProductsModalOpen = false;
    };

    get selectedSalesforceIds() {
        if (!(this.selectedDataProducts instanceof Array)) {
            return [];
        }

        return this.selectedDataProducts.map(dataProduct => dataProduct.salesforceId);
    }
}

const accountProductsUIStore = new AccountProductsUIStore()
export default accountProductsUIStore;