import {withTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

import {regExpUtil, stringUtil} from '../../utils';
import BaseTableSelect from './BaseTableSelect';

class TableSelect extends BaseTableSelect {

    static propTypes = {
        ...BaseTableSelect.propTypes,
        rowData: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        super.onComponentUpdate(prevProps, prevState, this.props.loading);
        this.updateSelectedRow(prevState, this.props.rowData);
        this.removeDeletedRow(prevState, prevProps.rowData, this.props.rowData);
    }

    showPaginationBar = filteredData => {
        return !this.props.loading
            && filteredData.length > this.getPageSize();
    };

    onTextFilterChange = event => {
        super.onTextFilterChange(event);
    };

    filterDataLocally = rows => {

        let filteredData = rows;
        if (stringUtil.isNotBlank(this.state.textFilter)) {

            let filterRegex = new RegExp(regExpUtil.escapeRegExp(this.state.textFilter), 'i');
            filteredData =
                rows.filter(row =>
                    Object.values(row)
                        .filter(value => value)
                        .some(value => filterRegex.test(value.toString()))
                );
        }

        return filteredData;
    };

    getReactTableProperties = (filteredRows) => {

        const {
            loading
        } = this.props;

        return super.getReactTableProperties(
            filteredRows,
            loading,
            this.showPaginationBar(filteredRows)
        );
    };

    render() {
        const filteredRows = this.filterDataLocally(this.props.rowData);
        return super.renderCustomisedComponent(
            this.getReactTableProperties(filteredRows),
            filteredRows.length,
            this.onTextFilterChange
        );
    }
}

export default withTranslation()(observer(TableSelect));

