import {action, computed, makeObservable, observable} from 'mobx';

import BaseStore from '../../../store/domain/base.store';

class ActiveUsersUIStore extends BaseStore {
    selectedUsers;
    isEditUserModalOpen = false;
    isAddNewUserModalOpen = false;
    isArchiveConfirmOpen = false;
    isActivateConfirmOpen = false;
    isDeactivateConfirmOpen = false;
    archiveUserReason = '';
    tableFilter = '';

    constructor() {
        super();
        this.setSelectedUsers([]);
        this.closeAddNewUserModal();
        this.closeEditUserModal();
        this.setArchiveUserReason();

        makeObservable(this, {
            selectedUsers: observable,
            isAddNewUserModalOpen: observable,
            isEditUserModalOpen: observable,
            isArchiveConfirmOpen: observable,
            isActivateConfirmOpen: observable,
            isDeactivateConfirmOpen: observable,
            tableFilter: observable,

            setSelectedUsers: action,
            setArchiveUserReason: action,
            openAddNewUserModal: action,
            closeAddNewUserModal: action,
            openEditUserModal: action,
            closeEditUserModal: action,
            openArchiveConfirmModal: action,
            closeArchiveConfirmModal: action,
            openActivateConfirmModal: action,
            closeActivateConfirmModal: action,
            openDeactivateConfirmModal: action,
            closeDeactivateConfirmModal: action,
            setTableFilter: action,

            singleSelectedUser: computed,
            isSingleSelectedUser: computed,
            isSelectedUserEditable: computed,
            isSelectedUsersActivatable: computed,
            isSelectedUsersDeactivatable: computed,
            isSelectedUsersArchivable: computed
        });

        this.createInitialState();
    }

    setSelectedUsers = (selectedUsers) => {
        this.selectedUsers = selectedUsers;
    };

    setArchiveUserReason = (archiveUserReason) => {
        this.archiveUserReason = archiveUserReason;
    };

    openAddNewUserModal = () => {
        this.isAddNewUserModalOpen = true;
    };

    closeAddNewUserModal = () => {
        this.isAddNewUserModalOpen = false;
    };

    openEditUserModal = () => {
        this.isEditUserModalOpen = true;
    };

    closeEditUserModal = () => {
        this.isEditUserModalOpen = false;
    };

    openArchiveConfirmModal = () => {
        this.isArchiveConfirmOpen = true;
    };

    closeArchiveConfirmModal = () => {
        this.isArchiveConfirmOpen = false;
    };

    openActivateConfirmModal = () => {
        this.isActivateConfirmOpen = true;
    };

    closeActivateConfirmModal = () => {
        this.isActivateConfirmOpen = false;
    };

    openDeactivateConfirmModal = () => {
        this.isDeactivateConfirmOpen = true;
    };

    closeDeactivateConfirmModal = () => {
        this.isDeactivateConfirmOpen = false;
    };

    setTableFilter = (tableFilter) => {
        this.tableFilter = tableFilter
    };

    get singleSelectedUser() {
        return this.selectedUsers
            && this.selectedUsers.length > 0
            && this.selectedUsers[0];
    }

    get isSingleSelectedUser() {
        return this.selectedUsers
            && this.selectedUsers.length === 1
    }

    get isSelectedUserEditable() {
        return this.isSingleSelectedUser
            && !this.singleSelectedUser.accountArchived
            && this.singleSelectedUser.accountValidORPC
            && !this.singleSelectedUser.externallyManaged;
    }

    get isSelectedUsersActivatable() {
        return this.selectedUsers
            && this.selectedUsers.length > 0
            && this.selectedUsers.every(user => user.accountActive)
            && this.selectedUsers.every(user => !user.active)
            && this.selectedUsers.every(user => user.accountValidORPC)
            && this.selectedUsers.every(user => !user.externallyManaged);
    }

    get isSelectedUsersDeactivatable() {
        return this.selectedUsers
            && this.selectedUsers.length > 0
            && this.selectedUsers.every(user => user.accountActive)
            && this.selectedUsers.every(user => user.active)
            && this.selectedUsers.every(user => !user.externallyManaged);
    }

    get isSelectedUsersArchivable() {
        return this.selectedUsers
            && this.selectedUsers.length > 0
    }
}

const activeUsersUIStore = new ActiveUsersUIStore()
export default activeUsersUIStore;