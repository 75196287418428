import React from 'react';
import Modal from 'react-modal';
import {Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

function EncConfirm (props) {

    const openConfirm = typeof props.openConfirm === 'function' ? props.openConfirm() : props.openConfirm;
    return (
        <Modal
            isOpen={openConfirm}
            onRequestClose={props.closeConfirm}
            closeTimeoutMS={props.closeTimeoutMS || 200}
            className="modal__alert"
            contentLabel="Confirm"
            overlayClassName="modal__overlay--alert">
                <h1>{props.confirmTitle}</h1>
                {props.confirmMessage}

            {props.additionalFields !== undefined &&
                <span className="form-field">
                    {props.additionalFields}
                </span>
            }
               <div className="button-box">
                   <Button className="button__primary" onClick={props.closeConfirm}>
                       {props.t('common:common.cancel')}
                   </Button>
                   <Button onClick={() => {
                       props.confirmFunc();
                   }}>
                       {props.confirmButtonLabel}
                   </Button>
               </div>
        </Modal>
    )
}

export default withTranslation()(EncConfirm);

EncConfirm.defaultProps = {
  openConfirm: false
};

EncConfirm.propTypes = {
    openConfirm: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
    closeTimeoutMS: PropTypes.number,
    closeConfirm: PropTypes.func.isRequired,
    confirmTitle: PropTypes.string.isRequired,
    confirmMessage: PropTypes.node.isRequired,
    confirmFunc: PropTypes.func.isRequired,
    confirmButtonLabel: PropTypes.string.isRequired,
    additionalFields: PropTypes.element
};