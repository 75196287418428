const config = [
    {
        headerKey: 'common.type',
        prop: 'type',
        width: 200
    },
    {
        headerKey: 'common.description',
        prop: 'description',
        width: 500
    },
    {
        headerKey: 'account:account.charge.number',
        prop: 'zuoraChargeNumber'
    },
    {
        headerKey: 'common.quantity',
        prop: 'quantity',
        transform: (billingRun) => billingRun.type === 'TRANSACTION' ? parseFloat(billingRun.quantity).toFixed(2) : billingRun.quantity
    }
];
export default config;