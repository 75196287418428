import React from 'react';
import {Checkbox, Form, Input, Radio, Select, Dropdown} from 'semantic-ui-react';
import {FastField} from 'formik';
import ErrorMessage from './ErrorMessage';
import {getI18n} from 'react-i18next';
import {SingleDatePicker} from 'react-dates';

const STANDARD_DATE_FORMAT = 'DD/MM/YYYY';
const SEARCH = true;
const SELECTION = true;
const FLUID = true;
const t = (msg, values) => getI18n().t(msg, values);

let encField = (control) => (props) => {
    const {handleChange, handleBlur, errors, values} = props.fieldProperties;

    const fieldProps = {
        label: t(props.label),
        type: props.type,
        placeholder: props.placeholder,
        options: props.options,
        validator: props.validator,
        disabled: props.disabled,
        control: control,
        onChange: handleChange,
        onBlur: handleBlur,
        error: !!errors[props.id]
    };

    if (control === Radio) {
        fieldProps.value = props.value;
        fieldProps.checked = (values[props.id] === props.value);
        fieldProps.name = props.id;
    }else if (control === Dropdown) {
        fieldProps.search = SEARCH;
        fieldProps.selection = SELECTION;
        fieldProps.fluid = FLUID;
        fieldProps.id = props.id;
        fieldProps.defaultValue = values[props.id];
    } else {
        fieldProps.defaultValue = values[props.id];
        fieldProps.defaultChecked = values[props.id];
        fieldProps.id = props.id;
    }

    return (
        <div className="form-validation-field">
            <Form.Field {...fieldProps}/>
            <ErrorMessage error={errors[props.id]}/>
        </div>
    );
};

let fastField = (control) => (props) => {
    return (
        <FastField
            name={props.id}
            render={() => encField(control)(props)}
        />
    );
};

let EncInputField = encField(Input);
let EncCheckboxField = encField(Checkbox);
let EncRadioField = encField(Radio);

let EncSelectField = fastField(Select);

let EncFilterDropdownField = fastField(Dropdown);


let EncSingleDatePicker = (props) => {
    const {handleChangeWithNoEvent, handleFocusChange, focusedField, errors, values} = props.fieldProperties;

    return (
        <div className='form-validation-field'>
            <div className={errors[props.id] ? 'error field' : 'field'}>
                <label>{t(props.label)}</label>
                <SingleDatePicker
                    id={props.id}
                    date={values[props.id]}
                    onDateChange={(value) => handleChangeWithNoEvent(props.id, value)}
                    focused={focusedField === props.id}
                    onFocusChange={() => handleFocusChange(props.id)}
                    isOutsideRange={props.isOutsideRange ? (newValue) => props.isOutsideRange(newValue, values) : undefined}
                    displayFormat={STANDARD_DATE_FORMAT}
                    numberOfMonths={1}
                    noBorder={true}
                    hideKeyboardShortcutsPanel={true}
                />
            </div>
            <ErrorMessage error={errors[props.id]}/>
        </div>
    );
};

let AllEncFieldTypes = [
    EncInputField,
    EncSelectField,
    EncCheckboxField,
    EncRadioField,
    EncSingleDatePicker,
    EncFilterDropdownField
];

export {AllEncFieldTypes, EncInputField, EncSelectField, EncCheckboxField, EncRadioField, EncSingleDatePicker, EncFilterDropdownField};