import React from 'react';
import {Trans} from 'react-i18next';
import PropTypes from 'prop-types';

export default function ConfirmMessage (props) {
    let items = [];
    let displayedItems = [];
    const MAX_NUM_ITEMS_TO_DISPLAY = props.numItemsToDisplay;
    const item = props.itemsArray.length === 1 ? props.itemKey : props.itemsKey;
    props.itemsArray.forEach(item => {
        if (items.length > MAX_NUM_ITEMS_TO_DISPLAY - 1) {
            items.push(` ${item[props.itemProperty]}`);
            displayedItems = items.slice(0, MAX_NUM_ITEMS_TO_DISPLAY);

            return displayedItems;

        } else {
            return items.push(` ${item[props.itemProperty]}`);
        }

    });

    if (props.confirmMessageTranslationKey instanceof Array) {
        return (
            props.confirmMessageTranslationKey.map((msg, i) => {
                if (msg.translation && msg.values) {
                    return (<p key={i}><Trans i18nKey={msg.translation}>{msg.values}</Trans></p>)
                }

                return (<p key={i}><Trans i18nKey={msg} /></p>)
            })
        )
    } else {
        return (
            <p className="modal__confirm--message">
                <Trans i18nKey={items.length > MAX_NUM_ITEMS_TO_DISPLAY ? props.confirmMessageLimitDisplayTranslationKey : props.confirmMessageTranslationKey}>
                {{selectedItems: items.length > MAX_NUM_ITEMS_TO_DISPLAY ? displayedItems : items, itemPluralise: item, itemCount: items.length - MAX_NUM_ITEMS_TO_DISPLAY}}
                </Trans>
            </p>
        )
    }


}

ConfirmMessage.defaultProps = {
    numItemsToDisplay: 10
};


ConfirmMessage.propTypes = {
    itemsArray: PropTypes.array.isRequired,
    itemProperty: PropTypes.string,
    confirmMessageTranslationKey: PropTypes.any.isRequired,
    itemsKey: PropTypes.node.isRequired,
    itemKey: PropTypes.node.isRequired,
    numItemsToDisplay: PropTypes.number,
    confirmMessageLimitDisplayTranslationKey: PropTypes.string
};