import { observable, action, makeObservable } from 'mobx';
import BaseStore from '../../store/domain/base.store';

class HeaderUIStore extends BaseStore {
    titleKey = undefined;

    constructor() {
        super();
        makeObservable(this, {
            titleKey: observable,
            setTitleKey: action
        });
        this.setTitleKey('');

        super.createInitialState();
    }

    setTitleKey(titleKey) {
        this.titleKey = titleKey;
    }
}

const headerUIStore = new HeaderUIStore();
export default headerUIStore;