import React from 'react';
import {withTranslation} from 'react-i18next';
import TableSelect from '../../../../../../components/table-select/TableSelect';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';
import StickyWrapper from '../../../../../../components/wrappers/index';

const AccountBillingRunEntryDetails = props => {
    const {t} = props;

    return (
        <div className="section__full--width">
            <h1 className="text-center">{props.header}</h1>

            <TableSelect
                selectable={false}
                columnConfig={props.columnConfig}
                rowData={props.data}
                loading={props.loading}
                dataName={t(props.dataName)}
                rowId="id"
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                defaultColumnSorted="endTransaction"
            />
            <StickyWrapper stickyFooter={true} className="sticky__section--footer">
                <div className="section__full--width--padding">
                    <div className="button-box">
                        <Button onClick={props.backFunc}>{t('common:common.back')}</Button>
                    </div>
                </div>
            </StickyWrapper>
        </div>
    );
};

AccountBillingRunEntryDetails.propTypes = {
    header: PropTypes.string.isRequired,
    columnConfig: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    dataName: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    backFunc: PropTypes.func.isRequired
};

export default withTranslation('account')(AccountBillingRunEntryDetails);