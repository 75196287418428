import {lookup} from 'country-data';
import {sentenceCase} from 'sentence-case';
import {dateTimeUtil} from '../../../../utils';

const config = [
    {
        headerKey: 'account:account.name',
        prop: 'name'
    },
    {
        headerKey: 'account:account.auth0.tenant',
        prop: 'auth0Tenant'
    },
    {
        headerKey: 'account:account.taxable',
        prop: 'taxable'
    },
    {
        headerKey: 'account:account.active.users',
        prop: 'numActiveUsers'
    },
    {
        headerKey: 'account:account.city',
        prop: 'billingCity'
    },
    {
        headerKey: 'account:account.country',
        prop: 'billingCountryCode',
        transform: billingCountryCode => {
            if (!billingCountryCode) {
                return undefined;
            }

            let result = lookup.countries({alpha2: billingCountryCode});
            return !result || result.length === 0 ? undefined : result[0].name;
        }
    },
    {
        headerKey: 'account:account.salesforce.id',
        prop: 'id'
    },
    {
        headerKey: 'account:account.channel.account.code',
        prop: 'channelAccountCode'
    },
    {
        headerKey: 'account:account.subscription.type',
        prop: 'subscriptionType',
        transform: subscriptionType => (subscriptionType && subscriptionType !== '') ? subscriptionType : 'No subscription'
    },
    {
        headerKey: 'account:account.subscription.status',
        prop: 'status',
        transform: status => status ? sentenceCase(status) : status
    },
    {
        headerKey: 'account:account.logins.last.24.hours',
        prop: 'loginsLast24Hours'
    },
    {
        headerKey: 'account:account.transactions.last.24.hours',
        prop: 'transactionsLast24Hours'
    },
    {
        headerKey: 'account:account.profiles.created.last.24.hours',
        prop: 'profilesCreatedLast24Hours'
    },
    {
        headerKey: 'common.created.date',
        prop: 'createdDate',
        transform: createdDate => createdDate && dateTimeUtil.toDisplayDateTime(createdDate)
    },
    {
        headerKey: 'common.created.by',
        prop: 'createdBy'
    },
    {
        headerKey: 'common.date.modified',
        prop: 'lastModifiedDate',
        transform: lastModified => lastModified && dateTimeUtil.toDisplayDateTime(lastModified)
    },
    {
        headerKey: 'common.last.modified.by',
        prop: 'lastModifiedBy'
    },
    {
        headerKey: 'account:account.auth.external.connection.names',
        prop: 'auth0ExternalConnectionNames'
    }
];
export default config;