import React from 'react';
import {withTranslation} from 'react-i18next';
import {countryCodeUtil} from '../../../../utils';
import {
    EncForm,
    EncInputField,
    EncRadioField,
    EncSelectField,
    withEncFormHandler
} from '../../../../components/hocs/form-validation/index';

let EditAccount = (props) => {

    const {formProperties, fieldProperties, t} = props;

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncInputField
                id="name"
                label="account:account.name"
                validator={{
                    type: 'string',
                    rules: ['alphanumericWithSpaceParenthesesAndAmpersand']
                }}
            />
            <EncSelectField
                id="auth0Id"
                label="account:account.auth0.tenant"
                disabled={true}
                options={props.auth0TenantOptions}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="billingCity"
                label="account:account.city"
                validator={{
                    type: 'string',
                    rules: []
                }}
            />
            <EncSelectField
                id="billingCountryCode"
                label={'account:account.country'}
                options={countryCodeUtil.getBillingCountryCodeOptions()}
                placeholder='Please select a country'
                required={true}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncSelectField
                id="subscriptionType"
                label="account:account.subscription.type"
                options={props.subscriptionTypeOptions}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="auth0ExternalConnectionNames"
                label="account:account.auth.external.connection.names.field"
                validator={{
                    type: 'string',
                    rules: []
                }}
            />
            <div className="radio__group">
                <label>{t('account:account.taxable')}</label>
                <EncRadioField
                    id="taxable"
                    label="common:common.yes"
                    value={true}
                />
                <EncRadioField
                    id="taxable"
                    label="common:common.no"
                    value={false}
                />
            </div>
            <div className="radio__group">
                <label>{t('account:account.subscription.status')}</label>
                <EncRadioField
                    id="status"
                    label="account:account.active"
                    value={'ACTIVE'}
                />
                <EncRadioField
                    id="status"
                    label="account:account.inactive"
                    value={'INACTIVE'}
                />
            </div>
        </EncForm>
    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'account:account.edit.account',
    successMessage: 'account:account.successfully.updated',
    validateOnChange: false,
    validateOnBlur: true,
    closeOnPristine: true,
    confirmAlertMsg: (initialValues, submitValues) => {
        if (initialValues.status === 'ACTIVE' && submitValues.status === 'INACTIVE') {
            return {
                header: 'account:account.confirm.edit.account',
                messages: [
                    'account:account.account.being.made.inactive',
                    'account:account.edit.account.inactive.confirm.message'
                ]
            }
        }

        if (initialValues.status === 'INACTIVE' && submitValues.status === 'ACTIVE') {
            return {
                header: 'account:account.confirm.edit.account',
                messages: [
                    'account:account.account.being.made.active',
                    'account:account.edit.account.active.confirm.message',
                ]
            }
        }

        return undefined;
    }
};

export default withEncFormHandler(
    FORM_VALIDATION_OPTIONS
)(withTranslation()(EditAccount));


