import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const ACCOUNT_BILLING_RUN_ENDPOINT = '/api/v1/account-billing-run';

class AccountBillingRunService {

    getAccountBillingRunCorporateProfiles(billingRunId) {
        return axiosInterface.get(`${ACCOUNT_BILLING_RUN_ENDPOINT}/${billingRunId}/corporate-profiles`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading account billing run corporate profiles. ${error}`);
                throw error;
            });
    }

    getAccountBillingRunIndividualProfiles(billingRunId) {
        return axiosInterface.get(`${ACCOUNT_BILLING_RUN_ENDPOINT}/${billingRunId}/individual-profiles`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading account billing run individual profiles. ${error}`);
                throw error;
            });
    }

    getAccountBillingRunTransactions(billingRunId) {
        return axiosInterface.get(`${ACCOUNT_BILLING_RUN_ENDPOINT}/${billingRunId}/transactions`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading account billing run transactions. ${error}`);
                throw error;
            });
    }

    getAccountBillingRunCsv(billingRunId) {
        return axiosInterface.get(`${ACCOUNT_BILLING_RUN_ENDPOINT}/${billingRunId}/csv`)
            .then(response => {
                return response;
            }).catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when generating account billing run csv: ${error}`);
                throw error;
            });
    };

    getAccountBillingRuns(params) {
        return axiosInterface.get(ACCOUNT_BILLING_RUN_ENDPOINT, {params})
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occured when loading account billing runs: ${error}`);
                throw error;
            });
    };

    executeAccountBillingRun(accountBillingRun) {
        return axiosInterface.post(ACCOUNT_BILLING_RUN_ENDPOINT, accountBillingRun)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occured when executing account billing run: ${error}`);
                throw error;
            });
    };
}

const accountBillingRunService = new AccountBillingRunService()
export default accountBillingRunService;