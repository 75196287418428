import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {ConfirmMessage} from '../../../../../../../../components/enc-confirm';
import PropTypes from 'prop-types';
import EncConfirm from '../../../../../../../../components/enc-confirm';


let ArchivedAccountUsersConfirmMessages = (props) => {
    const {
        refreshTable,
        t, archivedAccountUsersUIStore, userStore
    } = props;

    function handleUnarchiveUser() {
        userStore.unarchiveUser(archivedAccountUsersUIStore.selectedUsers)
            .then(() => refreshTable());

        archivedAccountUsersUIStore.closeRestoreConfirmModal();
    }

    const restoreMessage = (
        <ConfirmMessage
            itemsArray={archivedAccountUsersUIStore.selectedUsers}
            itemProperty={'email'}
            itemKey={'user'}
            itemsKey={'users'}
            numItemsToDisplay={10}
            confirmMessageTranslationKey={'account:account.unarchive.selected.user.message'}
            confirmMessageLimitDisplayTranslationKey={'account:account.unarchive.selected.user.limit.items.message'}
        />
    );

    return (
        <EncConfirm
            openConfirm={archivedAccountUsersUIStore.isRestoreConfirmModalOpen}
            closeConfirm={archivedAccountUsersUIStore.closeRestoreConfirmModal}
            confirmFunc={handleUnarchiveUser}
            confirmButtonLabel={t('user.restore.user', {count: archivedAccountUsersUIStore.selectedUsers.length})}
            confirmTitle={t('user.confirm.restore')}
            confirmMessage={restoreMessage}
        />
    );
};

ArchivedAccountUsersConfirmMessages.propTypes = {
    refreshTable: PropTypes.func.isRequired
};

export default inject(
    'archivedAccountUsersUIStore',
    'userStore'
)(withTranslation(
    'user'
)(observer(ArchivedAccountUsersConfirmMessages)));