import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../../../../../store/domain/base.store';

class AssignProductsUIStore extends BaseStore {

    selectedProductBundles;
    validationError;

    constructor() {
        super();

        this.selectedProductBundles = [];
        this.clearValidationError();

        makeObservable(this, {
            validationError: observable,
            setValidationError: action
        });

        super.createInitialState();
    }

    setValidationError(validationError) {
        this.validationError = validationError;
    }

    hasValidationErrors = () => {
        return !!(this.validationError
            && this.validationError.errorTranslationKey);
    };

    clearValidationError = () => {
        this.setValidationError({});
    };

    validate = assignedProducts => {
        const isInvalid =
            this.hasNoSelectedProductBundles()
            || this.hasBundleConflicts(assignedProducts);

        return !isInvalid;
    };

    hasNoSelectedProductBundles = () => {
        const noneSelected = this.selectedProductBundles.length === 0;
        if (noneSelected) {
            this.setValidationError({
                errorTranslationKey: 'account:account.assign.product.none.selected'
            });
        }
        return noneSelected;
    };

    hasBundleConflicts = assignedProducts => {

        let hasConflicts = false;

        let currentlyAssignedProducts = new Map(
            assignedProducts.map(product => [
                product.name,
                product.charges.map(charge => charge.chargeCode)
            ])
        );

        let layeredProductBundle = new Map();

        for (const selectedProductBundle of this.selectedProductBundles) {
            let chargeCodesInSelectedBundle = selectedProductBundle.charges.map(charge => charge.chargeCode);

            const conflicts = new Map();
            this.checkProductConflicts(currentlyAssignedProducts, chargeCodesInSelectedBundle, conflicts);
            this.checkProductConflicts(layeredProductBundle, chargeCodesInSelectedBundle, conflicts);

            if (conflicts.size > 0) {
                this.setValidationError({
                    errorTranslationKey: 'account:account.assign.product.bundle.conflicts',
                    selectedBundleName: selectedProductBundle.name,
                    conflictsSummary: conflicts
                });
                hasConflicts = true;
                break;
            } else {
                layeredProductBundle.set(selectedProductBundle.name, chargeCodesInSelectedBundle);
            }
        }

        return hasConflicts;
    };

    checkProductConflicts = (existingProductBundle, chargeCodesInSelectedBundle, conflicts) => {
        existingProductBundle.forEach((chargeCodes, productName) => {
            let chargeCodeIntersection = chargeCodes.filter(chargeCode => chargeCodesInSelectedBundle.includes(chargeCode));
            if (chargeCodeIntersection.length > 0) {
                conflicts.set(productName, chargeCodeIntersection.join(', '));
            }
        });
    }
}

const assignProductsUIStore = new AssignProductsUIStore()
export default assignProductsUIStore;