import {dateTimeUtil} from '../../../../utils';

const config = [
    {
        headerKey: 'transactions:transactions.matter.reference',
        prop: 'matterReference'
    },
    {
        headerKey: 'transactions:transactions.transaction.date',
        prop: 'endTransaction',
        type: 'datetime',
        width: 200,
        transform: accountTransaction => accountTransaction.endTransaction ? dateTimeUtil.toDisplayDateTime(accountTransaction.endTransaction) : null
    },
    {
        headerKey: 'transactions:transactions.product',
        prop: 'encompassSearchProduct'
    },
    {
        headerKey: 'transactions:transactions.price',
        prop: 'productPrice',
        type: 'number',
        width: 145
    },
    {
        headerKey: 'transactions:transactions.billed.by',
        prop: 'billedBy'
    },
    {
        headerKey: 'common.email',
        prop: 'channelPartnerUserId'
    }
];
export default config;