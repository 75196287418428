import React, {Children} from 'react';
import {Button, Form} from 'semantic-ui-react';
import {getI18n} from 'react-i18next';
import {AllEncFieldTypes} from './EncField';
import PropTypes from 'prop-types';

const t = (msg, values) => getI18n().t(msg, values);

let EncForm = (props) => {
    const {handleSubmit, isSubmitting, submitLabel, closeFunc} = props.formProperties;

    return (
        <Form onSubmit={handleSubmit}>
            {assignFieldPropertiesToChildren(props.children, props.fieldProperties)}

            <div className="button-box form-validation">
                <Button type="button" onClick={closeFunc}>{t('common:common.cancel')}</Button>
                <Button type="submit"
                        className={isSubmitting ? 'button__primary button--loading' : 'button__primary'}
                        disabled={isSubmitting}>
                    {t(submitLabel)}
                </Button>
            </div>
        </Form>
    );
};

EncForm.propTypes = {
    formProperties: PropTypes.object.isRequired,
    fieldProperties: PropTypes.object.isRequired
};

export default EncForm;

function assignFieldPropertiesToChildren(children, fieldProperties) {
    return Children.map(children, child => assignFieldPropertiesToChild(child, fieldProperties), undefined);
}

function assignFieldPropertiesToChild(child, fieldProperties) {
    if (!child) {
        return child;
    }

    if (child.props.className === 'radio__group') {
        let children = assignFieldPropertiesToChildren(child.props.children, fieldProperties);
        return React.cloneElement(child, undefined, children);
    }

    return AllEncFieldTypes.includes(child.type) ? React.cloneElement(child, {fieldProperties: fieldProperties}) : child;
}