import BaseStore from '../base.store';
import {action, makeObservable, observable} from 'mobx';
import {fileUtil} from '../../../utils';
import accountBillingRunService from './account-billing-run.service';

class AccountBillingRunStore extends BaseStore {
    isLoading = false;
    accountBillingRuns;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setAccountBillingRuns([]);

        makeObservable(this, {
            isLoading: observable,
            accountBillingRuns: observable,
            setIsLoading: action,
            setAccountBillingRuns: action,
            loadAccountBillingRuns: action
        });

        super.createInitialState();
    }

    setIsLoading = isLoading => {
        this.isLoading = isLoading;
    };

    setAccountBillingRuns = accountBillingRuns => {
        this.accountBillingRuns = accountBillingRuns;
    };


    loadAccountBillingRuns = (accountId, startDate, endDate) => {
        this.setIsLoading(true);

        let params = {
            accountId: accountId,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        };

        return accountBillingRunService.getAccountBillingRuns(params)
            .then(({data}) => {
                this.setAccountBillingRuns(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    generateCsv = (billingRunId, billingRunName, accountName, formattedStartDate, formattedEndDate) => {
        const fileName = billingRunName ? billingRunName : `Billing run ${accountName} ${formattedStartDate}-${formattedEndDate}`;

        return accountBillingRunService.getAccountBillingRunCsv(billingRunId)
            .then(({data}) => {
                fileUtil.download(data, fileName, '.csv');
            });
    };

    executeAccountBillingRun = (accountId) => (accountBillingRun) => {
        if (accountBillingRun) {
            accountBillingRun.accountId = accountId;
        }

        return accountBillingRunService.executeAccountBillingRun(accountBillingRun);
    };
}

const accountBillingRunStore = new AccountBillingRunStore()
export default accountBillingRunStore;