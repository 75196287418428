import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../base.store';
import productService from './product.service';
import {computedFn} from 'mobx-utils';

class ProductBundleArrayStore extends BaseStore {
    productBundles;
    isLoading = false;

    constructor() {
        super();
        this.setProductBundles([]);
        this.setIsLoading(false);

        makeObservable(this, {
            productBundles: observable,
            isLoading: observable,
            setProductBundles: action,
            setIsLoading: action,
            loadProductBundles: action
        });

        super.createInitialState();
    }

    setProductBundles(productBundles) {
        this.productBundles = productBundles;
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    loadProductBundles = () => {
        this.setIsLoading(true);

        return productService.getAllProducts()
            .then(({data}) => {
                this.setProductBundles(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    archiveProductBundles = (salesforceIds) => {
        return productService.archiveProducts(salesforceIds);
    };

    assignedProductBundles = computedFn(function(assignedProductCodes) {
        if (!(this.productBundles instanceof Array)) {
            return [];
        }

        return this.productBundles.filter(productBundle => assignedProductCodes.includes(productBundle.productCode));
    });

    unassignedProductBundles = computedFn(function(assignedProductCodes) {
        if (!(this.productBundles instanceof Array)) {
            return [];
        }

        return this.productBundles.filter(productBundle => !assignedProductCodes.includes(productBundle.productCode));
    });
}

const productBundleArrayStore = new ProductBundleArrayStore()
export default productBundleArrayStore;