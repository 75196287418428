import axiosInterface from '../../axios/axios-instance';
import {toastError, toastSuccess} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const PRPC_ENDPOINT = '/api/v1/product-rate-plan-charge';

class ProductRatePlanChargeService {

    createPRPC(prpc) {
        return axiosInterface.post(PRPC_ENDPOINT, prpc)
            .catch(error => {
                loggingUtil.error(`An error has occurred when create prpc. ${error}`);
                throw error;
            });
    }

    updatePRPC(prpc) {
        return axiosInterface.put(PRPC_ENDPOINT, prpc)
            .catch(error => {
                loggingUtil.error(`An error has occurred when updating prpc. Id: [${prpc.id}]: ${error}`);
                throw error;
            });
    };

    deletePRPC(salesforceIds) {
        return axiosInterface.delete(PRPC_ENDPOINT, {data: salesforceIds})
            .then(() => {
                toastSuccess({
                    statusMessage: salesforceIds.length > 1
                        ? 'productBundles:product.bundle.data.products.successfully.deleted'
                        : 'productBundles:product.bundle.data.product.successfully.deleted'
                });
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when deleting prpc: ${error}`);
                throw error;
            });
    };
}

const productRatePlanChargeService = new ProductRatePlanChargeService()
export default productRatePlanChargeService;