import React from 'react';
import {Button} from 'semantic-ui-react';
import RowDetails from '../../../../../components/row-details/RowDetails';
import {withTranslation} from 'react-i18next';
import columnConfig from './zuora-account-details.column-config';
import PropTypes from 'prop-types';

let ZuoraAccountDetails = (props) => {
    const {t, accountDetails, refreshAccountDetails} = props;

    return (
        <div>
            <h2>{t('account.zuora.account.details')}</h2>

            { accountDetails.zuoraValidationIssues && accountDetails.zuoraValidationIssues.length &&
                <div className="zuora-warning error mt-2">
                    <label className="error">{accountDetails.zuoraValidationIssues}</label>
                </div>
            }

            <Button className={'my-2 button'}
                    onClick={refreshAccountDetails}>
                {t('common:common.refresh')}
            </Button>

            <RowDetails dataObject={accountDetails}
                        columnConfig={columnConfig}/>
        </div>
    );
};

ZuoraAccountDetails.propTypes = {
    accountDetails: PropTypes.object.isRequired,
    refreshAccountDetails: PropTypes.func.isRequired
};

export default withTranslation('account')(ZuoraAccountDetails);