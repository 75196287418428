import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import EncModal from '../../../../../../../../components/enc-modal/index';
import PropTypes from 'prop-types';
import {AddAccountUser} from './add-user/index';
import {EditAccountUser} from './edit-user/index';
import {useParams} from 'react-router';

const ACTIVE = 'ACTIVE';
const DEFAULT_CONNECTION = 'Username-Password-Authentication';

let ActiveAccountUsersModals = (props) => {
    const {
        refreshTable,
        accountStore, activeAccountUsersUIStore, connectionInformationStore, userStore
    } = props;

    const params = useParams();

    useEffect(() => {
        connectionInformationStore.loadConnectionInformations(params.accountId);
        return () => connectionInformationStore.reset();
    }, [connectionInformationStore, params]);

    function createUserSuccess() {
        refreshTable();
        activeAccountUsersUIStore.closeAddNewUserModal();
    }

    function editUserSuccess() {
        refreshTable();
        activeAccountUsersUIStore.closeEditUserModal();
    }

    function retrieveEditUser() {
        return userStore.retrieveUser(activeAccountUsersUIStore.singleSelectedUser.salesforceId)
            .then(() => {
                const data = userStore.user;
                return {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    partnerUserId: data.partnerUserId,
                    connection: data.connection,
                    active: data.active,
                    accountAdmin: data.accountAdmin,
                    uiAccessAllowed: data.uiAccessAllowed,
                    apiAccessAllowed: data.apiAccessAllowed,
                    factsetAccessAllowed: data.factsetAccessAllowed
                };
            });
    }

    return (
        <div>
            <EncModal isOpen={activeAccountUsersUIStore.isAddNewUserModalOpen}
                      closeModal={activeAccountUsersUIStore.closeAddNewUserModal}>
                <AddAccountUser closeFunc={activeAccountUsersUIStore.closeAddNewUserModal}
                         submitFunc={userStore.createUser(accountStore.account.id)}
                         onSuccess={createUserSuccess}
                         connectionInfo={connectionInformationStore.connectionInformations}
                         connectionOptions={connectionInformationStore.connectionInformationOptions}
                         disableActive={accountStore.account.status !== ACTIVE}
                         initialValues={{
                             connection: DEFAULT_CONNECTION,
                             uiAccessAllowed: true,
                             active: accountStore.account.status === ACTIVE
                         }}/>
            </EncModal>

            <EncModal isOpen={activeAccountUsersUIStore.isEditUserModalOpen}
                      closeModal={activeAccountUsersUIStore.closeEditUserModal}>
                <EditAccountUser closeFunc={activeAccountUsersUIStore.closeEditUserModal}
                          submitFunc={userStore.updateUser(accountStore.account.id, activeAccountUsersUIStore.singleSelectedUser.salesforceId)}
                          onSuccess={editUserSuccess}
                          connectionInfo={connectionInformationStore.connectionInformations}
                          connectionOptions={connectionInformationStore.connectionInformationOptions}
                          disableActive={accountStore.account.status !== ACTIVE}
                          initialValues={retrieveEditUser}/>
            </EncModal>
        </div>
    );
};

ActiveAccountUsersModals.propTypes = {
    refreshTable: PropTypes.func.isRequired
};

export default inject(
    'accountStore',
    'activeAccountUsersUIStore',
    'userStore',
    'connectionInformationStore'
)(withTranslation(
    'user'
)(observer(ActiveAccountUsersModals)));