import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import columnConfig from './archived-account-users.column-config';
import PageableTableSelect from '../../../../../../components/table-select/pageable';
import {useParams} from 'react-router';
import {ArchivedAccountUsersConfirmMessages} from './sub-components/confirm-messages';
import {ArchivedAccountUsersTableButtons} from './sub-components/table-buttons';
import {useRefreshTable} from '../../../../../../utils/hooks';

let ArchivedAccountUsers = (props) => {
    const {
        t, archivedAccountUsersUIStore, userStore
    } = props;

    const params = useParams();
    const [refreshTable, setRefreshTable] = useRefreshTable();

    useEffect(() => {
        return () => archivedAccountUsersUIStore.reset();
    }, [archivedAccountUsersUIStore]);

    return (
        <div>
            <ArchivedAccountUsersConfirmMessages refreshTable={refreshTable}/>

            <PageableTableSelect
                multiSelect={true}
                columnConfig={columnConfig}
                fetchPageableData={userStore.getPageableUsers(params.accountId, true)}
                handleRowsSelection={archivedAccountUsersUIStore.setSelectedUsers}
                rowId="id"
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('user.archived.users')}
                tableButtons={<ArchivedAccountUsersTableButtons/>}
                defaultColumnSorted="lastModifiedDate"
                tableSelectRef={setRefreshTable}
                propertyToDisableRow="transferred"
            />
        </div>
    );
};

export default inject(
    'accountStore',
    'archivedAccountUsersUIStore',
    'userStore',
)(withTranslation(
    'user'
)(observer(ArchivedAccountUsers)));