import moment from 'moment';

const DISPLAY_DATE_TIME_FORMAT = 'MMM DD, YYYY h:mma';
const DISPLAY_DATE_FORMAT = 'MMM DD, YYYY';
const DAY_SLASH_MONTH_DATE_FORMAT = 'DD/MM/YYYY';

export default class DateTimeUtil {

    toDisplayDateTime = datetime => {
        return datetime ? moment(datetime).utc(false).format(DISPLAY_DATE_TIME_FORMAT) : null;
    };

    toDisplayDate = date => {
        return date ? moment(date).utc(false).format(DISPLAY_DATE_FORMAT) : null;
    };

    toDayMonthYearDate = (dateTime) => {
        return dateTime ? moment(dateTime).utc(false).format(DAY_SLASH_MONTH_DATE_FORMAT) : null;
    };

    compareDisplayDateTime = (d1, d2) => {
        if (!d1 && !d2) {
            return 0;
        }

        if (!d1) {
            return 1;
        }

        if (!d2) {
            return -1;
        }

        // ToDo: All dates, which AAA uses, are supposed to be in ISO format. Long timestamp migration will avoid any potential issue
        return (moment(d1) < moment(d2) ? 1 : (moment(d1) > moment(d2) ? -1 : 0));
    };

    areMomentDates = (a, b) => {
        return !(!moment.isMoment(a) || !moment.isMoment(b));

    };

    isInclusivelyAfterDay = (a, b) => {
        return this.areMomentDates(a, b)
            && !this.isBeforeDay(a, b);
    };

    isAfterDay(a, b) {
        return this.areMomentDates(a, b)
            && !this.isBeforeDay(a, b)
            && !this.isSameDay(a, b);
    }

    isBeforeDay = (a, b) => {
        if (!this.areMomentDates(a, b)) return false;

        const aYear = a.year();
        const aMonth = a.month();

        const bYear = b.year();
        const bMonth = b.month();

        const isSameYear = aYear === bYear;
        const isSameMonth = aMonth === bMonth;

        if (isSameYear && isSameMonth) return a.date() < b.date();
        if (isSameYear) return aMonth < bMonth;
        return aYear < bYear;
    };

    isSameDay = (a, b) => {
        return this.areMomentDates(a, b)
            && a.date() === b.date()
            && a.month() === b.month()
            && a.year() === b.year();
    }
}