import axiosInterface from '../../axios/axios-instance';
import {toastError, toastSuccess} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';
import _ from 'lodash';

const AUTH0_TENANT_ENDPOINT = '/api/v1/auth0-tenant';

class Auth0TenantService {

    getAll() {
        return axiosInterface.get(AUTH0_TENANT_ENDPOINT)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading auth0 tenants. ${error}`);
                throw error;
            });
    }

    create(auth0Tenant) {
        return axiosInterface.post(AUTH0_TENANT_ENDPOINT, auth0Tenant)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when creating an auth0 tenant. ${error}`);
                throw error;
            });
    }

    delete(id) {
        return axiosInterface.delete(`${AUTH0_TENANT_ENDPOINT}/${id}`)
            .then(() => {
                toastSuccess({statusMessage: 'auth0Tenant:auth0Tenant.succe' +
                        'ssfully.deleted'});
            })
            .catch(error => {
                const label = _.get(error, 'response.data.message', error);
                toastError({statusMessage: 'common:common.an.error.occurred', label: label});
                loggingUtil.error(`An error has occurred when deleting Auth0 tenant: ${error.response.data.message}`);
                throw error;
            });
    }
}

const auth0TenantService = new Auth0TenantService()
export default auth0TenantService;