import BaseStore from '../base.store';
import {makeObservable} from 'mobx';
import prpcService from './product-rate-plan-charge.service';

class ProductBundlesDataProductStore extends BaseStore {

    constructor() {
        super();

        makeObservable(this, {});

        this.createInitialState();
    }

    createDataProduct = (productBundleId, productRatePlanId) => (formData) => {
        let charge = {};

        if (formData) {
            charge = {
                productId: productBundleId,
                productRatePlanId: productRatePlanId,
                name: formData.productName,
                chargeCode: formData.productCode,
                unitPrice: formData.productPrice,
                currencyIsoCode: formData.currency,
                productChargeCategory: formData.taxCategory
            };
        }

        return prpcService.createPRPC(charge);
    };

    updateDataProduct = (prpcSalesforceId) => (prpc) => {
        if (prpc) {
            prpc.id = prpcSalesforceId;
        }

        return prpcService.updatePRPC(prpc);
    };

    deleteDataProducts = (salesforceIds) => {
        return prpcService.deletePRPC(salesforceIds);
    };
}

const productBundlesDataProductStore = new ProductBundlesDataProductStore()
export default productBundlesDataProductStore;