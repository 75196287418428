import React, {useEffect} from 'react';
import RowDetails from '../../../../../../components/row-details/RowDetails';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import Loader from '../../../../../../components/loader/index';
import {Button} from 'semantic-ui-react';
import {useParams} from 'react-router';
import {UserOverviewModals} from './sub-components/modals';
import rowDetailsConfig from './user-overview.row-details-config';

let UserOverview = (props) => {
    const {
        t, accountNameStore, usersOverviewUIStore, userStore
    } = props;

    const params = useParams();

    useEffect(() => {
        return () => usersOverviewUIStore.reset();
    }, [usersOverviewUIStore]);

    useEffect(() => {
        userStore.retrieveUser(params.userId);
        return () => userStore.reset();
    }, [userStore, params]);

    return (
        <div className="content sticky-scroll">
            <UserOverviewModals/>

            <div className="section__full--width--padding">
                <div className="section__columns">
                    <div className="section__column grid-12">
                        <h2 className="mt-4">{t('user:user.overview')}</h2>
                        <div className="button-box my-2">
                            <Button className="button__primary"
                                    disabled={userStore.isLoading || userStore.isExternallyManged()}
                                    onClick={usersOverviewUIStore.openEditUserModal}>
                                {t('common:common.edit')}
                            </Button>
                            <Button
                                disabled={userStore.isLoading || !userStore.user.transferable
                                || accountNameStore.isLoading || userStore.isExternallyManged()}
                                onClick={usersOverviewUIStore.openTransferUserModal}>
                                {t('common:common.transfer')}
                            </Button>
                        </div>
                        {
                            userStore.isLoading
                                ? <Loader/>
                                : <RowDetails dataObject={userStore.user} columnConfig={rowDetailsConfig}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(
    'accountNameStore',
    'userStore',
    'usersOverviewUIStore'
)(withTranslation(
    'user'
)(observer(UserOverview)));