import axiosInterface from '../../axios/axios-instance';
import {toastError, toastSuccess} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const ACCOUNT_ENDPOINT = '/api/v1/accounts';
const ACCOUNT_BILLING_ENDPOINT = `${ACCOUNT_ENDPOINT}/billing`;
const ACCOUNT_NAMES_ENDPOINT = `${ACCOUNT_ENDPOINT}/names`;

class AccountService {

    archiveAccount(salesforceId, archiveReason) {
        return axiosInterface.delete(`${ACCOUNT_ENDPOINT}/${salesforceId}`, {data: archiveReason})
            .then(() => {
                toastSuccess({statusMessage: "account:account.successfully.archived"});
            })
            .catch(error => {
                toastError({statusMessage: "common:common.an.error.occurred", label: error});
                loggingUtil.error(`An error has occurred : ${error}`);
                throw error;
            });
    }

    createAccount(account) {
        return axiosInterface.post(ACCOUNT_ENDPOINT, account)
            .catch(error => {
                loggingUtil.error(`An error has occurred when creating account: ${error}`);
                throw error;
            });
    }

    retrieveAccountBillingDetails(accountId) {
        return axiosInterface.get(`${ACCOUNT_BILLING_ENDPOINT}/${accountId}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading account billing details. Id: [${accountId}]: ${error}`);
                throw error;
            });
    }

    retrieveDetailedAccount(accountId) {
        return axiosInterface.get(`${ACCOUNT_ENDPOINT}/${accountId}/detailed`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading account. Id: [${accountId}]: ${error}`);
                throw error;
            });
    }

    getPageableAccounts(params) {
        return axiosInterface.get(ACCOUNT_ENDPOINT, {params})
            .then(response => {
                return response;
            }).catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading accounts: ${error}`);
                throw error;
            });
    }

    updateAccount(accountId, account) {
        return axiosInterface.put(`${ACCOUNT_ENDPOINT}/${accountId}`, account)
            .catch(error => {
                loggingUtil.error(`An error has occurred when updating account. Id: [${accountId}]: ${error}`);
                throw error;
            });
    }

    getAccountNames = () => {
        return axiosInterface.get(ACCOUNT_NAMES_ENDPOINT)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading account names. ${error}`);
                throw error;
            });
    };
}

const accountService = new AccountService()
export default accountService;