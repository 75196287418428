import {action, makeObservable, observable} from 'mobx';
import BaseStore from '../base.store';
import adminUserService from './admin-user.service';

class AdminUserStore extends BaseStore {
    isLoading = false;
    adminUsers;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setAdminUsers([]);

        makeObservable(this, {
            isLoading: observable,
            adminUsers: observable,
            setIsLoading: action,
            setAdminUsers: action,
            createAdminUser: action,
            deleteAdminUsers: action,
            loadAdminUsers: action
        });

        super.createInitialState();
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setAdminUsers(adminUsers) {
        this.adminUsers = adminUsers;
    }

    createAdminUser = (adminUser) => {
        return adminUserService.postAdminUser(adminUser);
    };

    deleteAdminUsers = (users) => {
        return adminUserService.deleteAdminUsers(users);
    };

    doesUserHaveAdminUIAccess = (sourceUserId) => {
        return adminUserService.doesUserHaveAdminUIAccess(sourceUserId);
    };

    loadAdminUsers() {
        this.setIsLoading(true);

        return adminUserService.getAdminUsers()
            .then(({data}) => {
                this.setAdminUsers(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    }
}

const adminUserStore = new AdminUserStore()
export default adminUserStore;