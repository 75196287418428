import {dateTimeUtil} from '../../../utils';

const config = [
    {
        headerKey: 'common.start.date',
        prop: 'startDate',
        transform: (startDate) => dateTimeUtil.toDisplayDate(startDate)
    },
    {
        headerKey: 'common.end.date',
        prop: 'endDate',
        transform: (endDate) => dateTimeUtil.toDisplayDate(endDate)
    },
    {
        headerKey: 'common.created.date',
        prop: 'createdDate',
        transform: (createdDate) => dateTimeUtil.toDisplayDateTime(createdDate),
    },
    {
        headerKey: 'common.created.by',
        prop: 'createdBy'
    }
];
export default config;