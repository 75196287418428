import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {ActiveAccountUsers} from './sub-components/active-account-users';
import {ArchivedAccountUsers} from './sub-components/archived-account-users';
import {useParams} from 'react-router';

let AccountUsers = (props) => {
    const {
        t, accountStore, headerUIStore, userStore
    } = props;

    const params = useParams();

    useEffect(() => {
        accountStore.retrieveAccount(params.accountId)
            .then(() => headerUIStore.setTitleKey(accountStore.account.name));
    }, [accountStore, headerUIStore, params]);

    return (
        <Tabs className="content sticky-scroll">
            <TabList>
                <Tab disabled={userStore.isLoading}>{t('user.users')}</Tab>
                <Tab disabled={userStore.isLoading}>{t('user.archived.users')}</Tab>
            </TabList>
            <TabPanel>
                <ActiveAccountUsers/>
            </TabPanel>
            <TabPanel>
                <ArchivedAccountUsers/>
            </TabPanel>
        </Tabs>
    );
};

export default inject(
    'accountStore',
    'userStore',
    'headerUIStore'
)(withTranslation(
    'user'
)(observer(AccountUsers)));