import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import EncModal from '../../../../../../../../components/enc-modal';
import {EditAccountUser} from '../../../../sub-components/active-account-users/sub-components/modals/edit-user';
import {TransferUser} from '../transfer-user';
import {useNavigate, useParams} from 'react-router';

const ACTIVE = 'ACTIVE';

let UserOverviewModals = (props) => {
    const {
        accountStore, accountNameStore, connectionInformationStore, usersOverviewUIStore, userStore
    } = props;

    const params = useParams();
    const navigate =  useNavigate();

    useEffect(() => {
        accountStore.retrieveAccount(params.accountId);
    }, [accountStore, params]);

    useEffect(() => {
        connectionInformationStore.loadConnectionInformations(params.accountId);
    }, [connectionInformationStore, params]);

    useEffect(() => {
        accountNameStore.loadAccountNames();
        return () => accountNameStore.reset();
    }, [accountNameStore]);

    function editUserSuccess() {
        userStore.retrieveUser(params.userId);
        usersOverviewUIStore.closeEditUserModal();
    }

    function transferUserSuccess() {
        usersOverviewUIStore.closeTransferUserModal();
        let accountUsersPath = `/accounts/${params.accountId}/users`;
        navigate(accountUsersPath);
    }

    return (
        <div>
            <EncModal
                isOpen={usersOverviewUIStore.isEditUserModalOpen}
                closeModal={usersOverviewUIStore.closeEditUserModal}>
                <EditAccountUser closeFunc={usersOverviewUIStore.closeEditUserModal}
                                 submitFunc={userStore.updateUser(userStore.user.accountId, userStore.user.salesforceId)}
                                 onSuccess={editUserSuccess}
                                 connectionInfo={connectionInformationStore.connectionInformations}
                                 connectionOptions={connectionInformationStore.connectionInformationOptions}
                                 disableActive={accountStore.account.status !== ACTIVE}
                                 initialValues={userStore.editUserFields}/>
            </EncModal>

            <EncModal
                isOpen={usersOverviewUIStore.isTransferUserModalOpen}
                closeModal={usersOverviewUIStore.closeTransferUserModal}>
                <TransferUser closeFunc={usersOverviewUIStore.closeTransferUserModal}
                              submitFunc={userStore.transferUser(userStore.user.salesforceId)}
                              onSuccess={transferUserSuccess}
                              accountsOptions={accountNameStore.accountNameOptions(userStore.user.accountId)}/>
            </EncModal>
        </div>
    );
};

export default inject(
    'accountStore',
    'accountNameStore',
    'connectionInformationStore',
    'usersOverviewUIStore',
    'userStore'
)(withTranslation(
    'user'
)(observer(UserOverviewModals)));