import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from react-dom/client
import App from './app';
import {Auth0Provider} from "@auth0/auth0-react";
import 'normalize.css/normalize.css';
import './styles/base.scss';
import './styles/index.scss';
import auth0Config from './auth0-config';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: auth0Config.audience,
            scope: 'openid profile email'
        }}
        useRefreshTokens={true}
    >
        <App />
    </Auth0Provider>
);
