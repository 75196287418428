import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import {Button} from 'semantic-ui-react';
import TableSelect from '../../../../../components/table-select/index';
import StickyWrapper from '../../../../../components/wrappers/index';
import Alert from '../../../../../components/alert/index';
import {Trans, withTranslation} from 'react-i18next';
import columnConfig from './assign-products.column-config';

class AssignProducts extends React.Component {

    componentDidMount() {
        this.props.productBundleArrayStore.loadProductBundles();
    }

    componentWillUnmount() {
        this.props.productBundleArrayStore.reset();
        this.props.assignProductsUIStore.reset();
    }

    setSelectedProductBundles = selectedProductBundles => {
        this.props.assignProductsUIStore.selectedProductBundles = selectedProductBundles;
    };

    assignProductBundlesToAccount = () => {
        const isValid = this.props.assignProductsUIStore.validate(this.props.productBundleArrayStore.assignedProductBundles(this.props.assignedProductCodes));
        if (isValid) {
            this.props.accountProductStore.saveAccountProducts(
                this.props.assignProductsUIStore.selectedProductBundles,
                this.props.accountId,
                this.props.onSuccess
            );
        }
    };

    createErrorAlertContent() {

        const {validationError} = this.props.assignProductsUIStore;
        const conflicts = [];
        let index = 0;

        validationError.conflictsSummary
        && validationError.conflictsSummary.forEach((conflictingChargeCodes, productName) => {
            const element = (
                <div className="key__value--row" key={index}>
                    <label className="flex-grow-2">{productName}</label>
                    <label className="value">{conflictingChargeCodes}</label>
                </div>
            );
            conflicts.push(element);
            index++;
        });

        return (
            <div>
                <label className="attention">
                    <Trans i18nKey={validationError.errorTranslationKey}>
                        {{selectedBundleName: validationError.selectedBundleName}}
                    </Trans>
                </label>
                {conflicts}
            </div>
        );
    }

    render() {

        const {t, productBundleArrayStore, assignProductsUIStore} = this.props;
        return (
            <div className="section__full--width">
                <h1 className="text-center">{t('account.assign.products')}</h1>
                <Alert
                    openAlert={assignProductsUIStore.hasValidationErrors()}
                    closeAlert={assignProductsUIStore.clearValidationError}
                    alertTitle={t('account.cannot.assign.product')}
                    alertContent={this.createErrorAlertContent()}
                />
                <TableSelect
                    multiSelect={true}
                    columnConfig={columnConfig}
                    rowData={productBundleArrayStore.unassignedProductBundles(this.props.assignedProductCodes)}
                    loading={productBundleArrayStore.isLoading}
                    handleRowsSelection={this.setSelectedProductBundles}
                    isSticky={true}
                    stickyTopOffset={55}
                    stickyTopBarOffset={0}
                    dataName={t('account.products')}
                    rowId="salesforceId"
                />
                <StickyWrapper stickyFooter={true} className="sticky__section--footer">
                    <div className="section__full--width--padding">
                        <div className="button-box">
                            <Button onClick={this.props.onClose}>{t('common:common.cancel')}</Button>
                            <Button className="button__primary" onClick={this.assignProductBundlesToAccount}>
                                {t('common:common.save')}
                            </Button>
                        </div>
                    </div>
                </StickyWrapper>
            </div>
        );
    }
}

AssignProducts.propTypes = {
    assignedProductCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    accountId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default inject(
    'accountProductStore',
    'productBundleArrayStore',
    'assignProductsUIStore'
)(withTranslation(
    'account'
)(observer(AssignProducts)));