import {dateTimeUtil} from '../../../../../../utils';

const config = [
    {
        headerKey: 'user:user.username',
        prop: 'username',
        width: 400,
    },
    {
        headerKey: 'user:user.login.date',
        prop: 'loginDate',
        width: 200,
        type: 'datetime',
        transform: accountUser => accountUser.loginDate ? dateTimeUtil.toDisplayDateTime(accountUser.loginDate) : null
    }
];
export default config;