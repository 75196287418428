import React from 'react';
import {Provider} from 'mobx-react';
import {configure} from 'mobx';

import aboutStore from '../domain/about/about.store';
import accountStore from '../domain/account/account.store';
import accountsUIStore from '../../pages/accounts/accounts.ui-store';
import accountProfilesUIStore from '../../pages/accounts/pages/account-profiles/account-profiles.ui-store';
import accountTransactionsUIStore from '../../pages/accounts/pages/account-transactions/account-transactions.ui-store';
import accountProductStore from '../domain/accountproduct/account-product.store';
import accountProductsUIStore from '../../pages/accounts/pages/account-products/account-products.ui-store';
import accountBillingRunsUIStore from '../../pages/accounts/pages/account-billing-runs/account-billing-runs.ui-store';
import accountBillingRunStore from '../domain/accountbillingrun/account-billing-run.store';
import accountBillingRunDetailsUIStore
    from '../../pages/accounts/pages/account-billing-runs/account-billing-run-details/account-billing-run-details.ui-store';
import accountBillingRunEntryStore from '../domain/accountbillingrun/account-billing-run-entry.store';
import accountNameStore from '../domain/account/account-name.store';
import accountPolicyRunHistoryUIStore
    from '../../pages/accounts/pages/account-policy-run-history/account-policy-run-history.ui-store';
import activeAccountUsersUIStore
    from '../../pages/accounts/pages/account-users/sub-components/active-account-users/active-account-users.ui-store';
import activeUsersUIStore from '../../pages/users/active-users/active-users.ui-store';
import adminUserStore from '../domain/adminuser/admin-user.store';
import adminUsersUIStore from '../../pages/settings/admin-users/admin-users.ui-store';
import archivedAccountUsersUIStore
    from '../../pages/accounts/pages/account-users/sub-components/archived-account-users/archived-account-users.ui-store';
import archivedUsersUIStore from '../../pages/users/archived-users/archived-users.ui-store';
import assignProductsUIStore
    from '../../pages/accounts/pages/account-products/assign-products/assign-products.ui-store';
import auth0TenantStore from '../domain/auth0tenant/auth0-tenant.store';
import auth0TenantUIStore from '../../pages/settings/auth0-tenants/auth0-tenants.ui-store';
import authenticationStore from '../domain/authentication/authentication.store';
import billingRunsUIStore from '../../pages/billing-runs/billing-runs.ui-store';
import billingRunStore from '../domain/billingrun/billing-run.store';
import billingRunEntryStore from '../domain/billingrun/billing-run-entry.store';
import billingRunsDetailsUIStore from '../../pages/billing-runs/billing-run-details/billing-run-details.ui-store';
import connectionInformationStore from '../domain/connectioninformation/connection-information.store';
import encDateRangePickerUIStore from '../../components/date-range-picker/enc-date-range-picker.ui-store.js';
import headerUIStore from '../../app/header/header.ui-store';
import policyRunHistoryStore from '../domain/policyrunhistory/policy-run-history.store';
import policyRunHistoryUIStore from '../../pages/policy-run-history/policy-run-history.ui-store';
import productBundleStore from '../domain/product/product-bundle.store';
import productBundleArrayStore from '../domain/product/product-bundle.array.store';
import productBundlesUIStore from '../../pages/product-bundles/product-bundles.ui-store';
import productBundleDataProductStore from '../domain/productrateplancharge/product-bundles-data-product.store';
import productBundleDataProductsUIStore
    from '../../pages/product-bundles/pages/product-bundle-data-products/product-bundles-data-products.ui-store';
import profileStore from '../domain/profile/profile.store';
import profilesUIStore from '../../pages/profiles/profiles.ui-store';
import subscriptionTypeStore from '../domain/subscriptiontype/subscription-type.store';
import transactionStore from '../domain/transaction/transaction.store';
import transactionsUIStore from '../../pages/transactions/transactions.ui-store';
import userStore from '../domain/user/user.store';
import usersOverviewUIStore from '../../pages/accounts/pages/account-users/pages/user-overview/UserOverview.ui-store';
import userProfilesUIStore from '../../pages/accounts/pages/account-users/pages/user-profiles/user-profiles.ui-store';
import userPolicyRunHistoryUIStore
    from '../../pages/accounts/pages/account-users/pages/user-policy-run-history/user-policy-run-history.ui-store';
import userTransactionsUIStore
    from '../../pages/accounts/pages/account-users/pages/user-transactions/user-transactions.ui-store';
import userLoginHistoryStore from '../domain/userloginhistory/user-login-history.store';
import userLoginHistoryUIStore
    from '../../pages/accounts/pages/account-users/pages/user-login-history/user-login-history.ui-store';

const mobxConfig = {
    enforceActions: 'observed'
};
configure(mobxConfig);

const stores = {
    aboutStore,
    accountStore,
    accountsUIStore,
    accountProfilesUIStore,
    accountTransactionsUIStore,
    accountProductStore,
    accountProductsUIStore,
    accountBillingRunStore,
    accountBillingRunsUIStore,
    accountBillingRunEntryStore,
    accountBillingRunDetailsUIStore,
    accountNameStore,
    accountPolicyRunHistoryUIStore,
    activeAccountUsersUIStore,
    activeUsersUIStore,
    adminUserStore,
    adminUsersUIStore,
    archivedAccountUsersUIStore,
    archivedUsersUIStore,
    assignProductsUIStore,
    auth0TenantStore,
    auth0TenantUIStore,
    authenticationStore,
    billingRunsUIStore,
    billingRunStore,
    billingRunEntryStore,
    billingRunsDetailsUIStore,
    connectionInformationStore,
    encDateRangePickerUIStore,
    headerUIStore,
    policyRunHistoryStore,
    policyRunHistoryUIStore,
    productBundleStore,
    productBundleArrayStore,
    productBundlesUIStore,
    productBundleDataProductStore,
    productBundleDataProductsUIStore,
    profileStore,
    profilesUIStore,
    subscriptionTypeStore,
    transactionStore,
    transactionsUIStore,
    userStore,
    userPolicyRunHistoryUIStore,
    userProfilesUIStore,
    userTransactionsUIStore,
    userLoginHistoryStore,
    userLoginHistoryUIStore,
    usersOverviewUIStore
};

window.__APP_STORES__ = stores;

export default function MobxContextProvider({children}) {
    return <Provider {...stores}>{children}</Provider>;
}