import {sentenceCase} from 'sentence-case';
import {dateTimeUtil} from '../../../utils';

const accountsColumnConfig = [
    {
        headerKey: 'account:account.name',
        prop: 'name'
    },
    {
        headerKey: 'account:account.subscription.status',
        prop: 'status',
        transform: account => sentenceCase(account.status),
        width: 175
    },
    {
        headerKey: 'account:account.subscription.type',
        prop: 'subscriptionType',
        transform: account => (account.subscriptionType && account.subscriptionType !== '') ? account.subscriptionType : 'No subscription',
        width: 195
    },
    {
        headerKey: 'account:account.active.users',
        prop: 'numActiveUsers',
        width: 135,
        type: 'number'
    },
    {
        headerKey: 'common.date.modified',
        prop: 'lastModifiedDate',
        width: 200,
        type: 'datetime',
        transform: account => {
            if (account.lastModifiedDate) {
                return dateTimeUtil.toDisplayDateTime(account.lastModifiedDate);
            }
            return null;
        }
    },
    {
        headerKey: 'common.last.modified.by',
        prop: 'lastModifiedBy'
    }
];
export default accountsColumnConfig;