import {action, computed, makeObservable, observable} from 'mobx';
import BaseStore from '../base.store';
import subscriptionTypeService from './subscription-type.service';

class SubscriptionTypeStore extends BaseStore {
    isLoading = false;
    subscriptionTypes;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setSubscriptionTypes([]);

        makeObservable(this, {
            isLoading: observable,
            subscriptionTypes: observable,
            setIsLoading: action,
            setSubscriptionTypes: action,
            loadSubscriptionTypes: action,
            subscriptionTypeOptions: computed
        });

        this.createInitialState();
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setSubscriptionTypes(subscriptionTypes) {
        this.subscriptionTypes = subscriptionTypes;
    }

    loadSubscriptionTypes = () => {
        if (!(this.subscriptionTypes instanceof Array) || this.subscriptionTypes.length === 0) {
            this.setIsLoading(true);

            subscriptionTypeService.retrieveSubscriptionTypes()
                .then(({data}) => {
                    this.setSubscriptionTypes(data);
                })
                .finally(() => {
                    this.setIsLoading(false);
                });
        }
    };

    get subscriptionTypeOptions() {
        if (!(this.subscriptionTypes instanceof Array)) {
            return [];
        }

        return this.subscriptionTypes.map(subscriptionType => {
            return {
                key: subscriptionType,
                text: subscriptionType,
                value: subscriptionType
            };
        }).sort((s1, s2) => s1.text.localeCompare(s2.text));
    }
}

const subscriptionTypeStore = new SubscriptionTypeStore()
export default subscriptionTypeStore;