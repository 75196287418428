import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const BILLING_RUN_ENDPOINT = '/api/v1/billing-run';

class BillingRunService {

    getDetailedBillingRun(billingRunId) {
        return axiosInterface.get(`${BILLING_RUN_ENDPOINT}/${billingRunId}/detailed`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading detailed billing run: ${error}`);
                throw error;
            });
    };

    getBillingRuns(params) {
        return axiosInterface.get(BILLING_RUN_ENDPOINT, {params})
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading billing runs: ${error}`);
                throw error;
            });
    };

    executeBillingRun(billingRun) {
        return axiosInterface.post(BILLING_RUN_ENDPOINT, billingRun)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when executing billing run: ${error}`);
                throw error;
            });
    };

    getBillingRunCsv(billingRunId) {
        return axiosInterface.get(`${BILLING_RUN_ENDPOINT}/${billingRunId}/csv`)
            .then(response => {
                return response;
            }).catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading transactions: ${error}`);
                throw error;
            });
    };
}

const billingRunService = new BillingRunService()
export default billingRunService;