import React from 'react';

function errorMessage({error}) {

    if (!error) {
        return null;
    }
    return (
        <label className="validation-error attention">
            {error}
        </label>
    );

}

export default errorMessage
