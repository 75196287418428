import BaseStore from '../base.store';
import {action, computed, makeObservable, observable} from 'mobx';
import auth0TenantService from './auth0-tenant.service';

class Auth0TenantStore extends BaseStore {
    isLoading = false;
    auth0Tenants;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setAuth0Tenants([]);

        makeObservable(this, {
            isLoading: observable,
            auth0Tenants: observable,
            setIsLoading: action,
            setAuth0Tenants: action,
            load: action,
            auth0TenantsOptions: computed
        });

        this.createInitialState();
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    setAuth0Tenants(auth0Tenants) {
        this.auth0Tenants = auth0Tenants;
    }

    load = () => {
        this.setIsLoading(true);

        auth0TenantService.getAll()
            .then(({data}) => {
                this.setAuth0Tenants(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    create = (auth0Tenant) => {
        return auth0TenantService.create(auth0Tenant);
    };

    delete = (id) => {
        return auth0TenantService.delete(id);
    };

    get auth0TenantsOptions() {
        if (!(this.auth0Tenants instanceof Array)) {
            return [];
        }

        return this.auth0Tenants.map(auth0Tenant => {
            return {
                key: auth0Tenant.id,
                text: auth0Tenant.name,
                value: auth0Tenant.id
            };
        }).sort((s1, s2) => s1.text.localeCompare(s2.text));
    }
}

const auth0TenantStore = new Auth0TenantStore()
export default auth0TenantStore;