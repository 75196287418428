import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const TRANSACTION_ENDPOINT = '/api/v1/transactions';

class TransactionService {

    getPageableTransactions = (params) => {
        return axiosInterface.get(`${TRANSACTION_ENDPOINT}`, {params})
            .then(response => {
                return response;
            }).catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading transactions: ${error}`);
                throw error;
            });
    };

    getTransactionCSV = (params) => {
        return axiosInterface.get(`${TRANSACTION_ENDPOINT}/csv`, {params})
            .then(response => {
                return response;
            });
    };
}

const transactionService = new TransactionService()
export default transactionService;