import * as Yup from 'yup';
import {getI18n} from 'react-i18next';

const t = (msg, values) => getI18n().t(msg, values);

function uniqueProductCodeValidator() {

    Yup.addMethod(Yup.string, 'uniqueProductCode', function (existingProductCodes, excludedProductCode) {
        return this.test('uniqueProductCodeTest', 'Invalid password', function (productCode) {
            const {path, createError} = this;

            if (productCode && excludedProductCode && (productCode.toLowerCase() === excludedProductCode.toLowerCase())) {
                return true;
            }

            return !productCode || existingProductCodes.some(existingProductCode => existingProductCode.toLowerCase() === productCode.toLowerCase())
                ? createError({path, message: t('productBundles:product.bundle.product.code.must.be.unique')})
                : true;
        });
    });
}
export default uniqueProductCodeValidator;