import {action, computed, makeObservable, observable} from 'mobx';
import BaseStore from '../base.store';
import productService from './product.service';
import _ from 'lodash';

class ProductBundleStore extends BaseStore {
    productBundle;
    isLoading = false;

    constructor() {
        super();
        this.setProductBundle({});
        this.setIsLoading(false);

        makeObservable(this, {
            productBundle: observable,
            isLoading: observable,
            setProductBundle: action,
            setIsLoading: action,
            loadProductBundle: action,
            formValues: computed,
            charges: computed,
            chargeCodes: computed,
            productRatePlanId: computed,
            salesforceId: computed
        });

        super.createInitialState();
    }

    setProductBundle = (productBundle) => {
        this.productBundle = productBundle;
    };

    setIsLoading = (isLoading) => {
        this.isLoading = isLoading;
    };

    loadProductBundle = (productBundleId) => {
        this.setIsLoading(true);

        return productService.getProductById(productBundleId)
            .then(({data}) => {
                this.setProductBundle(data);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    updateProductBundle = (productBundleId) => (productBundle) => {
        if (productBundle) {
            productBundle.salesforceId = productBundleId;
        }

        return productService.updateProduct(productBundle);
    };

    createProductBundle = (productBundle) => {
        return productService.createProduct(productBundle);
    };

    get formValues() {
        return {
            name: this.productBundle.name,
            productCode: this.productBundle.productCode,
            description: this.productBundle.description
        };
    }

    get charges() {
        return _.isEmpty(this.productBundle) || _.isEmpty(this.productBundle.charges) ? [] : this.productBundle.charges;
    }

    get chargeCodes() {
        return this.charges.map(charge => charge.chargeCode) ;
    }

    get productRatePlanId() {
        return this.charges.length > 0 ? this.charges[0].salesforceId : undefined;
    }

    get salesforceId() {
        return _.isEmpty(this.productBundle) || _.isEmpty(this.productBundle.bundle) ? undefined : this.productBundle.bundle.salesforceId;
    }
}

const productBundleStore = new ProductBundleStore()
export default productBundleStore;