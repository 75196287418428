import BaseStore from '../base.store';
import {action, makeObservable, observable} from 'mobx';
import accountBillingRunService from './account-billing-run.service';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

class AccountBillingRunEntryStore extends BaseStore {
    isLoading = false;
    corporateProfiles;
    individualProfiles;
    transactions;

    constructor() {
        super();
        this.setIsLoading(false);
        this.setCorporateProfiles([]);
        this.setIndividualProfiles([]);
        this.setTransactions([]);

        makeObservable(this, {
            isLoading: observable,
            corporateProfiles: observable,
            individualProfiles: observable,
            transactions: observable,
            setIsLoading: action,
            setCorporateProfiles: action,
            setIndividualProfiles: action,
            setTransactions: action,
            loadByEntryType: action
        });

        super.createInitialState();
    }

    setIsLoading = (isLoading) => {
        this.isLoading = isLoading;
    };

    setCorporateProfiles = (corporateProfiles) => {
        this.corporateProfiles = corporateProfiles;
    };

    setIndividualProfiles = (individualProfiles) => {
        this.individualProfiles = individualProfiles;
    };

    setTransactions = (transactions) => {
        this.transactions = transactions;
    };

    getByEntryType = (entryType) => {
        switch (entryType) {
            case 'CORPORATE':
                return this.corporateProfiles;
            case 'INDIVIDUAL':
                return this.individualProfiles;
            case 'TRANSACTION':
                return this.transactions;
            default:
                return [];
        }
    };

    loadAllBillingRunEntries = (billingRunId) => {
        this.setIsLoading(true);
        let allPromises = [];

        allPromises.push(accountBillingRunService.getAccountBillingRunCorporateProfiles(billingRunId));
        allPromises.push(accountBillingRunService.getAccountBillingRunIndividualProfiles(billingRunId));
        allPromises.push(accountBillingRunService.getAccountBillingRunTransactions(billingRunId));

        Promise.all(allPromises)
            .then(([{data: corporateProfiles}, {data: individualProfiles}, {data: transactions}]) => {
                this.setCorporateProfiles(corporateProfiles);
                this.setIndividualProfiles(individualProfiles);
                this.setTransactions(transactions);
            })
            .finally(() => {
                this.setIsLoading(false);
            });
    };

    loadByEntryType = (billingRunId, entryType) => {
        this.setIsLoading(true);
        let promise;

        switch (entryType) {
            case 'CORPORATE':
                promise = accountBillingRunService.getAccountBillingRunCorporateProfiles(billingRunId)
                    .then(({data}) => this.setCorporateProfiles(data));
                break;
            case 'INDIVIDUAL':
                promise = accountBillingRunService.getAccountBillingRunIndividualProfiles(billingRunId)
                    .then(({data}) => this.setIndividualProfiles(data));
                break;
            case 'TRANSACTION':
                promise = accountBillingRunService.getAccountBillingRunTransactions(billingRunId)
                    .then(({data}) => this.setTransactions(data));
                break;
            default:
                promise = new Promise((resolve, reject) => {
                    toastError({statusMessage: 'common:common.an.error.occurred'});
                    loggingUtil.error('Invalid entry type given when loading account billing run entry');
                    return reject('Invalid entry type');
                });
        }

        return promise.finally(() => {
            this.setIsLoading(false);
        });
    };
}

const accountBillingRunEntryStore = new AccountBillingRunEntryStore()
export default accountBillingRunEntryStore;