import React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from 'mobx-react';
import {Button} from 'semantic-ui-react';

import columnConfig from './policy-run-history.column-config';
import EncDateRangePicker from '../../components/date-range-picker/index';
import NoResultsMessage from '../../components/table-select/NoResultsMessage';
import PageableTableSelect from '../../components/table-select/pageable/index';

class PolicyRunHistory extends React.Component {

    constructor(props) {
        super(props);

        this.props.headerUIStore.setTitleKey('policyRunHistory:policy.run.history.title');
    }

    componentWillUnmount() {
        this.props.policyRunHistoryUIStore.reset();
    }

    getPageablePolicyRunHistory = (...params) => {
        this.props.policyRunHistoryStore.getPageablePolicyRunHistory(this.props.policyRunHistoryUIStore.dateRange)(...params);
    };

    exportToCSV = () => {
        this.props.policyRunHistoryStore.exportToCsv(
            this.props.policyRunHistoryUIStore.dateRange,
            this.tableSelectFilter
        );
    };

    handleTextFilterChange = filter => this.tableSelectFilter = filter;

    render() {
        const {t} = this.props;

        return (
            <div className="content sticky-scroll">
                <div className="section__full--width--padding mt-4">
                    <Button className="button__primary"
                            onClick={this.exportToCSV}>
                        {this.props.t('common:common.export.to.csv')}
                    </Button>
                </div>

                <PageableTableSelect
                    multiSelect={false}
                    selectable={false}
                    columnConfig={columnConfig}
                    fetchPageableData={this.getPageablePolicyRunHistory}
                    rowId="id"
                    isSticky={true}
                    stickyTopOffset={55}
                    stickyTopBarOffset={0}
                    dataName={t('policy.run.history.data.name')}
                    noDataText={this.renderNoResultsMessage()}
                    defaultColumnSorted="policyDate"
                    tableSelectRef={(tableSelectRef) => this.tableSelectRef = tableSelectRef}
                    handleTextFilterChange={this.handleTextFilterChange}
                    additionalFilteringComponents={this.renderAdditionalTableFilters()}
                />
            </div>
        );
    }

    renderAdditionalTableFilters() {
        const {policyRunHistoryUIStore} = this.props;

        return (
            <EncDateRangePicker
                startDate={policyRunHistoryUIStore.dateRange.startDate}
                endDate={policyRunHistoryUIStore.dateRange.endDate}
                handleRange={dateRange => {
                    policyRunHistoryUIStore.setDateRange(dateRange);
                    this.tableSelectRef.refreshTable();
                }}
            />
        );
    }

    renderNoResultsMessage() {
        const {policyRunHistoryUIStore} = this.props;

        return (
            <NoResultsMessage
                startDate={policyRunHistoryUIStore.dateRange.startDate}
                endDate={policyRunHistoryUIStore.dateRange.endDate}
            />
        );
    }
}

export default inject(
    'headerUIStore',
    'policyRunHistoryStore',
    'policyRunHistoryUIStore'
)(withTranslation(
    'policyRunHistory'
)(observer(PolicyRunHistory)));
