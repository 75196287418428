import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import AddDataProduct from './add-data-product/index';
import Loader from '../../../../components/loader/Loader';
import TableSelect from '../../../../components/table-select/TableSelect';
import columnConfig from './product-bundles-data-products.column-config';
import EditDataProduct from './edit-data-product/index';
import DataProductDetails from './data-product-details/index';
import EncModal from '../../../../components/enc-modal/index';
import EncConfirm from '../../../../components/enc-confirm/EncConfirm';
import ConfirmMessage from '../../../../components/enc-confirm/ConfirmMessage';
import { useParams } from 'react-router-dom';
import { MobXProviderContext } from 'mobx-react';

const ProductBundleDataProducts = observer(() => {
    const { headerUIStore, productBundleStore, productBundleDataProductStore, productBundleDataProductsUIStore } = useContext(MobXProviderContext);
    const { productBundleId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        productBundleStore.loadProductBundle(productBundleId)
            .then(() => headerUIStore.setTitleKey(productBundleStore.productBundle.name));

        return () => {
            productBundleStore.reset();
            productBundleDataProductStore.reset();
            productBundleDataProductsUIStore.reset();
        };
    }, [productBundleId, headerUIStore, productBundleStore, productBundleDataProductStore, productBundleDataProductsUIStore]);

    const createDataProductSuccess = () => {
        productBundleStore.loadProductBundle(productBundleId);
        productBundleDataProductsUIStore.closeAddDataProductModal();
    };

    const updateDataProductSuccess = () => {
        productBundleStore.loadProductBundle(productBundleId);
        productBundleDataProductsUIStore.closeEditDataProductModal();
    };

    const handleRowDoubleClick = (selectedDataProduct) => {
        productBundleDataProductsUIStore.setSelectedDataProducts([selectedDataProduct]);
        productBundleDataProductsUIStore.openEditDataProductModal();
    };

    const deleteDataProducts = () => {
        productBundleDataProductStore.deleteDataProducts(productBundleDataProductsUIStore.selectedDataProducts.map(row => row.id))
            .then(() => {
                productBundleStore.loadProductBundle(productBundleId);
            })
            .finally(() => productBundleDataProductsUIStore.closeConfirmArchiveModal());
    };

    const renderTableButtons = () => {
        if (productBundleDataProductsUIStore.selectedDataProducts.length === 0) {
            return null;
        }

        return (
            <div>
                {
                    productBundleDataProductsUIStore.selectedDataProducts.length === 1 &&
                    <Button onClick={productBundleDataProductsUIStore.openDataProductDetailsModal}>
                        {t('common:common.details')}
                    </Button>
                }

                {
                    productBundleDataProductsUIStore.selectedDataProducts.length === 1 &&
                    <Button onClick={productBundleDataProductsUIStore.openEditDataProductModal}>
                        {t('common:common.edit')}
                    </Button>
                }

                <Button onClick={productBundleDataProductsUIStore.openConfirmArchiveModal}>
                    {t('common:common.delete')}
                </Button>
            </div>
        );
    };

    const renderModals = () => {
        return (
            <div>
                <EncModal isOpen={productBundleDataProductsUIStore.isDataProductDetailsModalOpen}
                          closeModal={productBundleDataProductsUIStore.closeDataProductDetailsModal}>
                    <DataProductDetails dataProduct={productBundleDataProductsUIStore.singleSelectedDataProduct}
                                        dataProductName={productBundleDataProductsUIStore.singleSelectedDataProduct.name}/>
                </EncModal>

                <EncModal isOpen={productBundleDataProductsUIStore.isAddDataProductModalOpen}
                          closeModal={productBundleDataProductsUIStore.closeAddDataProductModal}>
                    <AddDataProduct closeFunc={productBundleDataProductsUIStore.closeAddDataProductModal}
                                    submitFunc={productBundleDataProductStore.createDataProduct(productBundleStore.salesforceId, productBundleStore.productRatePlanId)}
                                    onSuccess={createDataProductSuccess}
                                    existingDataProducts={productBundleStore.charges}
                                    initialValues={{
                                        productPrice: 0,
                                        currency: 'GBP',
                                        taxCategory: 'NONE'
                                    }}/>
                </EncModal>

                <EncModal isOpen={productBundleDataProductsUIStore.isEditDataProductModalOpen}
                          closeModal={productBundleDataProductsUIStore.closeEditDataProductModal}>
                    <EditDataProduct closeFunc={productBundleDataProductsUIStore.closeEditDataProductModal}
                                     submitFunc={productBundleDataProductStore.updateDataProduct(productBundleDataProductsUIStore.singleSelectedDataProduct.id)}
                                     onSuccess={updateDataProductSuccess}
                                     initialValues={productBundleDataProductsUIStore.singleSelectedDataProduct}
                                     existingProductCodes={productBundleStore.chargeCodes}
                                     excludedProductCode={productBundleDataProductsUIStore.singleSelectedDataProduct.chargeCode}/>
                </EncModal>
            </div>
        );
    };

    const renderConfirm = () => {
        const deleteMessage = (
            <ConfirmMessage
                itemsArray={productBundleDataProductsUIStore.selectedDataProducts}
                itemProperty={'name'}
                itemKey={t('product.bundle.product')}
                itemsKey={t('product.bundle.products')}
                numItemsToDisplay={10}
                confirmMessageTranslationKey={'productBundles:product.bundle.delete.message'}
                confirmMessageLimitDisplayTranslationKey={'productBundles:product.bundle.delete.limit.display.message'}
            />
        );

        return (
            <EncConfirm
                openConfirm={productBundleDataProductsUIStore.isConfirmArchiveModalOpen}
                closeConfirm={productBundleDataProductsUIStore.closeConfirmArchiveModal}
                confirmFunc={deleteDataProducts}
                confirmButtonLabel={productBundleDataProductsUIStore.selectedDataProducts.length > 1 ?  t('product.bundle.delete.products') : t('product.bundle.delete.product')}
                confirmTitle={t('common:common.confirm.delete')}
                confirmMessage={deleteMessage}
            />
        );
    };

    if (Object.entries(productBundleStore.productBundle).length === 0) {
        return <Loader />;
    }

    return (
        <div className="content sticky-scroll">
            {renderModals()}
            {renderConfirm()}
            <div className="section__full--width--padding mt-4">
                <Button className="button__primary"
                        disabled={productBundleStore.productBundle.bundle.status !== 'Active'}
                        onClick={productBundleDataProductsUIStore.openAddDataProductModal}>
                    {t('common:common.new')}
                </Button>
            </div>

            <TableSelect
                multiSelect={true}
                columnConfig={columnConfig}
                rowData={productBundleStore.charges}
                loading={productBundleStore.isLoading}
                handleRowsSelection={productBundleDataProductsUIStore.setSelectedDataProducts}
                handleRowDoubleClick={handleRowDoubleClick}
                rowId="id"
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('common:common.data.products')}
                tableButtons={renderTableButtons()}
                defaultColumnSorted="lastModifiedDate"
            />
        </div>
    );
});

export default ProductBundleDataProducts;
