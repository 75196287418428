import {useState} from 'react';

function useRefreshTable(initialState) {

    const [refreshTable, setRefreshTable] = useState(() => !!initialState ? initialState : () => {
    });
    function customSetRefreshTable(newState) {

        const newRefreshTable = typeof newState === 'object' ? newState.refreshTable : newState;
        setRefreshTable(() => !!newRefreshTable ? newRefreshTable : () => {
        });

    }
    return [refreshTable, customSetRefreshTable];

}
export default useRefreshTable;
