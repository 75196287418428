import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {Button} from 'semantic-ui-react';
import EncModal from '../../components/enc-modal/EncModal';
import ExecuteBillingRun from '../accounts/pages/account-billing-runs/execute-billing-run/index';
import TableSelect from '../../components/table-select/TableSelect';
import columnConfig from '../accounts/pages/account-billing-runs/account-billing-runs.column-config';
import BillingRunDetails from './billing-run-details/index';
import moment from 'moment';
import {observe} from 'mobx';
import {dateTimeUtil} from '../../utils';
import EncDateRangePicker from '../../components/date-range-picker/index';

class BillingRuns extends React.Component {

    constructor(props) {
        super(props);
        this.props.headerUIStore.setTitleKey('menu:menu.billing.runs');
    }

    componentDidMount() {
        this.props.billingRunsUIStore.setExecuteBillingRunStartDate(moment().utc(false).subtract(1, 'month'));
        this.props.billingRunsUIStore.setExecuteBillingRunEndDate(moment().utc(false));

        this.loadBillingRuns();

        this.initialiseObserve();
    }

    initialiseObserve() {
        const listener = ({newValue, oldValue}) => {
            if (newValue
                && (!newValue.startDate.isSame(oldValue.startDate)
                    || !newValue.endDate.isSame(oldValue.endDate))) {

                this.loadBillingRuns();
            }
        };

        this.dateRangeDisposer = observe(
            this.props.billingRunsUIStore,
            'dateRange',
            listener
        );
    }

    componentWillUnmount() {
        this.props.billingRunStore.reset();
        this.props.billingRunsUIStore.reset();

        this.dateRangeDisposer();
    }

    loadBillingRuns = () => {
        this.props.billingRunStore.loadBillingRuns(
            this.props.billingRunsUIStore.dateRange.startDate,
            this.props.billingRunsUIStore.dateRange.endDate
        );
    };

    onSuccessExecuteBillingRun = () => {
        this.loadBillingRuns();
        this.props.billingRunsUIStore.closeNewBillingRun();
    };

    handleRowDoubleClick = (selectedBillingRuns) => {
        this.props.billingRunsUIStore.setSelectedBillingRuns([selectedBillingRuns]);
        this.props.billingRunsUIStore.openBillingRunDetails();
    };

    exportToCSV = () => {
        let selectedBillingRun = this.props.billingRunsUIStore.singleSelectedBillingRun;
        let accountName = this.props.billingRunsUIStore.singleSelectedBillingRun.createdBy;
        const formattedStartDate = dateTimeUtil.toDayMonthYearDate(selectedBillingRun.startDate);
        const formattedEndDate = dateTimeUtil.toDayMonthYearDate(selectedBillingRun.endDate);
        this.props.billingRunStore.generateCsv(
            selectedBillingRun.id,
            selectedBillingRun.name,
            accountName,
            formattedStartDate,
            formattedEndDate
        );
    };

    render() {
        const {t} = this.props;

        return (
            <div className="content sticky-scroll">
                {this.renderModals()}

                <div className="section__full--width--padding mt-4">
                    <Button className="button__primary"
                            onClick={this.props.billingRunsUIStore.openNewBillingRun}>
                        {t('common:common.new')}
                    </Button>
                </div>

                <TableSelect
                    multiSelect={true}
                    columnConfig={columnConfig}
                    rowData={this.props.billingRunStore.billingRuns}
                    loading={this.props.billingRunStore.isLoading}
                    handleRowsSelection={this.props.billingRunsUIStore.setSelectedBillingRuns}
                    handleRowDoubleClick={this.handleRowDoubleClick}
                    dataName={t('account.billing.runs')}
                    rowId="id"
                    tableButtons={this.renderTableButtons()}
                    isSticky={true}
                    stickyTopBarOffset={0}
                    stickyTopOffset={55}
                    defaultColumnSorted="createdDate"
                    additionalFilteringComponents={this.renderAdditionalTableFilters()}
                />
            </div>
        );
    }

    renderTableButtons() {
        const {t} = this.props;
        let length = this.props.billingRunsUIStore.selectedBillingRuns.length;

        return (
            <div>
                {
                    length === 1 &&
                    <Button onClick={this.props.billingRunsUIStore.openBillingRunDetails}>
                        {t('common:common.details')}
                    </Button>
                }
                {
                    length === 1 &&
                    <Button onClick={this.exportToCSV}>
                        {t('common:common.export.to.csv')}
                    </Button>
                }
            </div>
        );
    }

    renderModals() {
        return (
            <div>
                <EncModal
                    isOpen={this.props.billingRunsUIStore.isNewBillingRunModalOpen}
                    closeModal={this.props.billingRunsUIStore.closeNewBillingRun}>

                    <ExecuteBillingRun
                        closeFunc={this.props.billingRunsUIStore.closeNewBillingRun}
                        submitFunc={this.props.billingRunStore.executeBillingRun}
                        onSuccess={this.onSuccessExecuteBillingRun}
                        initialValues={{
                            startDate: this.props.billingRunsUIStore.executeBillingRunStartDate,
                            endDate: this.props.billingRunsUIStore.executeBillingRunEndDate
                        }}/>
                </EncModal>

                <EncModal
                    isOpen={this.props.billingRunsUIStore.isBillingRunDetailsModalOpen}
                    closeModal={this.props.billingRunsUIStore.closeBillingRunDetails}>

                    <BillingRunDetails
                        billingRunSummary={this.props.billingRunsUIStore.singleSelectedBillingRun}/>
                </EncModal>
            </div>
        );
    }


    renderAdditionalTableFilters() {
        const {billingRunsUIStore} = this.props;

        return (
            <EncDateRangePicker
                startDate={billingRunsUIStore.dateRange.startDate}
                endDate={billingRunsUIStore.dateRange.endDate}
                handleRange={dateRange => {
                    billingRunsUIStore.setDateRange(dateRange);
                }}
            />
        );
    }
}

export default inject(
    'billingRunStore',
    'billingRunsUIStore',
    'headerUIStore'
)(withTranslation([
    'account'
])(observer(BillingRuns)));
