import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import columnConfig from './active-users.column-config';
import {Button} from 'semantic-ui-react';
import PageableTableSelect from '../../../components/table-select/pageable';

let ActiveUsers = (props) => {
    const {
        t, activeUsersUIStore, userStore
    } = props;

    useEffect(() => {
        return () => activeUsersUIStore.reset();
    }, [activeUsersUIStore]);

    return (
        <div>
            <div className="section__full--width--padding mt-4">
                <div className="button-box my-2">
                    <Button className={userStore.isExportingCsv ? 'button--loading' : ''}
                            disabled={userStore.isExportingCsv}
                            onClick={userStore.exportToCsv(activeUsersUIStore.tableFilter)}>
                        {t('common:common.export.to.csv')}
                    </Button>
                </div>
            </div>

            <PageableTableSelect
                multiSelect={false}
                selectable={false}
                columnConfig={columnConfig}
                fetchPageableData={userStore.getPageableUsers()}
                rowId="id"
                isSticky={true}
                stickyTopOffset={55}
                stickyTopBarOffset={0}
                dataName={t('user.users')}
                defaultColumnSorted='lastModifiedDate'
                handleTextFilterChange={activeUsersUIStore.setTableFilter}
            />
        </div>
    );
};

export default inject(
    'activeUsersUIStore',
    'authenticationStore',
    'userStore',
)(withTranslation(
    'user'
)(observer(ActiveUsers)));