import React from 'react';
import {inject, observer} from 'mobx-react';
import {Button} from 'semantic-ui-react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {withTranslation} from 'react-i18next';
import TableSelect from '../../../components/table-select';
import AddAdminUser from './add-admin-user';
import EncModal from '../../../components/enc-modal';
import {dateTimeUtil} from '../../../utils';
import EncConfirm from '../../../components/enc-confirm/EncConfirm';
import ConfirmMessage from '../../../components/enc-confirm/ConfirmMessage';

class AdminUsers extends React.Component {

    constructor(props) {
        super(props);

        this.props.headerUIStore.setTitleKey('admin:admin.admin.users');
    }

    handleAdminUserSelection = selectedRows => {
        this.props.adminUsersUIStore.setSelectedAdminUsers(selectedRows);
    };

    componentDidMount() {
        this.props.adminUserStore.loadAdminUsers();
    }

    componentWillUnmount() {
        this.props.adminUsersUIStore.reset();
        this.props.adminUserStore.reset();
    }

    successfulAddAdminUser = () => {
        this.props.adminUserStore.loadAdminUsers();
        this.props.adminUsersUIStore.closeAddModal();
    };

    deleteAdminUsers = () => {
        this.props.adminUserStore.deleteAdminUsers(this.props.adminUsersUIStore.selectedAdminUsers)
            .then(() => this.props.adminUserStore.loadAdminUsers());
        this.props.adminUsersUIStore.closeConfirmArchiveModal();
    };

    columnConfig = [
        {
            headerKey: 'admin:admin.source.user.id',
            prop: 'sourceUserId'
        },
        {
            headerKey: 'common.date.modified',
            prop: 'dateModified',
            transform: adminUser => {
                if (adminUser.dateModified) {
                    return dateTimeUtil.toDisplayDateTime(adminUser.dateModified);
                }
                return null;
            }
        },
        {
            headerKey: 'common.last.modified.by',
            prop: 'lastModifiedBy'
        }
    ];

    render() {
        const buttons = (
            <div>
                {(this.props.adminUsersUIStore.selectedAdminUsers && this.props.adminUsersUIStore.selectedAdminUsers.length > 0) &&
                <Button onClick={this.props.adminUsersUIStore.openConfirmArchiveModal}>Delete</Button>}
            </div>
        );

        const {t} = this.props;

        return (
            <div className="content sticky-scroll">
                <div className="section__full--width--padding mt-4">
                    <Button className="button__primary"
                            onClick={this.props.adminUsersUIStore.openAddModal}>{t('common:common.new')}</Button>

                    {this.renderConfirm()}

                    <EncModal
                        isOpen={this.props.adminUsersUIStore.isAddModalOpen}
                        closeModal={this.props.adminUsersUIStore.closeAddModal}>
                            <AddAdminUser closeFunc={this.props.adminUsersUIStore.closeAddModal}
                                          submitFunc={this.props.adminUserStore.createAdminUser}
                                          onSuccess={this.successfulAddAdminUser}
                                          adminUsers={this.props.adminUserStore.adminUsers}/>
                    </EncModal>
                </div>

                <TableSelect
                    multiSelect={true}
                    columnConfig={this.columnConfig}
                    rowData={this.props.adminUserStore.adminUsers}
                    loading={this.props.adminUserStore.isLoading}
                    handleRowsSelection={this.handleAdminUserSelection}
                    rowId="id"
                    tableButtons={buttons}
                    isSticky={true}
                    stickyTopOffset={55}
                    stickyTopBarOffset={0}
                    dataName={t('admin:admin.admin.users')}
                    defaultColumnSorted="dateModified"
                />
            </div>
        );
    }

    renderConfirm() {
        const {t} = this.props;
        const deleteMessage = (
            <ConfirmMessage
                itemsArray={this.props.adminUsersUIStore.selectedAdminUsers}
                itemProperty={'sourceUserId'}
                itemKey={'user'}
                itemsKey={'users'}
                confirmMessageTranslationKey={'admin:admin.delete.user.message'}
            />
        );

        return (
            <EncConfirm
                openConfirm={this.props.adminUsersUIStore.isConfirmArchiveModalOpen}
                closeConfirm={this.props.adminUsersUIStore.closeConfirmArchiveModal}
                confirmFunc={this.deleteAdminUsers}
                confirmButtonLabel={this.props.adminUsersUIStore.selectedAdminUsers.length > 1 ?  t('admin:admin.delete.users') : t('admin:admin.delete.user')}
                confirmTitle={t('admin:admin.confirm.delete')}
                confirmMessage={deleteMessage}
            />
        )
    }
}

export default inject(
    'adminUserStore',
    'adminUsersUIStore',
    'headerUIStore'
)(withTranslation([
    'adminUsers',
    'common'
])(observer(AdminUsers)));