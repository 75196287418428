import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const ABOUT_ENDPOINT = '/api/v1/about';

class AboutService {

    retrieveBuildInformation() {
        return axiosInterface.get(`${ABOUT_ENDPOINT}/build-information`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading build information. ${error}`);
                throw error;
            });
    }
}

const aboutService = new AboutService()
export default aboutService;