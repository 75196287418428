import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const SUBSCRIPTION_TYPE_ENDPOINT = '/api/v1/subscription-type';

class SubscriptionTypeService {

    retrieveSubscriptionTypes() {
        return axiosInterface.get(SUBSCRIPTION_TYPE_ENDPOINT)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: "common:common.an.error.occurred", label: error});
                loggingUtil.error(`An error has occurred when loading subscription types. ${error}`);
                throw error;
            });
    }
}

const subscriptionTypeService = new SubscriptionTypeService()
export default subscriptionTypeService;