import axiosInterface from '../../axios/axios-instance';
import {toastError} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const USER_LOGIN_HISTORY_ENDPOINT = '/api/v1/user-login-history';

class UserloginhistoryService {

    getPageableUserLoginHistory = (params) => {
        return axiosInterface.get(`${USER_LOGIN_HISTORY_ENDPOINT}`, {params})
            .then(response => {
                return response;
            }).catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading user login history: ${error}`);
                throw error;
            });
    };
}

const userloginhistoryService = new UserloginhistoryService()
export default userloginhistoryService;