import React from 'react';
import {withTranslation} from 'react-i18next';

let MenuTrigger = (props) => {
    const {t, key, onClick, isCloseTrigger} = props;

    const menuIcon = !!isCloseTrigger ? "close__icon" : "menu__icon";
    const menuLabel = !!isCloseTrigger ? t('menu.close') : t('menu.menu');

    return (
        <div key={key} className="menu__trigger cursor__pointer" onClick={onClick}>
            <span className="button__label">
                <span className={menuIcon}/>
                <span className="label">{menuLabel}</span>
            </span>
        </div>
    );
};

export default withTranslation(
    'menu'
)(MenuTrigger);