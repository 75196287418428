import React from 'react';
import {
    EncForm,
    EncInputField,
    EncFilterDropdownField,
    withEncFormHandler
} from '../../../../../../../../components/hocs/form-validation/index';

let TransferUser = (props) => {
    const {formProperties, fieldProperties} = props;

    return (
        <EncForm formProperties={formProperties} fieldProperties={fieldProperties}>
            <EncFilterDropdownField
                id="destinationAccountId"
                label="user:user.transfer.to.account"
                placeholder='Select an account'
                options={props.accountsOptions}
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
            <EncInputField
                id="transferReason"
                label="user:user.reason.for.transfer"
                validator={{
                    type: 'string',
                    rules: ['required']
                }}
            />
        </EncForm>
    );
};

const FORM_VALIDATION_OPTIONS = {
    header: 'user:user.transfer.user',
    successMessage: 'user:user.successfully.transferred',
    validateOnChange: false,
    validateOnBlur: true,
    closeOnPristine: false,
    confirmAlertMsg: (initialValues) => {
        let messages = [];
        messages.push({
            translation: 'user:user.being.transferred',
            values: {userEmail: initialValues.userEmail}
        });
        messages.push('user:user.being.transferred.message');
        return messages.length === 0 ? undefined
            : {
                header: 'user:user.confirm.transfer.user',
                messages: messages
            };
    }
};

export default withEncFormHandler(FORM_VALIDATION_OPTIONS)(TransferUser);
