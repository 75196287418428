import React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from 'mobx-react';
import {Button} from 'semantic-ui-react';

import columnConfig from './profiles.column-config';
import EncDateRangePicker from '../../components/date-range-picker/EncDateRangePicker';
import NoResultsMessage from '../../components/table-select/NoResultsMessage';
import PageableTableSelect from '../../components/table-select/pageable/index';

class Profiles extends React.Component {

    constructor(props) {
        super(props);

        this.props.headerUIStore.setTitleKey('profiles:profiles.title');
    }

    componentWillUnmount() {
        this.props.profilesUIStore.reset();
    }

    exportProfilesToCSV = () => {
        this.props.profileStore.exportToCsv(
            this.props.profilesUIStore.dateRange,
            this.tableSelectFilter
        );
    };

    handleTextFilterChange = filter => this.tableSelectFilter = filter;

    getPageableProfiles = (...params) => {
        this.props.profileStore.getPageableProfiles(this.props.profilesUIStore.dateRange)(...params);
    };

    render() {
        const {t} = this.props;

        return (
            <div className="content sticky-scroll">
                <div className="section__full--width--padding mt-4">
                    <Button className="button__primary"
                            onClick={this.exportProfilesToCSV}>{t('common:common.export.to.csv')}
                    </Button>
                </div>

                <div className="sticky-scroll">
                    <PageableTableSelect
                        multiSelect={false}
                        selectable={false}
                        columnConfig={columnConfig}
                        fetchPageableData={this.getPageableProfiles}
                        rowId="id"
                        isSticky={true}
                        stickyTopOffset={55}
                        stickyTopBarOffset={0}
                        dataName={t('profiles.data.name')}
                        noDataText={this.renderNoResultsMessage()}
                        defaultColumnSorted="profileCreationDate"
                        tableSelectRef={(tableSelectRef) => this.tableSelectRef = tableSelectRef}
                        handleTextFilterChange={this.handleTextFilterChange}
                        additionalFilteringComponents={this.renderAdditionalTableFilters()}/>
                </div>
            </div>
        );
    }

    renderAdditionalTableFilters() {
        const {profilesUIStore} = this.props;

        return (
            <EncDateRangePicker
                startDate={profilesUIStore.dateRange.startDate}
                endDate={profilesUIStore.dateRange.endDate}
                handleRange={dateRange => {
                    profilesUIStore.setDateRange(dateRange);
                    this.tableSelectRef.refreshTable();
                }}
            />
        );
    }

    renderNoResultsMessage() {
        const {profilesUIStore} = this.props;

        return (
            <NoResultsMessage
                startDate={profilesUIStore.dateRange.startDate}
                endDate={profilesUIStore.dateRange.endDate}
            />
        );
    }
}

export default inject(
    'profileStore',
    'profilesUIStore',
    'headerUIStore'
)(withTranslation([
    'profiles',
    'common'
])(observer(Profiles)));