import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {Button} from 'semantic-ui-react';

let ArchivedAccountUsersTableButtons = (props) => {
    const {
        t, archivedAccountUsersUIStore
    } = props;

    if (!archivedAccountUsersUIStore.isSelectedUsersRestorable) {
        return null;
    }

    return (
        <Button onClick={archivedAccountUsersUIStore.openRestoreConfirmModal}>{t('user.restore')}</Button>
    );
};

export default inject(
    'archivedAccountUsersUIStore'
)(withTranslation(
    'user'
)(observer(ArchivedAccountUsersTableButtons)));