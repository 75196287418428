import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

export default function EncModal (props) {
    const isOpen = typeof props.isOpen === 'function' ? props.isOpen() : props.isOpen;
    return (
        <Modal
            isOpen={isOpen}
            closeTimeoutMS={props.closeTimeoutMS || 200}
            onRequestClose={props.closeModal}
            className="modal__overlay">
                <div className="close icon" onClick={props.closeModal}>
                    <span>esc</span>
                </div>
                <div className="modal__overlay--content">
                    {props.children}
                </div>
        </Modal>
    )
}

EncModal.defaultProps = {
    isOpen: false
};

EncModal.propTypes = {
    isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
    closeTimeoutMS: PropTypes.number,
    closeModal: PropTypes.func.isRequired
};
